import { SET_USER, REMOVE_USER, FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_ERROR } from '../constants/actionTypes';

const initialState = {
  loading: true,
  error: null,
};

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER:
      return payload;

    case REMOVE_USER:
      return null;

    case FETCH_USER_REQUEST:
      return { ...state, payload, loading: true };

    case FETCH_USER_SUCCESS:
      return { ...state, payload, loading: false, error: null };

    case FETCH_USER_ERROR:
      return { ...state, error: payload, loading: false };

    default:
      return state;
  }
};

export default user;
