import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import i18n from '../i18n';

const AuthRoute = () => {
  const user = useSelector(state => state.user);
  
  if (user) {
    const redirectPath = i18n.language === "en" ? "/app" : `/${i18n.language}/app`;
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default AuthRoute;