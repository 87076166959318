import React, { StrictMode } from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client"
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import Firebase, { FirebaseContext } from './services/firebase';
// import { MemoryRouter } from 'react-router-dom';
import './index.css';
import App from './containers/App';
import { TooltipProvider } from './components/SmartEditor/components/ui/tooltip';
import { ThemeProvider, createTheme } from '@mui/material/styles'; // Update import

// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//     background: {
//       default: '#000000', // Pure black background
//       paper: '#000000',   // Black background for paper elements
//     },
//   },
//   components: {
//     MuiCssBaseline: {
//       styleOverrides: {
//         body: {
//           backgroundColor: '#000000',
//         },
//       },
//     },
//   },
// });

// document.documentElement.classList.add('dark');

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <FirebaseContext.Provider value={new Firebase()}>
        {/* <StrictMode> */}
          {/* <MemoryRouter> */}
          {/* <ThemeProvider theme={darkTheme}> */}
            <TooltipProvider>
              <App />
            </TooltipProvider>
          {/* </ThemeProvider> */}
          {/* </MemoryRouter> */}
        {/* </StrictMode> */}
      </FirebaseContext.Provider>
    </PersistGate>
  </Provider>
);
