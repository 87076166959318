import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';

const countries = [
  {
    label: 'English',
    lang: 'en',
    flag: '🇺🇸',
  },
  {
    label: 'Tiếng Việt',
    lang: 'vi',
    flag: '🇻🇳',
  },
  {
    label: 'Indonesia',
    lang: 'id',
    flag: '🇮🇩'
  },
  {
    label: '日本語',
    lang: 'jp',
    flag: '🇯🇵',
  },
  {
    label: '繁體中文',
    lang: 'zh',
    flag: '🇨🇳',
  },
  {
    label: '한국어',
    lang: 'ko',
    flag: '🇰🇷',
  }
];

const LanguageSwitcher = ({currentLanguage}) => {
  const [selected, setSelected] = useState(0);
  const dropdownRef = useRef(null);

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        dropdownRef.current.classList.remove('show');
        // Your code to handle closing the popup goes here
      }
    };

    document.addEventListener('click', handleClickOutside);

    const currentLanguage = i18n.language;
    const index = countries.findIndex((country) => country.lang === currentLanguage);
    setSelected(index);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [i18n.language]);

  const handleLanguageChange = (index) => {
    setSelected(index);
    dropdownRef.current.classList.remove('show');
    localStorage.setItem('mainLang', countries[index].lang);
  };

  function onOpenToggler(e) {
    e.stopPropagation();
    dropdownRef.current.classList.toggle('show');
  }

  function handleMouseEnter() {
    if (!dropdownRef.current.classList.contains('show')) {
      dropdownRef.current.classList.toggle('show');
    }
  }

  return (
    <div className="bg-gray-200 flex items-center justify-center pt-5 pb-56">
      <div className="w-full md:w-1/2 lg:w-1/3">
        <div className="w-full">
          <div className="relative pb-5">
            <button
              className="lang-btn-wrapper rounded-pill text-nowrap lang-btn"
              type="button"
              onClick={onOpenToggler}
              // onMouseEnter={handleMouseEnter}
            >
              {/* <svg
                width="24px"
                height="24px"
                viewBox="0 0 16 17"
                fill="currentColor"
                role="presentation"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 1.834a6.667 6.667 0 1 1 0 13.333A6.667 6.667 0 0 1 8 1.834Zm0 12c.143 0 .632-.15 1.164-1.216a7.2 7.2 0 0 0 .522-1.45H6.313c.14.552.318 1.042.522 1.45.533 1.065 1.021 1.216 1.165 1.216Zm-1.926-4a11.823 11.823 0 0 1 0-2.667h3.851a11.823 11.823 0 0 1 0 2.667H6.074Zm4.982 1.333a8.682 8.682 0 0 1-.748 2.143 5.357 5.357 0 0 0 2.312-2.143h-1.564Zm2.109-1.333h-1.899a13.198 13.198 0 0 0 0-2.667h1.899a5.342 5.342 0 0 1 0 2.667Zm-8.432 0H2.834a5.343 5.343 0 0 1 0-2.667h1.899a13.218 13.218 0 0 0 0 2.667ZM3.38 11.167h1.564c.177.811.432 1.538.747 2.143a5.357 5.357 0 0 1-2.311-2.143Zm2.933-5.333h3.373a7.19 7.19 0 0 0-.522-1.451C8.632 3.318 8.143 3.167 8 3.167c-.144 0-.632.151-1.165 1.216a7.19 7.19 0 0 0-.522 1.451Zm4.743 0h1.564a5.357 5.357 0 0 0-2.312-2.143c.315.605.57 1.332.748 2.143ZM5.69 3.691c-.315.605-.57 1.332-.747 2.143H3.38a5.357 5.357 0 0 1 2.31-2.143Z"
                    fillOpacity="1"
                    fill="currentColor"
                  />
                </g>
              </svg> */}
              <span style={{fontSize: "17px"}}>{countries[selected].flag}</span>
              <span>{countries[selected].label}</span>
              <svg
                width="14px"
                height="14px"
                viewBox="0 0 12 12"
                fill="none"
                role="presentation"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    strokeWidth={2}
                    d="M9.898 4.157 6 8.055 2.102 4.157"
                    stroke="currentColor"
                  />
                </g>
              </svg>
            </button>
            <div className="lang-drop-down text-gray-700 shadow-md rounded text-sm absolute mt-12 top-0 right-0 min-w-full" ref={dropdownRef}>
              <div className="overflow-auto w-full relative z-10">
                <ul className="list-reset">
                  {countries.map((item, index) => (
                    <li key={index}>
                      <a
                        href={item.lang === 'en' ? "/" : `/${item.lang}` }
                        className={`px-4 py-2 flex hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 ${index === selected ? 'font-bold' : ''}`}
                        onClick={() => handleLanguageChange(index)}
                      >
                        <span className="inline-block pr-4">{item.flag}</span>
                        <span className="inline-block">{item.label}</span>
                        {index === selected && (
                          <span style={{marginTop: "-2px", marginLeft: "2px"}}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z" fill="#72ff9a"></path></svg>
                          </span>
                        )}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSwitcher;

