import React, { FormEvent, useRef, useCallback, useState } from "react"
import { Button } from "./ui/button"
import { useStore } from "../lib/states"
import { useClickAway, useToggle } from "react-use"
import { Textarea } from "./ui/textarea"
import { cn } from "./../lib/utils"
import { useTranslation } from 'react-i18next';
import { toast } from "./ui/use-toast"

const badWordsString = process.env.REACT_APP_BAD_WORDS || '';
const badWordsArray = badWordsString.split(',').map(word => word.trim());
const forbiddenWordsSet = new Set(badWordsArray.map(word => word.toLowerCase()));

const containsBadWord = (text: string) => {
  const words = text.toLowerCase().split(/\s+/);
  return words.some(word => forbiddenWordsSet.has(word));
};


const PromptInput = ({ userData, firebase, isMobile }) => {
  const [
    isProcessing,
    prompt,
    updateSettings,
    runVirtualStaging,
    showPrevMask,
    hidePrevMask,
  ] = useStore((state) => [
    state.getIsProcessing(),
    state.settings.prompt,
    state.updateSettings,
    state.runVirtualStaging,
    state.showPrevMask,
    state.hidePrevMask,
  ])
  const { t } = useTranslation();

  const [showScroll, toggleShowScroll] = useToggle(false)
  const curLineGroup = useStore((state) => state.editorState.curLineGroup)
  const extraMasks = useStore((state) => state.editorState.extraMasks)

  const hadDrawSomething = useCallback(() => {
    return curLineGroup.length !== 0
  }, [curLineGroup])

  const ref = useRef(null)
  useClickAway<MouseEvent>(ref, () => {
    if (ref?.current) {
      const input = ref.current as HTMLTextAreaElement
      input.blur()
    }
  })

  const handleOnInput = (evt: FormEvent<HTMLTextAreaElement>) => {
    evt.preventDefault()
    evt.stopPropagation()
    const target = evt.target as HTMLTextAreaElement
    updateSettings({ prompt: target.value })
  }

  const handleOnInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    // alert(e.target.value);
    updateSettings({ prompt: e.target.value })
  }

  const handleRepaintClick = () => {
    if (!isProcessing) {
      const badWord = containsBadWord(prompt);
      if (badWord) {
        toast({
          variant: "destructive",
          description: t('message.error.badWordsStyle'),
        })
        return;
      }
    
      runVirtualStaging(userData.pro, firebase, prompt)
    }
  }

  const onKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && e.ctrlKey && prompt.length !== 0) {
      handleRepaintClick()
    }
  }

  const onMouseEnter = () => {
    showPrevMask()
  }

  const onMouseLeave = () => {
    hidePrevMask()
  }

  return (
    <div className="flex gap-4 relative w-full justify-center h-full">
      <div className="absolute flex gap-4 relative justify-center">
        <Textarea
          ref={ref}
          placeholder="Wearing a suit..."
          className={cn(
            showScroll ? "focus:overflow-y-auto" : "overflow-y-hidden",
            "focus:outline-none w-full min-h-[32px] h-[32px] overflow-x-hidden focus:h-[120px] overflow-y-hidden transition-[height] py-1 px-3 bg-background resize-none"
          )}
          style={isMobile ? {
            scrollbarGutter: "stable",
            width: "100%",
            minHeight: "36px",
            fontSize: "16px",
            marginTop: "-3px",
          }:
          {
            scrollbarGutter: "stable",
            width: "500px",
            fontSize: "14px",
            minHeight: "32px",
          }}
          value={prompt}
          onInput={handleOnInput}
          onKeyUp={onKeyUp}
          onTransitionEnd={toggleShowScroll}
          autoFocus={false}
        />
        {/* <textarea
          ref={ref}
          placeholder="Wearing a suit..."
          className="focus:outline-none w-full overflow-x-hidden focus:h-[120px] transition-[height] bg-background resize-none rounded-md border border-input px-3 py-1 shadow-sm placeholder:text-muted-foreground"
          style={isMobile ? {
            scrollbarGutter: "stable",
            width: "100%",
            minHeight: "36px",
            fontSize: "16px",
            marginTop: "-3px",
          }:
          {
            scrollbarGutter: "stable",
            width: "500px",
            fontSize: "14px",
            minHeight: "32px",
          }}
          value={prompt}
          onChange={handleOnInput}
          onTransitionEnd={toggleShowScroll}
          onKeyUp={onKeyUp}
        /> */}
        <Button
          size="sm"
          onClick={handleRepaintClick}
          disabled={isProcessing || (!hadDrawSomething() && extraMasks.length === 0)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {isProcessing ? t('button.rendering'): t('button.render')}
        </Button>
      </div>
    </div>
  )
}

export default PromptInput
