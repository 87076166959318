import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { openPricingDialog, closePricingDialog } from '../../store/slices/pricingSlice';

import { Button, message } from 'antd';
import ComposeSection from '../ComposeSection';
import Spinner from '../Spinner';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import { Link } from 'react-router-dom';
import NewAvatarHeader from '../NewAvatarHeader';
import MyDesign from '../MyDesign';
import NewPricing from '../NewPricing';
import HeaderSticky from '../HeaderSticky';
import MainEditor from '../SmartEditor/MainEditor';
import { Eraser, Sparkles } from 'lucide-react';
import { UPSCALE_MODEL, MAGIC_FILL_MODEL, REMOVE_OBJECTS_MODEL, REMOVE_BG_MODEL } from '../../constants/model';
import { v4 as uuidv4 } from 'uuid';
import Feedback from '../Feedback';
import TrainModel from '../TrainModel';
import HomePage from '../HomePage';
import Studio from '../Studio';
import Badge from '@mui/material/Badge';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PaidIcon from '@mui/icons-material/Paid';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import CameraIcon from '@mui/icons-material/Camera';
import { useLocation } from 'react-router-dom';
import { useStore } from "../SmartEditor/lib/states"
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';

const modes = {
  pro: 'Pro',
  basic: 'Basic',
};

const MAX_WIDTH = 768;

const ageItems = [];
for (let age = 18; age <= 100; age++) {
  ageItems.push({
    value: age,
    label: age,
  });
}

const NewHome = ({ firebase, setUser }) => {
  const isPricingOpen = useSelector((state) => state.pricing.isOpen);
  const dispatch = useDispatch();

  // User and authentication related states
  const [userData, setUserData] = useState({});
  const [isSpinning, setIsSpinning] = useState(true);
  const [darkMode, setDarkMode] = useState(true);
  const [currentLanguage, setCurrentLanguage] = useState('');

  // Job and creation related states
  const [isCreating, setIsCreating] = useState(false);
  const [currentJob, setCurrentJob] = useState(null);
  const [mode, setMode] = useState(modes.pro);

  // UI visibility states
  const [isShowPricing, setIsShowPricing] = useState(false);
  const [showMyDesign, setShowMyDesign] = useState(false);
  const [showProfessional, setShowProfessional] = useState(false);
  const [showTrainModel, setShowTrainModel] = useState(false);
  const [showHome, setShowHome] = useState(true);
  const [showRemoveBG, setShowRemoveBG] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [isSubmittedFeedback, setIsSubmittedFeedback] = useState(false);
  const [showEditorTools, setShowEditorTools] = useState(true);
  const [showHeadshots, setShowHeadshots] = useState(false);

  // Image and file related states
  const [imageSrc, setImageSrc] = useState('');
  const [file, setFile] = useState(null);
  const [fileInfo, setFileInfo] = useState(null);
  const [remixFile, setRemixFile] = useState('');
  const [selectedCollection, setSelectedCollection] = useState(null);

  // Mobile and menu related states
  const [isMobile, setIsMobile] = useState(false);
  const [isActiveMobileMenu, setIsActiveMobileMenu] = useState(false);
  const [isActiveDeskMenu, setIsActiveDeskMenu] = useState(true);

  // Other states
  const [model, setModel] = useState('');
  const [runModel, setRunModel] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [updateMyAssets, setUpdateMyAssets] = useState(false);
  const [activeMenuTab, setActiveMenuTab] = useState(0);

  // Refs
  const navPopupRef = useRef(null);

  // Navigation
  const navigate = useNavigate();

  // const [notificationCount, setNotificationCount] = useState(0);
  const [showNotification, setShowNotification] = useState(false); // State to control notification visibility
  const notificationRef = useRef(null);
  const [pack, setPack] = useState(null);
  const [
    updateSettings
  ] = useStore((state) => [
    state.updateSettings
  ])

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  const location = useLocation();

  useEffect(() => {
    // Check if this is the first visit (no darkMode setting in localStorage)
    const storedDarkMode = localStorage.getItem('darkMode');
    
    if (storedDarkMode === null) {
      // First visit - set dark mode as default
      localStorage.setItem('darkMode', 'true');
      // Ensure dark mode styles are applied
      const htmlElement = document.documentElement;
      htmlElement.classList.add('dark');
      htmlElement.style.background = '#000';
      document.body.style.backgroundColor = '#000';
    } else {
      // Returning visitor - use their stored preference
      const isDarkMode = storedDarkMode === 'true';
      if (isDarkMode !== darkMode) {
        onSelectDarkMode();
      }
    }
  }, []);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // Access specific parameters
    const imageUrl = params.url;

    if (imageUrl !== undefined && imageUrl !== '') {
      sampleUploadFromLink(imageUrl);
    }
  }, [i18n.language]);

  useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged(user => {
      if (user) {
        // User is authenticated, fetch the user data
        firebase
          .getUser(user.uid)
          .then(querySnapshot => {
            if (!querySnapshot.empty) {
              setUserData(querySnapshot.data());
              setTimeout(() => {
                setIsSpinning(false);
                updateSettings({ credits: querySnapshot.data().proCredits });
                // checkNewUsersCredits(querySnapshot.data().proCredits);
                // window.HubSpotConversations.widget.remove();
              }, 100);
            }
          })
          .catch(error => {
            console.log(error);
            setIsSpinning(false);
          });

        firebase.getNotifications(user.uid).then(querySnapshot => {
          if (!querySnapshot.empty) {
            const notfications = (querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            })));

            setNotifications(notfications);

            // const hasUnreadNotifications = notifications.some(notification => !notification.read);

            // if (!hasUnreadNotifications && fetchCount < MAX_FETCHES) {
            //   setFetchCount(prevCount => prevCount + 1);
            //   setTimeout(() => {
            //     firebase.getNotifications(user.uid).then(querySnapshot => {
            //       if (!querySnapshot.empty) {
            //       const notfications = (querySnapshot.docs.map(doc => ({
            //         id: doc.id,
            //         ...doc.data()
            //       })));

            //       setNotifications(notfications);
            //       }
            //     });
            //   }, 15000);
            // }
          }
        });
      } else {
        // User is not authenticated, clear the user data
        setUserData(null);
        setUser(null);
        setIsSpinning(false);
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const pack = urlSearchParams.get('pack');
    setPack(pack);
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotification(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 980) {
      setIsMobile(true);
      setIsActiveDeskMenu(false);
    }

    const handleResize = () => {
      if (window.innerWidth <= 980) {
        setIsMobile(true);
        setIsActiveDeskMenu(false);
      } else {
        setIsMobile(false);
        setIsActiveDeskMenu(true);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (navPopupRef.current && event.target.contains(navPopupRef.current)) {
        setIsActiveMobileMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [navPopupRef]);

  function checkCredits() {
    if (mode === modes.pro && ((userData.pro && userData.proCredits < 2) || !userData.pro)) {
      message.warning(t('message.warning.runOutProCredits'));
      setTimeout(() => {
        setIsCreating(false);
        onShowPricing(true);
      }, 1500);
      return true;
    }

    if (mode === modes.basic && ((userData.basic && userData.basicCredits < 2) || !userData.basic)) {
      message.warning(t('message.warning.runOutBasicCredits'));
      setTimeout(() => {
        setIsCreating(false);
        onShowPricing(true);
      }, 1500);
      return true;
    }

    return false;
  }

  function onGenFill(newJob) {
    setCurrentJob(newJob);
  }

  function handleModeChangeParent(mode) {
    setMode(mode);
  }

  function onShowPricing(value) {
    // setIsShowPricing(value);
    dispatch(openPricingDialog());
  }

  function onFetchUser() {
    firebase
      .getUser(firebase.auth.currentUser.uid)
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          setUserData(querySnapshot.data());
          setUser(querySnapshot.data());
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const checkFileSize = file => {
    const fileSize = file.size;
    const maxSizeBasic = 10 * 1024 * 1024;
    const maxSizePro = 10 * 1024 * 1024;

    if (mode === modes.pro && fileSize > maxSizePro) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return false;
    }
    if (mode === modes.basic && fileSize > maxSizeBasic) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return false;
    }
    return true;
  };

  const handleOnUpImageResolution = async (event, imageUrl) => {
    onActiveUpgradeTo4K(event);
    setShowEditorTools(true);

    const activeLink = document.querySelector('#upscale-4k');
    if (activeLink) {
      activeLink.classList.add('active');
    }

    // setIsActiveMobileMenu(false);

    const response = await fetch(imageUrl);
    const blob = await response.blob();
    if (checkFileSize(blob) === false) return;

    // const decodedUrl = decodeURIComponent(imageUrl);
    // const fileName = decodedUrl.split('/').pop().split('?')[0];

    // const file = new File([blob], fileName, {
    //     type: blob.type
    // });

    setFile(blob);
  };

  const sampleUploadFromLink = async imageUrl => {
    const response = await fetch(imageUrl);
    const file = await response.blob();

    if (checkFileSize(file) === false) return;

    setFile(file);
    const reader = new FileReader();

    reader.onload = function () {
      const image = new Image();

      image.onload = function () {
        const canvas = document.createElement('canvas');
        const scaleSize = MAX_WIDTH / image.width;
        canvas.width = MAX_WIDTH;
        canvas.height = image.height * scaleSize;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        if (file.name === '' || file.name === undefined) {
          file.name = `reok-input-${uuidv4()}.jpg`;
        }

        canvas.toBlob(blob => {
          const canvasImage = new File([blob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          });
          setFileInfo(canvasImage); // Set fileInfo as the canvas image with additional properties
          setFile(canvasImage);

          const reader2 = new FileReader();
          reader2.onloadend = function () {
            const srcEncoded = reader2.result;
            setImageSrc(srcEncoded);
          };

          canvas.remove(); // Remove the canvas element
          reader2.readAsDataURL(blob);
        }, 'image/jpeg');
      };

      image.src = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const handleOnRemoveObject = async (event, imageUrl) => {
    // scrollToTop();
    onActiveRemoveObject(event);
    setShowEditorTools(true);

    const activeLink = document.querySelector('#remove-object');
    if (activeLink) {
      activeLink.classList.add('active');
    }

    setIsActiveMobileMenu(false);

    const response = await fetch(imageUrl);
    const blob = await response.blob();
    if (checkFileSize(blob) === false) return;

    // const decodedUrl = decodeURIComponent(imageUrl);
    // const fileName = decodedUrl.split('/').pop().split('?')[0];

    // const file = new File([blob], fileName, {
    //     type: blob.type
    // });

    setFile(blob);
  };

  const onMagicFixer = async (event, imageUrl) => {
    // scrollToTop();
    setShowEditorTools(true);
    onActiveMagicFill(event);

    const activeLink = document.querySelector('#magic-fill');
    if (activeLink) {
      activeLink.classList.add('active');
    }

    setIsActiveMobileMenu(false);

    const response = await fetch(imageUrl);
    const blob = await response.blob();
    if (checkFileSize(blob) === false) return;

    // const decodedUrl = decodeURIComponent(imageUrl);
    // const fileName = decodedUrl.split('/').pop().split('?')[0];

    // const file = new File([blob], fileName, {
    //     type: blob.type
    // });

    setFile(blob);
  };

  const onMagicRemix = async (event, imageUrl) => {
    // scrollToTop();
    onDisableActiveLink();
    setShowProfessional(true);
    onActiveProfessional(event);

    const response = await fetch(imageUrl);
    const blob = await response.blob();
    if (checkFileSize(blob) === false) return;

    // const decodedUrl = decodeURIComponent(imageUrl);
    // const fileName = decodedUrl.split('/').pop().split('?')[0];

    // const file = new File([blob], fileName, {
    //     type: blob.type
    // });

    setRemixFile(blob);
  };

  function onDisableActiveLink() {
    setShowHome(false);
    setShowTrainModel(false);
    setShowProfessional(false);
    setShowRemoveBG(false);
    setShowMyDesign(false);
    setShowEditor(false);
    setCurrentJob(null);
  }

  function onActiveHome(event) {
    onDisableActiveLink();
    setShowHome(true);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    event.currentTarget.classList.add('active');
    setIsActiveMobileMenu(false);
  }

  function onActiveTrainModel(event) {
    onDisableActiveLink();
    setShowTrainModel(true);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    event.currentTarget.classList.add('active');
    setIsActiveMobileMenu(false);
  }

  function onActiveProfessional(event) {
    onDisableActiveLink();
    setShowProfessional(true);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    event.currentTarget.classList.add('active');
    setIsActiveMobileMenu(false);
  }

  function onActiveMyPhoto(event) {
    onDisableActiveLink();
    setShowMyDesign(true);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    event.currentTarget.classList.add('active');
    setIsActiveMobileMenu(false);
  }

  function onActiveRemoveObject(event) {
    onDisableActiveLink();
    setShowEditor(true);
    setModel(REMOVE_OBJECTS_MODEL);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    if (event) {
      event.currentTarget.classList.add('active');
    } else {
      document.getElementById('remove-object').classList.add('active');
    }
    setIsActiveMobileMenu(false);
  }

  function onActiveUpgradeTo4K(event) {
    onDisableActiveLink();
    setShowEditor(true);
    setModel(UPSCALE_MODEL);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    if (event) {
      event.currentTarget.classList.add('active');
    } else {
      document.getElementById('upscale-4k').classList.add('active');
    }
    setIsActiveMobileMenu(false);
  }

  function onActiveMagicFill(event) {
    onDisableActiveLink();
    setShowEditor(true);
    setModel(MAGIC_FILL_MODEL);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    event.currentTarget.classList.add('active');
    setIsActiveMobileMenu(false);
  }

  const openMobileMenu = () => {
    setIsActiveMobileMenu(!isActiveMobileMenu);
  };

  const onSelectDarkMode = () => {
    setDarkMode(!darkMode);
    const htmlElement = document.documentElement;

    if (!darkMode) {
      // Enable dark mode
      htmlElement.classList.add('dark');
      htmlElement.style.background = '#000';
      // Update MUI theme if needed
      document.body.style.backgroundColor = '#000';
    } else {
      // Disable dark mode
      htmlElement.classList.remove('dark');
      htmlElement.style.removeProperty('background');
      // Update MUI theme if needed
      document.body.style.backgroundColor = '#fff';
    }

    // Optionally store the preference
    localStorage.setItem('darkMode', !darkMode);
  };

  const handleSubmittedFeedback = (submitted) => {
    setIsSubmittedFeedback(submitted);
  };

  const onShowFeedback = () => {
    setShowFeedbackModal(true);
  };

  const openTrainModel = (e) => {
    onActiveTrainModel(e);
    document.getElementById('train-model').classList.add('active');
  };

  const openStudioPipeLine = (e, item) => {
    setSelectedCollection(item);
    onDisableActiveLink();

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }

    const professionalLink = document.getElementById('generator');
    if (professionalLink) {
      professionalLink.classList.add('active');
    }

    setShowProfessional(true);
    setIsActiveMobileMenu(false);
  };

  const handleSelectModel = (model) => {
    setRunModel(model);
    onDisableActiveLink();

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }

    const professionalLink = document.getElementById('generator');
    if (professionalLink) {
      professionalLink.classList.add('active');
    }

    setShowProfessional(true);
    setIsActiveMobileMenu(false);
  }

  const openStudio = () => {
    onDisableActiveLink();

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }

    const studioLink = document.getElementById('generator');
    if (studioLink) {
      studioLink.classList.add('active');
    }

    setShowProfessional(true);
    setIsActiveMobileMenu(false);
  }

  const onActiveHomePage = (e) => {
    onDisableActiveLink();
    onActiveHome(e);
    document.getElementById('home').classList.add('active');
  };

  const startHeadshots = () => {
    dispatch(closePricingDialog());
    onDisableActiveLink();
    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    document.getElementById('home').classList.add('active');
    setShowHome(true);

    navigate('/app/?pack=ai_headshots');
  }

  const handleNotificationClick = () => {
    setShowNotification(!showNotification);
    // Read all notifications that are not read
    notifications.forEach(notification => {
      if (!notification.read) {
        notification.read = true;
        firebase.updateNotification(notification.id, { read: true });
      }
    });
  };

  const onSetUpdateMyAssets = () => {
    const activeMyPhotos = document.getElementById('my-photos');
    if (activeMyPhotos.classList.contains('active')) {
      setUpdateMyAssets(true);
    }
  }

  const handleOpenStudioDialog = () => {
    openStudio();
    setRunModel({
      isPublic: true,
    });
  }

  const onHideMenuBar = (value) => {
    setIsActiveDeskMenu(value);
  }

  return (
    <div>
      {userData && (
        <div>
          {!isMobile ? (
            <div className="left-area"
              style={{
                transition: 'all 0.35s ease',
                left: !isActiveDeskMenu ? '-350px' : '0',
                zIndex: '0',
              }}
              id="scroll-top-nav"
            >
              <div className="logo-header-desk">
                <HeaderSticky
                  currentLanguage={currentLanguage}
                  isMobile={isMobile}
                  isActiveMobileMenu={isActiveMobileMenu}
                  openMobileMenu={openMobileMenu}
                  onActiveProfessional={onActiveHomePage}
                  darkMode={darkMode}
                />
              </div>
              <>
                <div className="sub-area">
                  <ComposeSection
                    t={t}
                    userData={userData}
                    handleModeChangeParent={handleModeChangeParent}
                    onShowPricing={onShowPricing}
                    defaultMode={mode}
                    // onShowReferal={onShowReferal}
                    currentLanguage={currentLanguage}
                    firebase={firebase}
                    onFetchUser={onFetchUser}
                  />
                </div>
                <div className="nav-wrapper">
                  <div className="nav-menu">
                    <Link
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      className="nav-link active"
                      id="home"
                      onClick={onActiveHome}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M15 21v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8" /><path d="M3 10a2 2 0 0 1 .709-1.528l7-5.999a2 2 0 0 1 2.582 0l7 5.999A2 2 0 0 1 21 10v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /></svg>
                      <span>{t('app.menu.home')}</span>
                    </Link>
                    <Link
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      className="nav-link"
                      id="generator"
                      onClick={onActiveProfessional}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-aperture"><circle cx="12" cy="12" r="10" /><path d="m14.31 8 5.74 9.94" /><path d="M9.69 8h11.48" /><path d="m7.38 12 5.74-9.94" /><path d="M9.69 16 3.95 6.06" /><path d="M14.31 16H2.83" /><path d="m16.62 12-5.74 9.94" /></svg>
                      <span>{t('app.menu.studioMode')}</span>
                    </Link>
                    <Link
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      className="nav-link"
                      id="train-model"
                      onClick={onActiveTrainModel}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-brain-circuit"><path d="M12 5a3 3 0 1 0-5.997.125 4 4 0 0 0-2.526 5.77 4 4 0 0 0 .556 6.588A4 4 0 1 0 12 18Z" /><path d="M9 13a4.5 4.5 0 0 0 3-4" /><path d="M6.003 5.125A3 3 0 0 0 6.401 6.5" /><path d="M3.477 10.896a4 4 0 0 1 .585-.396" /><path d="M6 18a4 4 0 0 1-1.967-.516" /><path d="M12 13h4" /><path d="M12 18h6a2 2 0 0 1 2 2v1" /><path d="M12 8h8" /><path d="M16 8V5a2 2 0 0 1 2-2" /><circle cx="16" cy="13" r=".5" /><circle cx="18" cy="3" r=".5" /><circle cx="20" cy="21" r=".5" /><circle cx="20" cy="8" r=".5" /></svg>
                      <span>{t('app.menu.trainModel')}</span>
                    </Link>

                    {/* <Link
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      id="magic-tools"
                      onClick={toggleEditorTools}
                    >
                      <Sparkles />
                      <span style={{ marginRight: '6px' }}>{t('app.menu.editorTools')}</span>
                      {showEditorTools ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </Link> */}
                    {/* <Link
                      style={{display: showEditorTools ? 'block' : 'none'}}
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      id="magic-fill"
                      onClick={onActiveMagicFill}
                      className="nav-link"
                    >
                      <Sparkles/>
                      <span>{t('app.menu.aiMagicFill')}</span>
                    </Link> */}
                    <Link
                      style={{ display: showEditorTools ? 'block' : 'none' }}
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      id="remove-object"
                      onClick={onActiveRemoveObject}
                      className="nav-link"
                    >
                      <Eraser />
                      <span>{t('app.menu.removeObjectsMode')}</span>
                    </Link>
                    <Link
                      style={{ display: showEditorTools ? 'block' : 'none' }}
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      id="upscale-4k"
                      onClick={onActiveUpgradeTo4K}
                      className="nav-link"
                    >
                      <svg
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="bi bi-badge-4k"
                      >
                        <path d="M4.807 5.001C4.021 6.298 3.203 7.6 2.5 8.917v.971h2.905V11h1.112V9.888h.733V8.93h-.733V5.001h-1.71zm-1.23 3.93v-.032a46.781 46.781 0 0 1 1.766-3.001h.062V8.93H3.577zm9.831-3.93h-1.306L9.835 7.687h-.057V5H8.59v6h1.187V9.075l.615-.699L12.072 11H13.5l-2.232-3.415 2.14-2.584z" />
                        <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
                      </svg>
                      <span>{t('app.menu.unBluryMode')}</span>
                    </Link>

                    <Link
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      className="nav-link"
                      id="my-photos"
                      onClick={onActiveMyPhoto}
                    >
                      <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3,11h7a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1H3A1,1,0,0,0,2,3v7A1,1,0,0,0,3,11ZM4,4H9V9H4ZM22,3a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1ZM20,9H15V4h5ZM2,21a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1H3a1,1,0,0,0-1,1Zm2-6H9v5H4Zm18,6V14a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7A1,1,0,0,0,22,21Zm-2-1H15V15h5Z" />
                      </svg>
                      <span>{t('app.menu.myPhotos')}</span>
                    </Link>
                  </div>
                </div>
                <div className="left-down-menu">
                  <div className="idea-alert">
                    <a href="https://reok.lemonsqueezy.com/affiliates" target="_blank">
                      <div className="idea-inner">
                        <div className="ref-title">
                          <svg stroke="#ef4444" fill="#ef4444" strokeWidth="0" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                            <path d="M160 894c0 17.7 14.3 32 32 32h286V550H160v344zm386 32h286c17.7 0 32-14.3 32-32V550H546v376zm334-616H732.4c13.6-21.4 21.6-46.8 21.6-74 0-76.1-61.9-138-138-138-41.4 0-78.7 18.4-104 47.4-25.3-29-62.6-47.4-104-47.4-76.1 0-138 61.9-138 138 0 27.2 7.9 52.6 21.6 74H144c-17.7 0-32 14.3-32 32v140h366V310h68v172h366V342c0-17.7-14.3-32-32-32zm-402-4h-70c-38.6 0-70-31.4-70-70s31.4-70 70-70 70 31.4 70 70v70zm138 0h-70v-70c0-38.6 31.4-70 70-70s70 31.4 70 70-31.4 70-70 70z" />
                          </svg>
                          <span>{t('app.menu.affiliateTitle')}</span>
                        </div>
                        <div className="ref-des">{t('app.menu.affiliateSub')}</div>
                      </div>
                    </a>
                  </div>
                  <div className="dropdown">
                    <NewAvatarHeader userData={userData} t={t} darkMode={darkMode} onSelectDarkMode={onSelectDarkMode} onShowFeedback={onShowFeedback}
                    />
                  </div>
                </div>
              </>
            </div>
          ) : (
            <div>
              <div className="logo-header">
                <HeaderSticky
                  currentLanguage={currentLanguage}
                  isMobile={isMobile}
                  isActiveMobileMenu={isActiveMobileMenu}
                  openMobileMenu={openMobileMenu}
                  onActiveProfessional={onActiveHomePage}
                  darkMode={darkMode}
                />
              </div>
              <div
                className={`left-area-wrap fixed inset-0 z-50 transition-all duration-300 ${isActiveMobileMenu
                    ? 'opacity-100 pointer-events-auto backdrop-blur-sm'
                    : 'opacity-0 pointer-events-none'
                  }`}
              >
                <div
                  className="left-area transition-all duration-300"
                  style={{
                    transform: isActiveMobileMenu ? 'translateX(0)' : 'translateX(-100%)',
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    width: '280px'
                  }}
                  ref={navPopupRef}
                >  <div className="sub-area">
                    <ComposeSection
                      t={t}
                      userData={userData}
                      handleModeChangeParent={handleModeChangeParent}
                      onShowPricing={onShowPricing}
                      defaultMode={mode}
                      // onShowReferal={onShowReferal}
                      currentLanguage={currentLanguage}
                      firebase={firebase}
                      onFetchUser={onFetchUser}
                    />
                  </div>
                  <div className="nav-menu">
                    <Link
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      className="nav-link active"
                      id="home"
                      onClick={onActiveHome}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M15 21v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8" /><path d="M3 10a2 2 0 0 1 .709-1.528l7-5.999a2 2 0 0 1 2.582 0l7 5.999A2 2 0 0 1 21 10v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /></svg>
                      <span>{t('app.menu.home')}</span>
                    </Link>
                    <Link
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      className="nav-link"
                      onClick={onActiveProfessional}
                      id="generator"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-aperture"><circle cx="12" cy="12" r="10" /><path d="m14.31 8 5.74 9.94" /><path d="M9.69 8h11.48" /><path d="m7.38 12 5.74-9.94" /><path d="M9.69 16 3.95 6.06" /><path d="M14.31 16H2.83" /><path d="m16.62 12-5.74 9.94" /></svg>
                      <span>{t('app.menu.studioMode')}</span>
                    </Link>
                    <Link
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      className="nav-link"
                      id="train-model"
                      onClick={onActiveTrainModel}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-brain-circuit"><path d="M12 5a3 3 0 1 0-5.997.125 4 4 0 0 0-2.526 5.77 4 4 0 0 0 .556 6.588A4 4 0 1 0 12 18Z" /><path d="M9 13a4.5 4.5 0 0 0 3-4" /><path d="M6.003 5.125A3 3 0 0 0 6.401 6.5" /><path d="M3.477 10.896a4 4 0 0 1 .585-.396" /><path d="M6 18a4 4 0 0 1-1.967-.516" /><path d="M12 13h4" /><path d="M12 18h6a2 2 0 0 1 2 2v1" /><path d="M12 8h8" /><path d="M16 8V5a2 2 0 0 1 2-2" /><circle cx="16" cy="13" r=".5" /><circle cx="18" cy="3" r=".5" /><circle cx="20" cy="21" r=".5" /><circle cx="20" cy="8" r=".5" /></svg>
                      <span>{t('app.menu.trainModel')}</span>
                    </Link>
                    {/* <Link
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      id="magic-tools"
                      onClick={toggleEditorTools}
                    >
                      <Sparkles />
                      <span style={{ marginRight: '6px' }}>{t('app.menu.editorTools')}</span>
                      {showEditorTools ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </Link> */}
                    {/* <Link
                      style={{display: showEditorTools ? 'block' : 'none'}}
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      id="magic-fill"
                      onClick={onActiveMagicFill}
                      className="nav-link"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m19 11-8-8-8.6 8.6a2 2 0 0 0 0 2.8l5.2 5.2c.8.8 2 .8 2.8 0L19 11Z"/><path d="m5 2 5 5"/><path d="M2 13h15"/><path d="M22 20a2 2 0 1 1-4 0c0-1.6 1.7-2.4 2-4 .3 1.6 2 2.4 2 4Z"/></svg>
                      <span>{t('app.menu.aiMagicFill')}</span>
                    </Link> */}
                    <Link
                      style={{ display: showEditorTools ? 'block' : 'none' }}
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      id="remove-object"
                      onClick={onActiveRemoveObject}
                      className="nav-link"
                    >
                      <Eraser />
                      <span>{t('app.menu.removeObjectsMode')}</span>
                    </Link>
                    <Link
                      style={{ display: showEditorTools ? 'block' : 'none' }}
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      id="upscale-4k"
                      onClick={onActiveUpgradeTo4K}
                      className="nav-link"
                    >
                      <svg
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="bi bi-badge-4k"
                      >
                        <path d="M4.807 5.001C4.021 6.298 3.203 7.6 2.5 8.917v.971h2.905V11h1.112V9.888h.733V8.93h-.733V5.001h-1.71zm-1.23 3.93v-.032a46.781 46.781 0 0 1 1.766-3.001h.062V8.93H3.577zm9.831-3.93h-1.306L9.835 7.687h-.057V5H8.59v6h1.187V9.075l.615-.699L12.072 11H13.5l-2.232-3.415 2.14-2.584z" />
                        <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
                      </svg>
                      <span>{t('app.menu.unBluryMode')}</span>
                    </Link>
                    {/* <Link
                      style={{display: showEditorTools ? 'block' : 'none'}}
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      id="remove-bg"
                      onClick={onActiveRemoveBackground}
                      className="nav-link"
                    >
                      <svg width="24" height="24" fill="currentColor" viewBox="0 0 7.68 7.68" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.687 2.4H1.432a.593.593 0 0 0-.593.593v3.255a.593.593 0 0 0 .593.593h3.255a.593.593 0 0 0 .593-.593V2.993a.593.593 0 0 0-.593-.593m-.128 3.72h-3v-3h3ZM3.96 1.2a.36.36 0 0 1 .36-.36h.48a.36.36 0 0 1 0 .72h-.48a.36.36 0 0 1-.36-.36m2.88.24v.24a.36.36 0 0 1-.72 0v-.12H6a.36.36 0 0 1 0-.72h.24a.6.6 0 0 1 .6.6m0 1.44v.48a.36.36 0 0 1-.72 0v-.48a.36.36 0 0 1 .72 0m0 1.68v.24a.6.6 0 0 1-.6.6H6a.36.36 0 0 1 0-.72h.12v-.12a.36.36 0 0 1 .72 0M2.28 1.68v-.24a.6.6 0 0 1 .6-.6h.24a.36.36 0 0 1 0 .72H3v.12a.36.36 0 0 1-.72 0"/>
                      </svg>
                      <span>{t('app.menu.removeBGMode')}</span>
                    </Link> */}

                    <Link
                      id="my-photos"
                      to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}
                      className="nav-link"
                      onClick={onActiveMyPhoto}
                    >
                      <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3,11h7a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1H3A1,1,0,0,0,2,3v7A1,1,0,0,0,3,11ZM4,4H9V9H4ZM22,3a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1ZM20,9H15V4h5ZM2,21a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1H3a1,1,0,0,0-1,1Zm2-6H9v5H4Zm18,6V14a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7A1,1,0,0,0,22,21Zm-2-1H15V15h5Z" />
                      </svg>
                      <span>{t('app.menu.myPhotos')}</span>
                    </Link>
                  </div>
                  <div className="left-down-menu">
                    <div className="idea-alert">
                      <a href="https://reok.lemonsqueezy.com/affiliates" target="_blank">
                        <div className="idea-inner">
                          <div className="ref-title">
                            <svg
                              stroke="#ef4444"
                              fill="#ef4444"
                              strokeWidth="0"
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M160 894c0 17.7 14.3 32 32 32h286V550H160v344zm386 32h286c17.7 0 32-14.3 32-32V550H546v376zm334-616H732.4c13.6-21.4 21.6-46.8 21.6-74 0-76.1-61.9-138-138-138-41.4 0-78.7 18.4-104 47.4-25.3-29-62.6-47.4-104-47.4-76.1 0-138 61.9-138 138 0 27.2 7.9 52.6 21.6 74H144c-17.7 0-32 14.3-32 32v140h366V310h68v172h366V342c0-17.7-14.3-32-32-32zm-402-4h-70c-38.6 0-70-31.4-70-70s31.4-70 70-70 70 31.4 70 70v70zm138 0h-70v-70c0-38.6 31.4-70 70-70s70 31.4 70 70-31.4 70-70 70z" />
                            </svg>
                            <span>{t('app.menu.affiliateTitle')}</span>
                          </div>
                          <div className="ref-des">{t('app.menu.affiliateSub')}</div>
                        </div>
                      </a>
                    </div>
                    <div className="dropdown">
                      <NewAvatarHeader userData={userData} t={t} darkMode={darkMode} onSelectDarkMode={onSelectDarkMode} onShowFeedback={onShowFeedback} />
                    </div>
                  </div>
                </div>
              </div>
              <Box sx={{ 
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 1000,
                  borderTop: darkMode ? '1px solid #333' : '1px solid #e0e0e0',
                  backgroundColor: darkMode ? '#121212' : '#fff'
                }}>
                <BottomNavigation
                  showLabels
                  value={activeMenuTab}
                  sx={{
                    backgroundColor: darkMode ? '#121212' : '#fff',
                    '& .MuiBottomNavigationAction-root': {
                      color: darkMode ? '#666' : '#999',
                      '&.Mui-selected': {
                        color: darkMode ? '#fff' : '#000',
                      },
                    },
                    '& .MuiBottomNavigationAction-label': {
                      '&.Mui-selected': {
                        fontSize: '0.75rem',
                      },
                    },
                    '& svg': {
                      color: 'currentColor',
                    },
                  }}
                  onChange={(event, newValue) => {
                    setActiveMenuTab(newValue);
                    switch(newValue) {
                      case 0:
                        onActiveHome({ currentTarget: document.getElementById('home') });
                        navigate('/app');
                        break;
                      case 1:
                        onActiveHome({ currentTarget: document.getElementById('home') });
                        startHeadshots();
                        break;
                      case 2:
                        onActiveProfessional({ currentTarget: document.getElementById('generator') });
                        navigate('/app');
                        break;
                      case 3:
                        onActiveMyPhoto({ currentTarget: document.getElementById('my-photos') });
                        navigate('/app');
                        break;
                    }
                  }}
                >
                  <BottomNavigationAction 
                    label={t('app.menu.home')} 
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M15 21v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8" /><path d="M3 10a2 2 0 0 1 .709-1.528l7-5.999a2 2 0 0 1 2.582 0l7 5.999A2 2 0 0 1 21 10v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /></svg>} 
                  />
                  <BottomNavigationAction 
                    label={"Headshots"} 
                    icon={<CenterFocusStrongIcon/>}
                  />
                  <BottomNavigationAction 
                    label={t('app.menu.studioMode')} 
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-aperture"><circle cx="12" cy="12" r="10" /><path d="m14.31 8 5.74 9.94" /><path d="M9.69 8h11.48" /><path d="m7.38 12 5.74-9.94" /><path d="M9.69 16 3.95 6.06" /><path d="M14.31 16H2.83" /><path d="m16.62 12-5.74 9.94" /></svg>} 
                  />
                  <BottomNavigationAction 
                    label={t('app.menu.myPhotos')} 
                    icon={
                      <svg fill="currentColor" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3,11h7a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1H3A1,1,0,0,0,2,3v7A1,1,0,0,0,3,11ZM4,4H9V9H4ZM22,3a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1ZM20,9H15V4h5ZM2,21a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1H3a1,1,0,0,0-1,1Zm2-6H9v5H4Zm18,6V14a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7A1,1,0,0,0,22,21Zm-2-1H15V15h5Z" />
                      </svg>  
                    } 
                  />
                </BottomNavigation>
              </Box>
            </div>
          )}

          <div id="scroll-top">
            {showHome && (
              <HomePage
                userData={userData}
                firebase={firebase}
                t={t}
                darkMode={darkMode}
                onSelectDarkMode={onSelectDarkMode}
                onShowFeedback={onShowFeedback}
                setUser={setUser}
                setUserData={setUserData}
                pack={pack}
                openStudioPipeLine={openStudioPipeLine}
                onActiveUpgradeTo4K={onActiveUpgradeTo4K}
                onRemoveObject={onActiveRemoveObject}
                showHeadshots={showHeadshots}
                handleOpenStudioDialog={handleOpenStudioDialog}
                onHideMenuBar={onHideMenuBar}
              />
            )}
            {showTrainModel && (
              <TrainModel
                userData={userData}
                firebase={firebase}
                t={t}
                darkMode={darkMode}
                onSelectDarkMode={onSelectDarkMode}
                onShowFeedback={onShowFeedback}
                setUser={setUser}
                setUserData={setUserData}
                handleSelectCollection={handleSelectModel}
                openStudio={openStudio}
                onShowPricing={onShowPricing}
                onHideMenuBar={onHideMenuBar}
              />
            )}
            {showProfessional && (
              <Studio
                userData={userData}
                firebase={firebase}
                t={t}
                darkMode={darkMode}
                onSelectDarkMode={onSelectDarkMode}
                onShowFeedback={onShowFeedback}
                setUser={setUser}
                setUserData={setUserData}
                openTrainModel={openTrainModel}
                onShowPricing={onShowPricing}
                handleOnUpImageResolution={handleOnUpImageResolution}
                onMagicFixer={onMagicRemix}
                onActiveUpgradeTo4K={onActiveUpgradeTo4K}
                onRemoveObject={handleOnRemoveObject}
                runModel={runModel}
                remixFile={remixFile}
                setRemixFile={setRemixFile}
                selectedCollection={selectedCollection}
                setSelectedCollection={setSelectedCollection}
                onSetUpdateMyAssets={onSetUpdateMyAssets}
                onHideMenuBar={onHideMenuBar}
              />
            )}
            {showMyDesign && (
              <div className="right-area">
                <MyDesign
                  userData={userData}
                  checkCredits={checkCredits}
                  firebase={firebase}
                  t={t}
                  isCreating={isCreating}
                  onFetchUser={onFetchUser}
                  onUpImageResolution={handleOnUpImageResolution}
                  onRemoveBG={handleOnRemoveObject}
                  onMagicFixer={onMagicRemix}
                  darkMode={darkMode}
                  isMobile={isMobile}
                  updateMyAssets={updateMyAssets}
                  setUpdateMyAssets={setUpdateMyAssets}
                />
              </div>
            )}
            {showEditor && (
              <div className="right-area-editor">
                <MainEditor
                  userData={userData}
                  firebase={firebase}
                  darkMode={darkMode}
                  isMobile={isMobile}
                  model={model}
                  userInputImage={file}
                  removeImageFromEditor={() => setFile(null)}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <NewPricing
        t={t}
        currentLanguage={currentLanguage}
        userData={userData}
        firebase={firebase}
        onShowPricing={onShowPricing}
        onFetchUser={onFetchUser}
        startHeadshots={startHeadshots}
      />
      {/* {isSpinning && <Spinner />} */}
      <Feedback isOpen={showFeedbackModal} onClose={() => setShowFeedbackModal(false)} isSubmitted={isSubmittedFeedback} onSubmitted={handleSubmittedFeedback} currentLanguage={currentLanguage} t={t} userData={userData} engine={firebase} />
      {/* Notification Badge */}
      <div style={{ position: 'fixed', top: 10, right: 28, zIndex: 1000, display: (!isMobile && showEditor) ? 'none' : 'block' }}>
        <Badge
          color="secondary"
          badgeContent={notifications.filter(notification => !notification.read).length}
          onClick={handleNotificationClick}
          sx={{
            cursor: 'pointer',
            '& .MuiBadge-badge': { backgroundColor: '#fc5185' } // Change 'yourCustomColor' to your desired color
          }}
        >
          <NotificationsIcon sx={{ color: darkMode || isMobile ? '#fff' : '#000' }} />
        </Badge>
      </div>

      {showNotification && (
        <div
          style={{
            position: 'fixed',
            top: '46px',
            right: '10px',
            marginLeft: isMobile ? '10px' : '',
            zIndex: 1001,
            backgroundColor: darkMode ? '#121212b3' : '#ffffffb3',
            color: darkMode ? '#fff' : '#000',
            boxShadow: 'rgb(0 0 0 / 18%) 0px 2px 10px',
            borderRadius: '8px',
            padding: '10px',
            minWidth: '300px',
            maxWidth: '450px',
            maxHeight: '60vh',
            overflowY: 'auto',
            border: darkMode ? '1px solid #777777' : '1px solid #e0e0e0',
            backdropFilter: 'blur(10px)',
            WebkitBackdropFilter: 'blur(10px)'
          }}
          ref={notificationRef}
        >
          <div className="fw-semi-bold py-2 px-2">
            All notifications
          </div>
          <div className="dropdown-divider" />
          {notifications.length === 0 && (
            <div className="text-center py-2">
              No notifications found
            </div>
          )}
          {notifications.map((notification, index) => {
            let icon, primary, color;
            const isHighlight = index === 0;

            if (notification.message.topic === "training") {
              icon = <ModelTrainingIcon />;
              primary = <div>{notification.message.content}</div>;
            } else if (notification.message.topic === "photo-pipeline-done") {
              icon = <div><AutoAwesomeIcon /></div>;
              primary = <div>
                <div>{notification.message.content}</div>
                <div>check it here → <span onClick={onActiveMyPhoto} style={{ cursor: 'pointer' }} className='coupon-card'>My assets</span></div>
              </div>
            } else if (notification.message.topic === "generating") {
              icon = isHighlight ? (
                <div className="rotating-icon"><CameraIcon /></div>
              ) : (
                <CameraIcon />
              ); primary = <div>{notification.message.content}</div>;
            } else if (notification.message.topic === "purchased") {
              icon = <PaidIcon />;
              primary = <div>
                <span>{notification.message.content}</span>
                <span> check your order here → <a href="https://reok.lemonsqueezy.com/billing" target="_blank">Billing</a></span>
              </div>;
            }

            color = isHighlight ? (darkMode ? '#3eff76' : '#00bb36') : (darkMode ? '#a0a0a0' : '#666666');

            return (
              <ListItem key={index} sx={{ color: color }}>
                <ListItemAvatar sx={{ marginRight: '-5px' }}>
                  {icon}
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    color: isHighlight ? (darkMode ? '#fff' : '#000') : color,
                    '& .MuiListItemText-secondary': {
                      color: isHighlight ? (darkMode ? '#fff' : '#000') : color
                    }
                  }}
                  primary={primary}
                  secondary={new Date(notification.createdAt).toLocaleString()}
                />
              </ListItem>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NewHome;
