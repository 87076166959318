import React, { useEffect, useState, memo } from 'react';
import { message } from 'antd';

import LoaderLineMask from '../LoaderLineMask';
import axios from 'axios';
import ImageResult from '../ImageResult';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import CollectionCard from '../CollectionCard';
import NewSkeleton from '../NewSkeleton';
import ExportPhoto from '../ExportPhoto';

const APP_URL = process.env.REACT_APP_APP_URL;

const photographerStyle = "photographer_style";
const photographerPose = "photographer_pose";

const modes = {
  pro: 'Pro',
  basic: 'Basic',
};

const StyledTabs = styled(({ darkMode, ...props }) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ darkMode }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    width: "100%",
  },
  '& .MuiTabs-indicatorSpan': {
    width: '80%',
    // backgroundColor: "#fb5183",
    backgroundImage: "linear-gradient(115deg, #4fcf70, #fad648, #a767e5, #12bcfe, #44ce7b)"
  },
}));

const StyledTab = styled(({ darkMode, ...props }) => (
  <Tab disableRipple {...props} />
))(({ darkMode }) => ({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: 16,
  fontFamily: 'inherit',
  letterSpacing: "-0.01em",
  backgroundColor: 'transparent',
  color: darkMode ? '#5d5d5d' : '#15293c80',
  '&.Mui-selected': {
    color: darkMode ? 'white' : 'black',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: "-0.01em"
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "16px" }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const AllShowCase = memo(({ userData, currentJob, t, firebase, onGenFill, onFetchUser, checkCredits, onUpImageResolution, onRemoveBG, progress, handleSelectCollection, darkMode, onMagicFixer, isMobile, mode}) => {
  const [jobsList, setJobsList] = useState([]);
  const [imageSrcCompare, setImageSrcCompare] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [showShopFurniture, setShowShopFurniture] = useState(false);
  const [imageShop, setImageShop] = useState(null);
  const [imageSrcEdit, setImageSrcEdit] = useState();
  const [showEditImage, setShowEditImage] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isFaceNotFound, setIsFaceNotFound] = useState(false);
  const [value, setValue] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showExportPhoto, setShowExportPhoto] = useState(false);

  const collectionInfo = [
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F13.webp?alt=media&token=0a46442d-790b-4622-aedf-75cc762c7979",
      photographerUrl: "/images/sam.webp",
      name: t('styles.cyberpunk_style_bathroom'),
      style: "Cyberpunk-style bath room home interior With synthwave and led lights and surrealist paintings and military uniforms and gear and color lights glow and clean straight square lines and bladerunner lights and futuristic cybernetic city. With mirror and bathroom sink with faucet and bathtub and waste basket and toilet seat and bath towel and plant and bathroom cabinet and bath rail and shower. Cinematic photo, highly detailed, cinematic lighting, ultra-detailed, ultrarealistic, photorealism, 8k. Cyberpunk interior design style",
      roomType: "bath_room",
      styleCode: "cyberpunk_style_bathroom",
      gender: "man"
    },
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F14.webp?alt=media&token=dd770fb8-1734-4eaf-bbb9-987bbf62412a",
      photographerUrl: "/images/sam.webp",
      name: t('styles.eclectic_style_coworking_space'),
      style: "eclectic-style (coworking space interior) with seating area with sofa and office desks and office chairs and lounge chairs and seating area with sofa. . . cinematic photo, highly detailed, cinematic lighting, ultra-detailed, ultrarealistic, photorealism, 8k. eclectic interior design style. masterpiece, cinematic light, ultrarealistic+, photorealistic+, 8k, raw photo, realistic, sharp focus on eyes, (symmetrical eyes), (intact eyes), hyperrealistic, highest quality, best quality, , highly detailed, masterpiece, best quality, extremely detailed 8k wallpaper, masterpiece, best quality, ultra-detailed, best shadow, detailed background, detailed face, detailed eyes, high contrast, best illumination, detailed face, dulux, caustic, dynamic angle, detailed glow. dramatic lighting",
      roomType: "coworking_space",
      styleCode: "eclectic_style_coworking_space",
      gender: "woman"
    },
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F15.webp?alt=media&token=cd56bc86-b72b-4033-8df0-69faa2cf5af2",
      photographerUrl: "/images/sam.webp",
      name: t('styles.maximalist_style_bedroom'),
      style: "maximalist-style (bedroom interior) with plant and accent chair and storage bench or ottoman and night light and bedside table or night stand and bed and dresser closet and headboard. . with bold colors and eye-catching and vibrant and playful and bold design and more is more philosophy and over-the-top aesthetic and bold creativity. . cinematic photo, highly detailed, cinematic lighting, ultra-detailed, ultrarealistic, photorealism, 8k. maximalist interior design style. masterpiece, cinematic light, ultrarealistic+, photorealistic+, 8k, raw photo, realistic, sharp focus on eyes, (symmetrical eyes), (intact eyes), hyperrealistic, highest quality, best quality, , highly detailed, masterpiece, best quality, extremely detailed 8k wallpaper, masterpiece, best quality, ultra-detailed, best shadow, detailed background, detailed face, detailed eyes, high contrast, best illumination, detailed face, dulux, caustic, dynamic angle, detailed glow. dramatic lighting. highly detailed, insanely detailed hair, symmetrical, intricate details, professionally retouched, 8k high definition. strong bokeh. award winning photo.",
      roomType: "bedroom",
      styleCode: "maximalist_style_bedroom",
      gender: "man"
    },
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F16.webp?alt=media&token=44cc3799-e0d9-42bc-b629-8ec88271ffba",
      photographerUrl: "/images/sam.webp",
      name: t('styles.contemporary_style_hotel_bedroom'),
      style: "contemporary-style (bedroom interior) with night light and plant and bed and headboard and mirror and dresser closet and storage bench or ottoman and accent chair. . with . . cinematic photo, highly detailed, cinematic lighting, ultra-detailed, ultrarealistic, photorealism, 8k. contemporary interior design style. masterpiece, cinematic light, ultrarealistic+, photorealistic+, 8k, raw photo, realistic, sharp focus on eyes, (symmetrical eyes), (intact eyes), hyperrealistic, highest quality, best quality, , highly detailed, masterpiece, best quality, extremely detailed 8k wallpaper, masterpiece, best quality, ultra-detailed, best shadow, detailed background, detailed face, detailed eyes, high contrast, best illumination, detailed face, dulux, caustic, dynamic angle, detailed glow. dramatic lighting. highly detailed, insanely detailed hair, symmetrical, intricate details, professionally retouched, 8k high definition. strong bokeh. award winning photo.",
      roomType: "hotel_room",
      styleCode: "contemporary_style_hotel_bedroom",
      gender: "man"
    },
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F10.webp?alt=media&token=6c099d6e-7f31-4826-a36d-a79f10b70c5b",
      photographerUrl: "/images/sam.webp",
      name: t('styles.zen_style_bedroom'),
      style: "zen-style (bedroom interior) with accent chair and dresser closet and bedside table or night stand and bed and plant and storage bench or ottoman and headboard and mirror. . with asian interior and natural light and japanese minimalist interior and japanese minimalist interior and mimimalist and japanese interior and simplicity and calm and neutral colors. . cinematic photo, highly detailed, cinematic lighting, ultra-detailed, ultrarealistic, photorealism, 8k. zen interior design style. masterpiece, cinematic light, ultrarealistic+, photorealistic+, 8k, raw photo, realistic, sharp focus on eyes, (symmetrical eyes), (intact eyes), hyperrealistic, highest quality, best quality, , highly detailed, masterpiece, best quality, extremely detailed 8k wallpaper, masterpiece, best quality, ultra-detailed, best shadow, detailed background, detailed face, detailed eyes, high contrast, best illumination, detailed face, dulux, caustic, dynamic angle, detailed glow. dramatic lighting. highly detailed, insanely detailed hair, symmetrical, intricate details, professionally retouched, 8k high definition. strong bokeh. award winning photo.",
      roomType: "bedroom",
      styleCode: "zen_style_bedroom",
      gender: "man"
    },
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F9.webp?alt=media&token=e1fddc9a-e7bf-45c2-bc57-50d85cd70b9e",
      photographerUrl: "/images/sam.webp",
      name: t('styles.cyberpunk_style_outdoor_patio'),
      style: "cyberpunk-style designed (outdoor patio ) with patio couch with pillows and grass and deck with deck chairs and barbeque or grill and plant and patio couch with pillows. . with color lights glow and led lights and bladerunner lights and synthwave and synthetic objects and dark night and military uniforms and gear and strong geometric walls. . cinematic photo, highly detailed, cinematic lighting, ultra-detailed, ultrarealistic, photorealism, 8k. cyberpunk design style. masterpiece, cinematic light, ultrarealistic+, photorealistic+, 8k, raw photo, realistic, sharp focus on eyes, (symmetrical eyes), (intact eyes), hyperrealistic, highest quality, best quality",
      roomType: "patio",
      styleCode: "cyberpunk_style_outdoor_patio",
      gender: "woman"
    },
    {
      photographerCode: photographerStyle,
      photographer: "David",
      photographerName: t('photographers.david.name'),
      title: t('photographers.david.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F1.webp?alt=media&token=75534d6b-8876-4135-b46c-beb8eedaacae",
      photographerUrl: "/images/sam.webp",
      name: t('styles.midcentury_modern_style_pool'),
      style: "midcentury modern-style designed (outdoor pool area ) with pool lights and pool and pool lounge chairs and pool lights. . with natural and manmade materials and minimalist and organic and geometric shapes and nature indoors and integrating indoor and outdoor motifs and muted tones and wood pendant light mid century modern chandelier and clean lines. . cinematic photo, highly detailed, cinematic lighting, ultra-detailed, ultrarealistic, photorealism, 8k. midcentury modern design style. masterpiece, cinematic light, ultrarealistic+, photorealistic+, 8k, raw photo, realistic, sharp focus on eyes, (symmetrical eyes), (intact eyes), hyperrealistic, highest quality, best quality",
      roomType: "pool_area",
      styleCode: "midcentury_modern_style_pool",
      gender: "woman"
    },
    {
      photographerCode: photographerStyle,
      photographer: "David",
      photographerName: t('photographers.david.name'),
      title: t('photographers.david.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F2.webp?alt=media&token=303c67a6-1c2a-424f-aec5-48fe707beefa",
      photographerUrl: "/images/sam.webp",
      name: t('styles.easter_style_drop_zone'),
      style: "easter-style (drop zone interior) with cabinets and cubbies and storage drawers and shelves for shoes and a bench and high up storage and storage baskets and wall hooks for coats. . with flowers on table and spring decorations and light blue colors and colorful easter eggs and easter decorations and flowers on table. . cinematic photo, highly detailed, cinematic lighting, ultra-detailed, ultrarealistic, photorealism, 8k. easter interior design style. masterpiece, cinematic light, ultrarealistic+, photorealistic+, 8k, raw photo, realistic, sharp focus on eyes, (symmetrical eyes), (intact eyes), hyperrealistic, highest quality, best quality, , highly detailed, masterpiece, best quality, extremely detailed 8k wallpaper, masterpiece, best quality, ultra-detailed, best shadow, detailed background, detailed face, detailed eyes, high contrast, best illumination, detailed face, dulux, caustic, dynamic angle, detailed glow. dramatic lighting. highly detailed, insanely detailed hair, symmetrical, intricate details, professionally retouched, 8k high definition. strong bokeh. award winning photo.",
      roomType: "drop_zone",
      styleCode: "easter_style_drop_zone",
      gender: "woman"
    },
    {
      photographerCode: photographerStyle,
      photographer: "David",
      photographerName: t('photographers.david.name'),
      title: t('photographers.david.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F3.webp?alt=media&token=6edca17c-1600-42e4-bbd1-8aab2ef04d1d",
      photographerUrl: "/images/sam.webp",
      name: t('styles.coastal_style_kitchen'),
      style: "coastal-style (kitchen interior) with sink and stove and refrigerator and plant and worktops and kitchen cabinets and sink. . with . . cinematic photo, highly detailed, cinematic lighting, ultra-detailed, ultrarealistic, photorealism, 8k. coastal interior design style. masterpiece, cinematic light, ultrarealistic+, photorealistic+, 8k, raw photo, realistic, sharp focus on eyes, (symmetrical eyes), (intact eyes), hyperrealistic, highest quality, best quality, , highly detailed, masterpiece, best quality, extremely detailed 8k wallpaper, masterpiece, best quality, ultra-detailed, best shadow, detailed background, detailed face, detailed eyes, high contrast, best illumination, detailed face, dulux, caustic, dynamic angle, detailed glow. dramatic lighting. highly detailed, insanely detailed hair, symmetrical, intricate details, professionally retouched, 8k high definition. strong bokeh. award winning photo.",
      roomType: "kitchen",
      styleCode: "coastal_style_kitchen",
      gender: "woman"
    }
  ]

  useEffect(() => {
    // Check job status then add to the list
    if (currentJob !== undefined && currentJob !== null) {
      if (currentJob.status === "starting") {
        setIsCreating(true);
        setIsFaceNotFound(false);
        setValue(1);

        // try {
        //   var reader = new FileReader();
        //   reader.onload = function(event) {
        //     var dataURL = event.target.result;
        //     document.getElementById('img_src_loader').src = dataURL;
        //   };
        //   reader.readAsDataURL(currentJob.images.before);
        // } catch (error) {
        //   setIsCreating(false);
        //   console.log(error);
        // }
      }
      else if (currentJob.status === "finished") {
        const mergedJobsList = [...[currentJob], ...jobsList];
        setValue(1);
        setIsCreating(false);
        setJobsList(mergedJobsList);
      }
      else if (currentJob.status === "failed-face") {
        setValue(1);
        setIsFaceNotFound(true);
      }
    }
  }, [currentJob]);

  // useEffect(() => {
  //   setCurrentProgress(progress);
  // }, [progress]);

  function generateRandomFileName(extension) {
    const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
    return `reok-${randomNumber}.${extension}`;
  }

  const onCompareImage = (images) => {
    setImageSrcCompare(images);
    setShowExportPhoto(true);
  }

  const onFindAgency = (image) => {
    setOpenForm(true);
    setImageShop(image);
    setShowShopFurniture(false);
  }

  const onEditImage = (imageUrl) => {

    if (checkCredits()) {
      setShowEditImage(false);
      return;
    }

    setImageSrcEdit(imageUrl);
    setShowEditImage(true);
  }

  const onCloseImageCompare = () => {
    setShowExportPhoto(false);
  }

  const onDownloadImage = (image) => {
    setIsDownloading(true);

    try {
      setTimeout(async () => {
      const response = await fetch(image);
      const blob = await response.blob();
  
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      const fileName = generateRandomFileName('jpeg');
      link.download = fileName;
      link.click();
  
      // Clean up the temporary URL
      URL.revokeObjectURL(url);
      setIsDownloading(false);

      }, 2000);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  }

  const onRemoveFromList = (id) => {
    const updatedJobList = jobsList.filter(job => job.id !== id);
    setJobsList(updatedJobList);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function onSelectCollection(e, collection) {
    e.preventDefault();
    handleSelectCollection(collection);
  }

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div 
      className={`results-area ${darkMode? 'dark-bg' : ''}`}
    >
      <Box sx={{ borderBottom: 1, borderColor: darkMode ? '#373737' : '#f1f3f4' }}>
        <StyledTabs
          darkMode={darkMode}
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
          variant="scrollable"
          scrollButtons={false}
          >
          <StyledTab darkMode={darkMode} label={"🔥 " + t('showcase.generator.hotPack')} />
          <StyledTab darkMode={darkMode} label={"📸 " + t('showcase.generator.title')} />
        </StyledTabs>
      </Box>
      <CustomTabPanel value={value} index={0} >
        <div className="rnftg-h-full rnftg-w-full">
          <div className="rnftg-h-full dark:rnftg-bg-gray-900">
            <div className="rnftg-flex rnftg-flex-col pd-2">
              <div className="rnftg-grid rnftg-gap-4 rnftg-grid-flow-row rnftg-grid-cols-2 md:rnftg-grid-cols-3 lg:rnftg-grid-cols-3 xl:rnftg-grid-cols-3">
                {collectionInfo.map((c, i) => {
                  return (
                    <CollectionCard darkMode={darkMode} key={i} t={t} info={c} onSelectCollection={onSelectCollection}/>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
          <div className="rnftg-h-full rnftg-w-full" id="my-result">
            <div className="rnftg-h-full dark:rnftg-bg-gray-900">
              <div className="rnftg-flex rnftg-flex-col pd-2">
                <div className="rnftg-grid rnftg-gap-6 rnftg-grid-flow-row rnftg-grid-cols-1 md:rnftg-grid-cols-2 lg:rnftg-grid-cols-2 xl:rnftg-grid-cols-2">
                  {isCreating &&
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        {!isFaceNotFound ?
                          (
                          <div className="result">
                            <LoaderLineMask status={progress}/>
                            <span className="rnftg-no-underline rnftg-text-black dark:rnftg-text-gray-200">
                              {!imageLoaded && (
                                <NewSkeleton/>
                                )
                              }
                              {currentJob.images.before &&
                                <img
                                  className={`rnftg-w-full rnftg-h-full rnftg-object-cover rnftg-cursor-pointer rnftg-rounded-t-2xl ${imageLoaded ? 'rendering-mask' : ''}`}
                                  alt="reok"
                                  src={currentJob.images.before}
                                  onLoad={handleImageLoad}
                                />
                              }
                              <div className="img_src_loader"></div>
                              <div className="prompt-label">{t('message.prompt.rendering') + " - " + currentJob.result + " - " + currentJob.prompt}</div>
                            </span>
                          </div>
                          ) : (
                          <div className="result">
                            <div className="line-mask">
                              <div className="circular-progress">
                                <svg width="20px" height="20px" viewBox="0 0 0.5 0.5" xmlns="http://www.w3.org/2000/svg">
                                  <path fill="#d32f2f" d="M0.49 0.397 0.3 0.048C0.288 0.025 0.269 0.013 0.25 0.013c-0.02 0 -0.038 0.013 -0.05 0.036L0.01 0.397c-0.012 0.023 -0.014 0.046 -0.003 0.063 0.01 0.018 0.03 0.028 0.055 0.028h0.376c0.025 0 0.045 -0.01 0.056 -0.028 0.01 -0.018 0.009 -0.04 -0.004 -0.063ZM0.25 0.121c0.01 0 0.018 0.008 0.018 0.018v0.165c0 0.01 -0.008 0.018 -0.018 0.018a0.018 0.018 0 0 1 -0.018 -0.018V0.14c0 -0.01 0.008 -0.018 0.018 -0.018Zm0 0.291c-0.015 0 -0.028 -0.013 -0.028 -0.028 0 -0.016 0.013 -0.029 0.028 -0.029 0.015 0 0.028 0.013 0.028 0.028 0 0.016 -0.013 0.029 -0.028 0.029Z"/>
                                </svg>
                              </div>
                            </div>
                            <span className="rnftg-no-underline rnftg-text-black dark:rnftg-text-gray-200">
                              <img
                                className="rnftg-w-full rnftg-h-full rnftg-object-cover rnftg-cursor-pointer rnftg-rounded-t-2xl rendering-mask"
                                id="img_src_loader"
                                loading="lazy"
                                src={currentJob.images.before}
                              />
                              <div className="img_src_loader"></div>
                              <div className="prompt-label">{t('message.prompt.faceNotFound')}</div>
                            </span>
                          </div>
                          )
                        }
                      </div>
                    </article>
                  }
                  {jobsList.length > 0 &&
                    jobsList.map((job, key) => {
                      job.originalUrl = job.images.before;
                      job.upscaleUrl = job.images.after;

                      return (
                      <ImageResult 
                        t={t} 
                        firebase={firebase} 
                        userData={userData} 
                        key={key} 
                        job={job} 
                        onCompareImage={onCompareImage} 
                        onDownloadImage={onDownloadImage} 
                        onFindAgency={onFindAgency} 
                        onEditImage={onEditImage} 
                        onRemoveBG={onRemoveBG} 
                        isDownloading={isDownloading} 
                        onUpImageResolution={onUpImageResolution} 
                        onRemoveFromList={onRemoveFromList} 
                        hideRenew={true} 
                        darkMode={darkMode} 
                        onMagicFixer={onMagicFixer} 
                        mode={mode}
                      />
                    )}
                  )}
                </div>
              </div>
            </div>
          </div>
      </CustomTabPanel>
      {/* <section className="selection-thanks-customer">
        <span className="container display-4 fw-bold-600">{t('milestoneMessage.first')}<span className="particletext hearts"> 10.000 </span>{t('milestoneMessage.second')}</span>
      </section> */}
      {/* { showCompareImage && <CompareImage images={imageSrcCompare} onClose={onCloseImageCompare}></CompareImage>} */}
      {showExportPhoto && <ExportPhoto onClose={onCloseImageCompare} images={imageSrcCompare} isMobile={isMobile}></ExportPhoto>}
      {/* { showShopFurniture && <ShopFurniture checkCredits={checkCredits} productsList={productsList} firebase={firebase} t={t} image={imageShop} onClose={onCloseShopFurniture}></ShopFurniture>} */}
      {/* { showEditImage && <EditImage checkCredits={checkCredits} imageUrl={imageSrcEdit} onClose={onCloseImageEdit} firebase={firebase} t={t} onGenFill={onGenFill} onChangeImageSrc={onChangeImageSrc} onFetchUser={onFetchUser}></EditImage>} */}
      {/* { searching && <Searching title="Searching products"/> } */}
      {/* { openForm && <AgencyForm userData={userData} productsList={productsList} firebase={firebase} t={t} image={imageShop} onClose={onCloseAgencyForm}></AgencyForm>} */}
    </div>
  );
});

export default AllShowCase;
