import { createSlice } from '@reduxjs/toolkit';

const pricingSlice = createSlice({
    name: 'pricing',
    initialState: {
        isOpen: false,
        activeTab: '2',
    },
    reducers: {
        openPricingDialog: (state) => {
            state.isOpen = true;
        },
        closePricingDialog: (state) => {
            state.isOpen = false;
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
    },
});

export const { openPricingDialog, closePricingDialog, setActiveTab } = pricingSlice.actions;
export default pricingSlice.reducer;