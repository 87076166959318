import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import BeforeAfterTestimonial from '../BeforeAfterTestimonial';

function ShowCaseSlide() {
  const [currentLanguage, setCurrentLanguage] = useState('');

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <>
      {/* <img src="/images/night-background.webp" alt="reok" className="absolute inset-0 object-cover object-bottom w-full" /> */}
      {/* <div className="login-background-wrapper">
        <picture className="login-background-item">
          <source
            srcSet="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/man%2Fman12.webp?alt=media&token=a312938d-22ef-4151-aaad-4dc30788919a"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="reok"
            width={321}
            height={321}
            src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/man%2Fman12.webp?alt=media&token=a312938d-22ef-4151-aaad-4dc30788919a"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/woman1.webp?alt=media&token=a7f80668-da65-4601-babc-1057c7b3947b"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="reok"
            width={521}
            height={699}
            src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/woman1.webp?alt=media&token=a7f80668-da65-4601-babc-1057c7b3947b"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/man%2Fman6.webp?alt=media&token=961501d6-f067-40ff-8309-2a6261aedd10"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="reok"
            width={521}
            height={719}
            src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/man%2Fman6.webp?alt=media&token=961501d6-f067-40ff-8309-2a6261aedd10"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/woman%2Fwoman6.webp?alt=media&token=ed1a2846-ecdb-454d-b9b5-c021b84bc8f0"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="reok"
            width={521}
            height={719}
            src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/woman%2Fwoman6.webp?alt=media&token=ed1a2846-ecdb-454d-b9b5-c021b84bc8f0"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/man%2Fman3.webp?alt=media&token=8defd699-b6a6-4c79-b5e2-f11ea3aafe98"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="reok"
            width={521}
            height={531}
            src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/man%2Fman3.webp?alt=media&token=8defd699-b6a6-4c79-b5e2-f11ea3aafe98"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/woman%2Fwoman9.webp?alt=media&token=7b4c30b1-50cc-44aa-b16e-606fa10a401f"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="reok"
            width={521}
            height={699}
            src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/woman%2Fwoman9.webp?alt=media&token=7b4c30b1-50cc-44aa-b16e-606fa10a401f"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/man%2Fman11.webp?alt=media&token=bafce6ed-aee5-4b51-84d0-492820d57a4c"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="reok"
            width={521}
            height={719}
            src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/man%2Fman11.webp?alt=media&token=bafce6ed-aee5-4b51-84d0-492820d57a4c"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/woman%2Fwoman8.webp?alt=media&token=22457bbe-18c8-4d17-8050-96cf54d3d0bb"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="reok"
            width={521}
            height={719}
            src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/woman%2Fwoman8.webp?alt=media&token=22457bbe-18c8-4d17-8050-96cf54d3d0bb"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/man%2Fman4.webp?alt=media&token=b1fdbe26-467c-422b-90d1-97c0616b7dcf"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="reok"
            width={521}
            height={531}
            src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/man%2Fman4.webp?alt=media&token=b1fdbe26-467c-422b-90d1-97c0616b7dcf"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/woman%2Fwoman11.webp?alt=media&token=2c8c5e68-42fa-4f68-9fbf-6d0456209ec7"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="reok"
            width={521}
            height={699}
            src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/woman%2Fwoman11.webp?alt=media&token=2c8c5e68-42fa-4f68-9fbf-6d0456209ec7"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/man%2Fman10.webp?alt=media&token=7994072c-5522-4b2b-b4c4-334f9d8ed32e"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="reok"
            width={521}
            height={719}
            src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/man%2Fman10.webp?alt=media&token=7994072c-5522-4b2b-b4c4-334f9d8ed32e"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/woman%2Fwoman7.webp?alt=media&token=fcdda8b8-2c44-41de-9192-d513a2b6758b"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="reok"
            width={521}
            height={531}
            src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/woman%2Fwoman7.webp?alt=media&token=fcdda8b8-2c44-41de-9192-d513a2b6758b"
          />
        </picture>
      </div> */}
      <div className="login-background-wrapper">
        <BeforeAfterTestimonial/>
      </div>
    </>
  );
}

export default ShowCaseSlide;
