import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import { styled } from '@mui/material/styles';
import PhotoPipeLine from '../PhotoPipeLine';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import AIHeadshotsDialog from '../AIHeadshotsDialog';
import { Grid, Card, CardContent, Typography, Skeleton } from '@mui/material';
import AIInfluencersDialog from '../AIInfluencersDialog';
import OpenIconSpeedDial from '../OpenIconSpeedDial';

const icons = {
  All: (
    <svg fill="currentColor" width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M3,11h7a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1H3A1,1,0,0,0,2,3v7A1,1,0,0,0,3,11ZM4,4H9V9H4ZM22,3a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1ZM20,9H15V4h5ZM2,21a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1H3a1,1,0,0,0-1,1Zm2-6H9v5H4Zm18,6V14a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7A1,1,0,0,0,22,21Zm-2-1H15V15h5Z" />
    </svg>
  ),
  Trending: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-flame"
    >
      <path d="M8.5 14.5A2.5 2.5 0 0 0 11 12c0-1.38-.5-2-1-3-1.072-2.143-.224-4.054 2-6 .5 2.5 2 4.9 4 6.5 2 1.6 3 3.5 3 5.5a7 7 0 1 1-14 0c0-1.153.433-2.294 1-3a2.5 2.5 0 0 0 2.5 2.5z" />
    </svg>
  ),
  ForCreator: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-thumbs-up"
    >
      <path d="M7 10v12" />
      <path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2a3.13 3.13 0 0 1 3 3.88Z" />
    </svg>
  ),
  ForBusiness: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-handshake"
    >
      <path d="m11 17 2 2a1 1 0 1 0 3-3" />
      <path d="m14 14 2.5 2.5a1 1 0 1 0 3-3l-3.88-3.88a3 3 0 0 0-4.24 0l-.88.88a1 1 0 1 1-3-3l2.81-2.81a5.79 5.79 0 0 1 7.06-.87l.47.28a2 2 0 0 0 1.42.25L21 4" />
      <path d="m21 3 1 11h-2" />
      <path d="M3 3 2 14l6.5 6.5a1 1 0 1 0 3-3" />
      <path d="M3 4h8" />
    </svg>
  ),
  CreateWithAI: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-wand-sparkles"
    >
      <path d="m21.64 3.64-1.28-1.28a1.21 1.21 0 0 0-1.72 0L2.36 18.64a1.21 1.21 0 0 0 0 1.72l1.28 1.28a1.2 1.2 0 0 0 1.72 0L21.64 5.36a1.2 1.2 0 0 0 0-1.72" />
      <path d="m14 7 3 3" />
      <path d="M5 6v4" />
      <path d="M19 14v4" />
      <path d="M10 2v2" />
      <path d="M7 8H3" />
      <path d="M21 16h-4" />
      <path d="M11 3H9" />
    </svg>
  ),
  AIHeadshots: <CenterFocusStrongIcon />,
  AIShorts: <MovieFilterIcon />,
  AIInfluencers: <FaceRetouchingNaturalIcon />,
  // Headshot: <Focus/>,
};

const StyledCard = styled(({ darkMode, ...props }) => <Card {...props} />)(({ darkMode }) => ({
  backgroundColor: darkMode ? '#1E1E1E' : '#FFFFFF',
  borderRadius: '12px',
  cursor: 'pointer',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
}));

const categories = [
  { name: 'All', icon: icons.All },
  { name: 'For headshot', icon: icons.Trending },
  // { name: 'Headshot', icon: icons.Headshot },
  { name: 'For creator', icon: icons.ForCreator },
  { name: 'For business', icon: icons.ForBusiness },
  { name: 'Magic AI tools', icon: icons.CreateWithAI },
];

const magicAITools = [
  // { title: 'Magic Fixer', icon: icons.MagicFixer, image: 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/admin%2Ffiles%2Fyoutube-thumbnail.png?alt=media&token=440d71ac-36f5-4f8d-b47d-1fb34868ff66', description: 'Fix your images magically.', isHot: false },
  {
    id: 'magic_eraser',
    title: 'Magic Eraser',
    icon: icons.MagicEraser,
    image:
      'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/ai-tools%2Fremove-objects.webp?alt=media&token=1940e37a-2caf-46ca-8c14-51148f71f0e0',
    description: 'Erase unwanted objects effortlessly.',
    isHot: false,
  },
  {
    id: 'magic_upscaler',
    title: 'Magic Upscaler',
    icon: icons.MagicUpscaler,
    image:
      'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/ai-tools%2Fupscale4k.webp?alt=media&token=894c02ec-0749-459b-91b5-3e5ffc31fcf0',
    description: 'Enhance image resolution seamlessly.',
    isHot: false,
  },
];

const ageItems = [];
for (let age = 18; age <= 100; age++) {
  ageItems.push({
    value: age,
    label: age,
  });
}

const StyledTabs = styled(({ darkMode, ...props }) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))(({ darkMode }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    width: '100%',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    // backgroundColor: "#fb5183",
    backgroundImage: 'linear-gradient(115deg, #4fcf70, #fad648, #a767e5, #12bcfe, #44ce7b)',
  },
}));

const StyledTab = styled(({ darkMode, ...props }) => <Tab disableRipple {...props} />)(({ darkMode }) => ({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: 16,
  fontFamily: 'inherit',
  letterSpacing: '-0.01em',
  backgroundColor: 'transparent',
  color: darkMode ? '#5d5d5d' : '#15293c80',
  '&.Mui-selected': {
    color: darkMode ? 'white' : 'black',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: '-0.01em',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

const CustomTabPanel = React.memo(function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  const content = useMemo(
    () =>
      value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      ),
    [children, value, index]
  );

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {content}
    </div>
  );
});

const PackSkeleton = ({ darkMode }) => (
  <Card
    sx={{
      backgroundColor: darkMode ? '#1a1a1a' : 'white',
      // border: darkMode ? '1px solid #333' : 'none',
      borderRadius: 2,
      overflow: 'hidden', // Ensure shimmer stays within bounds
      position: 'relative', // For shimmer positioning
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `linear-gradient(
          90deg,
          transparent 0%,
          ${darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(255, 255, 255, 0.5)'} 50%,
          transparent 100%
        )`,
        animation: 'shimmer 1.5s infinite',
        transform: 'translateX(-100%)',
      },
      '@keyframes shimmer': {
        '100%': {
          transform: 'translateX(100%)',
        },
      },
    }}
  >
    <Skeleton
      variant="rectangular"
      height={165}
      animation="wave"
      sx={{
        backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.06)',
        transform: 'scale(1)', // Prevents weird animation artifacts
      }}
    />
    <CardContent>
      <Skeleton
        variant="text"
        width="60%"
        height={24}
        animation="wave"
        sx={{
          backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.06)',
          transform: 'scale(1)',
          marginBottom: 1,
        }}
      />
      <Skeleton
        variant="text"
        width="90%"
        height={20}
        animation="wave"
        sx={{
          backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.06)',
          transform: 'scale(1)',
        }}
      />
    </CardContent>
  </Card>
);

const PackItem = ({ item, onClick, darkMode }) => (
  <Card
    onClick={onClick}
    sx={{
      borderRadius: 2,
      cursor: 'pointer',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      transition: 'transform 0.2s, box-shadow 0.2s',
      backgroundColor: darkMode ? '#1a1a1a' : 'white',
      // border: darkMode ? '1px solid #333' : 'none', // Added border for dark mode
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: darkMode ? '0 4px 20px rgba(0,0,0,0.5)' : 6, // Custom shadow for dark mode
      },
    }}
  >
    <div>
      <div className="relative aspect-w-16 aspect-h-9">
        <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
        {item.isHot && (
          <div
            className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full"
            style={{ marginTop: '-12px' }}
          >
            Hot
          </div>
        )}
      </div>
      <CardContent>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          gutterBottom
          fontFamily="inherit"
          sx={{ color: darkMode ? '#ffffff' : '#000000' }}
        >
          {item.title}
        </Typography>
        <Typography
          fontFamily="inherit"
          variant="body1"
          sx={{
            color: darkMode ? '#9ca3af' : '#6b7280',
            fontSize: '12px',
          }}
        >
          {item.description}
        </Typography>
      </CardContent>
    </div>
  </Card>
);

const HomePage = ({ firebase, setUser, darkMode, userData, pack, openStudioPipeLine, onActiveUpgradeTo4K, onRemoveObject, handleOpenStudioDialog, onHideMenuBar }) => {
  const [isActiveDeskMenu, setIsActiveDeskMenu] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [photoPipeLine, setPhotoPipeLine] = useState('');
  const [showPhotoPipeLine, setShowPhotoPipeLine] = useState(false);
  const [trendingPacks, setTrendingPacks] = useState([]);
  const [forCreatorPacks, setForCreatorPacks] = useState([]);
  const [forBusinessPacks, setForBusinessPacks] = useState([]);
  const [createWithAIPacks, setCreateWithAIPacks] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isOpenAIHeadshotsDialog, setIsOpenAIHeadshotsDialog] = useState(false);
  const [isOpenAIInfluencersDialog, setIsOpenAIInfluencersDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    // Simulate loading
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setIsLoadingData(true);
    firebase
      .getPhotoPacksOnLanding()
      .then(querySnapshot => {
        const sortedPacks = querySnapshot.docs
          .map(doc => doc.data())
          .sort((a, b) => {
            return (b.isHot ? 1 : 0) - (a.isHot ? 1 : 0) || new Date(a.createdAt) - new Date(b.createdAt); // Sort by isHot and then by createdAt
          });
        setTrendingPacks(sortedPacks.filter(pack => pack.category.includes('trending')));
        setForCreatorPacks(sortedPacks.filter(pack => pack.category.includes('for_creator')));
        setForBusinessPacks(sortedPacks.filter(pack => pack.category.includes('for_business')));
        setCreateWithAIPacks(sortedPacks.filter(pack => pack.category.includes('create_with_ai')));

        if (pack && sortedPacks.some(sortedPack => sortedPack.id === pack)) {
          const matchingPack = sortedPacks.find(sortedPack => sortedPack.id === pack);
          setPhotoPipeLine(matchingPack);
          setShowPhotoPipeLine(true);
        } else if (pack === 'ai_headshots') {
          setIsOpenAIHeadshotsDialog(true);
        } else if (pack === 'ai_influencer') {
          setIsOpenAIInfluencersDialog(true);
        }
      })
      .finally(() => {
        setIsLoadingData(false);
      });
  }, [pack]);

  useEffect(() => {
    if (window.innerWidth <= 980) {
      setIsMobile(true);
      setIsActiveDeskMenu(false);
    }

    const handleResize = () => {
      if (window.innerWidth <= 980) {
        setIsMobile(true);
        setIsActiveDeskMenu(false);
      } else {
        setIsMobile(false);
        setIsActiveDeskMenu(true);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const hideMenuBar = () => {
    setIsActiveDeskMenu(!isActiveDeskMenu);
    onHideMenuBar(!isActiveDeskMenu);
  };

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const TabLabel = ({ icon, label }) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      {icon}
      <span>{label}</span>
    </div>
  );

  const openPhotoPipeLine = (e, item) => {
    e.preventDefault();
    setPhotoPipeLine(item);
    setShowPhotoPipeLine(true);
  };

  const handleSelectCollection = (collection) => {
    setPhotoPipeLine(collection);
    setShowPhotoPipeLine(true);
  };

  const closePhotoPipeLine = () => {
    setShowPhotoPipeLine(false);
  };

  const openAITools = (e, item) => {
    e.preventDefault();

    if (item.id === 'magic_eraser') {
      onRemoveObject();
    } else if (item.id === 'magic_upscaler') {
      onActiveUpgradeTo4K();
    }
  };

  const handleOpenCreatorDialog = () => {
    setIsOpenAIHeadshotsDialog(true);
  };

  const openStudio = () => {
    setIsOpenAIInfluencersDialog(false);
    handleOpenStudioDialog();
  };

  const aiTools = [
    {
      title: 'AI Headshot',
      description: 'Professional AI-powered headshot photos in minutes',
      icon: icons.AIHeadshots,
      color: '#fc5185',
      onClick: handleOpenCreatorDialog,
    },
    {
      title: 'AI Influencers',
      description: 'Generate unique virtual influencers for your ads, social medias, and more',
      icon: icons.AIInfluencers,
      color: '#4ECDC4',
      onClick: () => {
        setIsOpenAIInfluencersDialog(true);
      },
    },
    {
      title: 'AI Shorts (Coming Soon)',
      description: 'Transform photos into engaging video content',
      icon: icons.AIShorts,
      color: '#FFD93D',
      onClick: () => {
        /* Handle AI Videos click */
      },
    },
  ];

  return (
    <>
      <OpenIconSpeedDial />
      <div
        className={`home-area ${darkMode ? 'dark-bg' : 'light-bg'}`}
        style={{
          transition: 'all 0.35s ease',
          left: !isActiveDeskMenu ? '0' : '250px',
          width: !isActiveDeskMenu ? '100%' : 'calc(100% - 250px)',
        }}
      >
        <div className="home-page-container mx-auto" style={{ paddingBottom: isMobile ? '54px' : '0' }}>
          {!isMobile ? (
            <>
              <div className="flex align-items-center mb-4">
                <span
                  style={{ color: '#888888' }}
                  className={`${darkMode ? 'cursor-pointer-dark' : 'cursor-pointer-light'}`}
                  onClick={hideMenuBar}
                >
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="bi bi-layout-sidebar-inset"
                  >
                    <path d="M21 3a1.5 1.5 0 0 1 1.5 1.5v15a1.5 1.5 0 0 1 -1.5 1.5H3a1.5 1.5 0 0 1 -1.5 -1.5V4.5a1.5 1.5 0 0 1 1.5 -1.5zM3 1.5a3 3 0 0 0 -3 3v15a3 3 0 0 0 3 3h18a3 3 0 0 0 3 -3V4.5a3 3 0 0 0 -3 -3z" />
                    <path d="M4.5 6a1.5 1.5 0 0 1 1.5 -1.5h3a1.5 1.5 0 0 1 1.5 1.5v12a1.5 1.5 0 0 1 -1.5 1.5H6a1.5 1.5 0 0 1 -1.5 -1.5z" />
                  </svg>
                </span>
                <h1
                  className={`mb-0 pl-4 font-bold ${darkMode ? 'text-white' : 'text-black'}`}
                  style={{ fontSize: '19px', fontWeight: '600' }}
                >
                  Explore AI Studio
                </h1>
                {/* <h3 className={`mb-0 pl-4 ${darkMode ? 'text-white' : 'text-black'}`}>{t('app.menu.studioMode')}</h3> */}
              </div>
            </>
          ) : (
            <div className="flex align-items-center mt-7">
              <h1 className={`font-bold mb-2 ${darkMode ? 'text-white' : 'text-black'}`} style={{ fontSize: '19px', fontWeight: '600' }}>
                Explore AI Studio
              </h1>
            </div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5 mt-5">
            {aiTools.map((tool, index) => (
              <StyledCard
                key={index}
                darkMode={darkMode} 
                onClick={tool.onClick}
                sx={{
                  position: 'relative',
                  overflow: 'hidden',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '4px',
                    background: 'linear-gradient(115deg, #4fcf70, #fad648, #a767e5, #12bcfe, #44ce7b);',
                    opacity: 0,
                    transition: 'opacity 0.3s ease',
                  },
                  '&:hover::before': {
                    opacity: 1,
                  },
                }}
              >
                <CardContent sx={{ padding: '20px' }}>
                  <div className="flex items-center space-x-5">
                    <div
                      style={{
                        background: `linear-gradient(135deg, ${tool.color}, ${tool.color}CC)`,
                        padding: '16px',
                        borderRadius: '16px',
                        color: 'white',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                        transition: 'transform 0.3s ease',
                        marginRight: '16px',
                      }}
                    >
                      {tool.icon}
                    </div>
                    <div className="flex-1">
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          color: darkMode ? 'white' : 'black',
                          fontWeight: '700',
                          fontSize: '16px',
                          marginBottom: '4px',
                          letterSpacing: '-0.01em',
                        }}
                      >
                        {tool.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: darkMode ? '#888888' : '#666666',
                          fontSize: '0.9rem',
                          lineHeight: 1.5,
                        }}
                      >
                        {tool.description}
                      </Typography>
                    </div>
                  </div>
                </CardContent>
              </StyledCard>
            ))}
          </div>
          <div className="space-x-4 mb-4">
            <Box sx={{ borderBottom: 1, borderColor: darkMode ? '#373737' : '#f1f3f4' }}>
              <StyledTabs darkMode={darkMode} value={activeTab} onChange={handleChangeTab} aria-label="main tab" variant="scrollable">
                {categories.map((category, index) => (
                  <StyledTab key={index} darkMode={darkMode} label={<TabLabel icon={category.icon} label={category.name} />} />
                ))}
              </StyledTabs>
            </Box>
            <CustomTabPanel value={activeTab} index={0}>
              <div className={`${darkMode ? 'text-white' : 'text-black'}`}>
                <p className={`mt-5 mb-2 bold`}>For headshot</p>
                <Box sx={{ pt: 1 }}>
                  <Grid container spacing={2}>
                    {isLoading
                      ? Array.from(new Array(5)).map((_, index) => (
                          <Grid item xs={6} sm={4} md={3} lg={2.4} key={index}>
                            <PackSkeleton darkMode={darkMode} />
                          </Grid>
                        ))
                      : trendingPacks.slice(0, 5).map((item, key) => (
                          <Grid item xs={6} sm={4} md={3} lg={2.4} key={key}>
                            <PackItem item={item} onClick={e => openPhotoPipeLine(e, item)} darkMode={darkMode} />
                          </Grid>
                        ))}
                  </Grid>
                </Box>

                <p className={`mt-6 mb-2 bold`}>For creator</p>
                <Box sx={{ pt: 1 }}>
                  <Grid container spacing={2}>
                    {isLoading
                      ? Array.from(new Array(5)).map((_, index) => (
                          <Grid item xs={6} sm={4} md={3} lg={2.4} key={index}>
                            <PackSkeleton darkMode={darkMode} />
                          </Grid>
                        ))
                      : forCreatorPacks.slice(0, 5).map((item, key) => (
                          <Grid item xs={6} sm={4} md={3} lg={2.4} key={key}>
                            <PackItem item={item} onClick={e => openPhotoPipeLine(e, item)} darkMode={darkMode} />
                          </Grid>
                        ))}
                  </Grid>
                </Box>

                <p className={`mt-6 mb-2 bold`}>For business</p>
                <Box sx={{ pt: 1 }}>
                  <Grid container spacing={2}>
                    {isLoading
                      ? Array.from(new Array(5)).map((_, index) => (
                          <Grid item xs={6} sm={4} md={3} lg={2.4} key={index}>
                            <PackSkeleton darkMode={darkMode} />
                          </Grid>
                        ))
                      : forBusinessPacks.slice(0, 5).map((item, key) => (
                          <Grid item xs={6} sm={4} md={3} lg={2.4} key={key}>
                            <PackItem item={item} onClick={e => openPhotoPipeLine(e, item)} darkMode={darkMode} />
                          </Grid>
                        ))}
                  </Grid>
                </Box>

                <p className={`mt-6 mb-2 bold`}>Magic AI tools</p>
                <Box sx={{ pt: 1 }}>
                  <Grid container spacing={2}>
                    {isLoading
                      ? Array.from(new Array(5)).map((_, index) => (
                          <Grid item xs={6} sm={4} md={3} lg={3} key={index}>
                            <PackSkeleton darkMode={darkMode} />
                          </Grid>
                        ))
                      : magicAITools.slice(0, 5).map((item, key) => (
                          <Grid item xs={6} sm={4} md={3} lg={3} key={key}>
                            <PackItem item={item} onClick={e => openAITools(e, item)} darkMode={darkMode} />
                          </Grid>
                        ))}
                  </Grid>
                </Box>
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={1}>
              <div className={`${darkMode ? 'text-white' : 'text-black'}`}>
                <p className={`mt-5 mb-2 bold`}>For headshot</p>
                <Box sx={{ pt: 1 }}>
                  <Grid container spacing={2}>
                    {isLoading
                      ? Array.from(new Array(5)).map((_, index) => (
                          <Grid item xs={6} sm={4} md={3} lg={2.4} key={index}>
                            <PackSkeleton darkMode={darkMode} />
                          </Grid>
                        ))
                      : trendingPacks.slice(0, 5).map((item, key) => (
                          <Grid item xs={6} sm={4} md={3} lg={2.4} key={key}>
                            <PackItem item={item} onClick={e => openPhotoPipeLine(e, item)} darkMode={darkMode} />
                          </Grid>
                        ))}
                  </Grid>
                </Box>
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={2}>
              <div className={`${darkMode ? 'text-white' : 'text-black'}`}>
                <p className={`mt-5 mb-2 bold`}>For creator</p>
                <Box sx={{ pt: 1 }}>
                  <Grid container spacing={2}>
                    {isLoading
                      ? Array.from(new Array(5)).map((_, index) => (
                          <Grid item xs={6} sm={4} md={3} lg={2.4} key={index}>
                            <PackSkeleton darkMode={darkMode} />
                          </Grid>
                        ))
                      : forCreatorPacks.slice(0, 5).map((item, key) => (
                          <Grid item xs={6} sm={4} md={3} lg={2.4} key={key}>
                            <PackItem item={item} onClick={e => openPhotoPipeLine(e, item)} darkMode={darkMode} />
                          </Grid>
                        ))}
                  </Grid>
                </Box>
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={3}>
              <div className={`${darkMode ? 'text-white' : 'text-black'}`}>
                <p className={`mt-5 mb-2 bold`}>For business</p>
                <Box sx={{ pt: 1 }}>
                  <Grid container spacing={2}>
                    {isLoading
                      ? Array.from(new Array(5)).map((_, index) => (
                          <Grid item xs={6} sm={4} md={3} lg={2.4} key={index}>
                            <PackSkeleton darkMode={darkMode} />
                          </Grid>
                        ))
                      : forBusinessPacks.slice(0, 5).map((item, key) => (
                          <Grid item xs={6} sm={4} md={3} lg={2.4} key={key}>
                            <PackItem item={item} onClick={e => openPhotoPipeLine(e, item)} darkMode={darkMode} />
                          </Grid>
                        ))}
                  </Grid>
                </Box>
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={4}>
              <div className={`${darkMode ? 'text-white' : 'text-black'}`}>
                <p className={`mt-5 mb-2 bold`}>Magic AI tools</p>
                <Box sx={{ pt: 1 }}>
                  <Grid container spacing={2}>
                    {isLoading
                      ? Array.from(new Array(5)).map((_, index) => (
                          <Grid item xs={6} sm={4} md={3} lg={3} key={index}>
                            <PackSkeleton darkMode={darkMode} />
                          </Grid>
                        ))
                      : magicAITools.slice(0, 5).map((item, key) => (
                          <Grid item xs={6} sm={4} md={3} lg={3} key={key}>
                            <PackItem item={item} onClick={e => openAITools(e, item)} darkMode={darkMode} />
                          </Grid>
                        ))}
                  </Grid>
                </Box>
              </div>
            </CustomTabPanel>
          </div>
        </div>
      </div>
      {showPhotoPipeLine && (
        <PhotoPipeLine
          onClose={closePhotoPipeLine}
          isMobile={isMobile}
          firebase={firebase}
          setUser={setUser}
          darkMode={darkMode}
          userData={userData}
          t={t}
          photoPack={photoPipeLine}
        />
      )}
      <AIHeadshotsDialog
        t={t}
        userData={userData}
        open={isOpenAIHeadshotsDialog}
        onClose={() => setIsOpenAIHeadshotsDialog(false)}
        darkMode={darkMode}
        isMobile={isMobile}
        firebase={firebase}
        handleSelectCollection={handleSelectCollection}
      />
      <AIInfluencersDialog
        t={t}
        userData={userData}
        open={isOpenAIInfluencersDialog}
        onClose={() => setIsOpenAIInfluencersDialog(false)}
        darkMode={darkMode}
        isMobile={isMobile}
        firebase={firebase}
        openStudio={openStudio}
      />
    </>
  );
};

export default HomePage;
