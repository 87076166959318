import React from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer';
import AdminHeaderContainer from '../containers/AdminHeaderContainer';

const AnyRoute = ({ user }) =>
  <>
    <AdminHeaderContainer userData={user}/>
      <div style={{ 
        paddingTop: "calc(40px)"
      }}>
        <Outlet userData={user}/>
      </div>
    <Footer/>
  </>

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(AnyRoute);
