import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import LovedBy from '../LovedBy';
import ShowCaseSlide from '../ShowCaseSlide';

const RECAPTCHA = process.env.REACT_APP_RECAPTCHA;

const exampleImages = [
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-qxa2wahnmdrm00cjcsjaze7w74_0.jpg?generation=1728294730574490&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-w2g8jj70adrm00cjcszbnf3htw_1.jpg?generation=1728296474732532&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-pyejnyrg7hrm00cjctgam6w5pr_0.jpg?generation=1728298688259903&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-09ddcdadahrj60cjctr86dwtg8.jpg?generation=1728299705253085&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-h3j6s2wpg1rm40cjd78a2m3sp4_3.jpg?generation=1728352207836995&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-eaqg2dm3nnrm40cjct1st5jsdc_0.jpg?generation=1728296791938242&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-e0e9jm7z0hrm40cjcsk8zztna8_3.jpg?generation=1728294909433701&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-px1ehxf315rm60cjd7yts0xa8r_1.jpg?generation=1728355131870361&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-1mqxsyd3fnrm00cjd7xt8vd1vw_0.jpg?generation=1728354980776315&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-gzv4hme1xnrm60cjct2teyjpgc_2.jpg?generation=1728296922784973&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-ste4b40rknrj40cj5k9a7b82e4_0.jpg?generation=1727328926781706&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-xaq6n9q6yhrj60cj5jtasd02ew_0.jpg?generation=1727327032871385&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-dk2fbbf1mhrj40cj5jqbthx698_3.jpg?generation=1727326617126822&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-dhae0pczgdrj00cj5jms31s0kg_0.jpg?generation=1727326522674325&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-tg3x5vwt0xrj60cj19p87athd8_3.jpg?generation=1726751892927251&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-hwprbkk0rdrm60cjd89bpd5w98_3.jpg?generation=1728356471190760&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-mhwxj2k7bnrm00cjd868kypjw8_3.jpg?generation=1728356076677359&alt=media',
  'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-m0baj6fdj5rj60cj1sxbq1pn2g_2.jpg?generation=1726819889792292&alt=media',
];

function SignUpEmailAndGoogle({ onShowLogin, handleSubmitWithEmailLink, error, handleSubmitWithGoogle, isSubmitted, onSubmitted }) {
  const [currentLanguage, setCurrentLanguage] = useState('');
  const popupRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (popupRef.current && event.target.contains(popupRef.current)) {
        onShowLogin(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
  }, []);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const onSubmitWithEmailLink = (e) => {
    e.preventDefault();
    if (loading) return;

    if (!email) {
      setEmailError(t('message.error.emptyEmail'));
      return;
    }

    if (!validateEmail(email)) {
      setEmailError(t('message.error.validEmail'));
      return;
    }

    setLoading(true);
    handleSubmitWithEmailLink(e, t, currentLanguage, email);
    // onSubmitted(true);
  };

  return (
    <div className="sign_in-wrapper">
      {/* <ShowCaseSlide /> */}
      <div>
        <div className="w-full rnftg-h-full rnftg-w-full">
          <div className="rnftg-h-full dark:rnftg-bg-gray-900">
            <div className="rnftg-flex rnftg-flex-col pd-2">
              <div className="rnftg-grid rnftg-gap-custom rnftg-grid-flow-row rnftg-grid-cols-3 md:rnftg-grid-cols-3 lg:rnftg-grid-cols-6 xl:rnftg-grid-cols-6">
                {exampleImages.map((url, index) => (
                  <article key={index} className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                    <div className="rnftg-item__img-wrapper_landing">
                      <div className="landing-result">
                        <img
                          className="banner-video"
                          alt="reok"
                          src={url}
                        ></img>
                      </div>
                    </div>
                  </article>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pricing-showbox inset-0 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0">
        <div className="sign_in-container">
          <div className="sign_in-logo">
            <Link to={currentLanguage === 'en' ? '/' : `/${currentLanguage}/`}>
              {/* <img src="images/airoomdecor-full.webp" /> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="140" viewBox="0 0 3600 1015.5" height="44" version="1.0">
                <defs>
                  <clipPath id="a">
                    <path d="M.887 0h1015v1015H.887Zm0 0" />
                  </clipPath>
                </defs>
                <path d="M1703.836 361.342c0-49.758-40.71-90.469-90.468-90.469h-361.875v452.344h90.469V542.279h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469l-90.469-90.469h90.47c49.757 0 90.468-40.71 90.468-90.468Zm-90.468 0v90.469h-271.406v-90.469Zm677.386-90.469h-361.873c-49.758 0-90.469 40.711-90.469 90.469v271.406c-.001 49.759 40.71 90.469 90.468 90.469h361.874v-90.469H1928.88v-90.469h361.874v-90.468H1928.88v-90.469h361.874Zm496.45 452.344c50.324 0 90.469-41.277 90.469-90.469V361.342c0-49.758-40.711-90.469-90.469-90.469h-271.406c-49.758 0-90.469 40.711-90.469 90.469v271.406c0 49.759 40.711 90.469 90.469 90.469Zm-271.406-361.875h271.406v271.406h-271.406Zm948.792-90.469h-108.561c-12.437 0-23.746 4.524-32.793 13.57l-167.368 167.369h-53.152V270.874h-90.469v452.343h90.469V542.279h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469L3238.42 497.045l135.703-135.703h90.469Zm0 0" fill="#f0f" />
                <path d="M1772.704 377.242c0-49.758-40.71-90.469-90.468-90.469h-361.875v452.344h90.469V558.179h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469l-90.469-90.469h90.47c49.757 0 90.468-40.71 90.468-90.468Zm-90.468 0v90.469H1410.83v-90.469Zm677.386-90.469h-361.873c-49.758 0-90.469 40.711-90.469 90.469v271.406c-.001 49.759 40.71 90.469 90.468 90.469h361.874v-90.469h-361.874v-90.469h361.874v-90.468h-361.874v-90.469h361.874Zm496.45 452.344c50.324 0 90.469-41.277 90.469-90.469V377.242c0-49.758-40.711-90.469-90.469-90.469h-271.406c-49.758 0-90.469 40.711-90.469 90.469v271.406c0 49.759 40.711 90.469 90.469 90.469Zm-271.406-361.875h271.406v271.406h-271.406Zm948.792-90.469h-108.561c-12.437 0-23.746 4.524-32.793 13.57l-167.368 167.369h-53.152V286.774h-90.469v452.343h90.469V558.179h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469l-135.702-135.703 135.703-135.703h90.469Zm0 0" fill="#0ff" />
                <path d="M1738.27 369.292c0-49.758-40.71-90.469-90.468-90.469h-361.875v452.344h90.469V550.229h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469l-90.469-90.469h90.47c49.757 0 90.468-40.71 90.468-90.468Zm-90.468 0v90.469h-271.406v-90.469Zm677.386-90.469h-361.873c-49.758 0-90.469 40.711-90.469 90.469v271.406c-.001 49.759 40.71 90.469 90.468 90.469h361.874v-90.469h-361.874v-90.469h361.874v-90.468h-361.874v-90.469h361.874Zm496.45 452.344c50.324 0 90.469-41.277 90.469-90.469V369.292c0-49.758-40.711-90.469-90.469-90.469h-271.406c-49.758 0-90.469 40.711-90.469 90.469v271.406c0 49.759 40.711 90.469 90.469 90.469Zm-271.406-361.875h271.406v271.406h-271.406Zm948.792-90.469h-108.561c-12.437 0-23.746 4.524-32.793 13.57l-167.368 167.369h-53.152V278.824h-90.469v452.343h90.469V550.229h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469l-135.702-135.703 135.703-135.703h90.469Zm0 0" fill="#fff" />
                <g transform="translate(3576.56 723.217)" fill="#f0f" />
                <g clipPath="url(#a)">
                  <path fill="#fff" d="M1015.617 507.277c0 280.254-227.199 507.453-507.453 507.453C227.906 1014.73.707 787.531.707 507.277.727 227.023 227.907-.18 508.164-.18c280.254.02 507.453 227.203 507.453 507.457" />
                </g>
                <path fill="#fff" d="M956.121 507.277c0 247.375-200.582 447.961-447.957 447.961-247.379 0-447.96-200.586-447.96-447.96 0-247.423 200.581-447.962 447.96-447.962 247.375 0 447.957 200.54 447.957 447.961" />
                <path d="m370.875 466.746 230.871-397.59c-30.195-6.441-61.465-9.84-93.582-9.84-115.86 0-221.387 43.973-300.922 116.13l163.633 291.3M179.371 203.09C105.43 282.984 60.203 389.852 60.203 507.277c0 33.414 3.711 65.98 10.645 97.293l332.816-.222L179.371 203.09M642.363 79.758 482.305 342.21h442.414C875.246 217.449 771.375 120.199 642.363 79.758M619.95 381.531l230.51 414.68c65.895-78.016 105.661-178.844 105.661-288.934 0-41.289-5.637-81.238-16.058-119.214-189.043-.313-320.114-6.532-320.114-6.532M508.164 955.238c121.047 0 230.91-48.047 311.566-126.105l-160.457-290.32s-141.582 246.93-216.691 411.636a452.445 452.445 0 0 0 65.582 4.79m-110.269-13.692 163.054-291.3-480.617-9.755c46.031 147.844 166.496 262.809 317.563 301.055" />
              </svg>
            </Link>
          </div>
          <div ref={popupRef} className="dark-login-placeholder shadow-lg">
            <div className="login-form-container pt-2 pb-4">
              <div>
                <p className="text-gray-400 text-center mt-4">
                  {t('signup.title')}
                </p>
                <a href="#" className="px-3 py-3 link-block-6 w-inline-block signup-button" onClick={(e) => handleSubmitWithGoogle(e, t)}>
                  <div>
                    <img src="fonts/6373e8bb67056287b1380aa8_image%206.svg" loading="lazy" alt="" className="signup-button" />
                  </div>
                  <p className="body-1-semi-bold ml-12 signup-button" style={{ color: "#fff" }}>{t('button.googleSignup')}</p>
                </a>
              </div>
              <div className='mt-2' />
              <div className="sign-with-box mt-2">
                <div className="sign-with-divider" />
                <p className='pl-2 pr-2'>{t('signin.another')}</p>
                <div className="sign-with-divider" />
              </div>
              {isSubmitted ? (
                <div className="flex flex-col items-center justify-center mb-2">
                  <hr className="w-full border-t border-gray-700 mb-2" />
                  <div className="flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    <span className="text-white font-semibold">&nbsp;{t('giveAway.emailSent')}</span>
                  </div>
                  <h2 className="text-center text-2xl font-bold">
                    <div className="mx-auto relative z-[10] text-center mx-auto md:text-center label-giveaway">
                      <span>{t('giveAway.emailSentTitle')}</span>&nbsp;🎉
                    </div>
                  </h2>
                  <p className="text-gray-400 text-center">
                    {t('giveAway.emailSentSub')}
                  </p>
                </div>
              ) : (
                <>
                  <div className='mt-2'>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={t('giveAway.enterYourEmail')}
                      className="w-full px-4 py-4 rounded-md border border-gray-700 mb-2 focus:outline-none text-white placeholder-gray-500" style={{ backgroundColor: '#151515' }}
                    />
                    {emailError && <p className="text-red text-sm mb-2">{emailError}</p>}
                  </div>
                  <button
                    onClick={(e) => onSubmitWithEmailLink(e)}
                    className="w-full bg-pink-500 text-white py-4 text-sm rounded-md hover:bg-yellow-400 hover:scale-105 transition duration-300 font-semibold"
                  >
                    {loading ? t('giveAway.submitting') : t('button.emailSignup')}
                  </button>
                  <div className="forget-passwrod-form w-form">
                    <form id="email-form" name="email-form" data-name="Email Form">
                      <div className="_2nd-form" style={{ color: "#fff" }}>
                        <p className="agree-service" style={{ color: "#c2c2c2" }}>
                          {t('tos.agree')}
                          <a
                            className="agree-service" style={{ color: "#c2c2c2" }}
                            href={currentLanguage === 'en' ? '/legal/?view=terms' : `/${currentLanguage}/legal/?view=terms`}
                            target="_blank"
                          >
                            {t('footer.terms')}
                          </a>
                          {' '}&{' '}
                          <a
                            className="agree-service" style={{ color: "#c2c2c2" }}
                            href={currentLanguage === 'en' ? '/legal/?view=privacy-policy' : `/${currentLanguage}/legal/?view=privacy-policy`}
                            target="_blank"
                          >
                            {t('footer.privacy')}
                          </a>
                        </p>
                        <LovedBy t={t} />
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SignUpEmailAndGoogle.propTypes = {
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.object,
};

export default SignUpEmailAndGoogle;
