import React, { useEffect, useState, memo } from 'react';
import LoaderLineMask from '../LoaderLineMask';
import ImageResult from '../ImageResult';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import NewSkeleton from '../NewSkeleton';
import ExportPhoto from '../ExportPhoto';
import CameraIcon from '@mui/icons-material/Camera';

const icons = {
  hotStyle: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-flame"><path d="M8.5 14.5A2.5 2.5 0 0 0 11 12c0-1.38-.5-2-1-3-1.072-2.143-.224-4.054 2-6 .5 2.5 2 4.9 4 6.5 2 1.6 3 3.5 3 5.5a7 7 0 1 1-14 0c0-1.153.433-2.294 1-3a2.5 2.5 0 0 0 2.5 2.5z" /></svg>,
  result: <CameraIcon/>,
};

const categories = [
  { name: 'Hot styles', icon: icons.hotStyle },
  { name: 'Your results', icon: icons.result },
];

const StyledTabs = styled(({ darkMode, ...props }) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ darkMode }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    width: "100%",
  },
  '& .MuiTabs-indicatorSpan': {
    width: '80%',
    // backgroundColor: "#fb5183",
    backgroundImage: "linear-gradient(115deg, #4fcf70, #fad648, #a767e5, #12bcfe, #44ce7b)"
  },
}));

const StyledTab = styled(({ darkMode, ...props }) => (
  <Tab disableRipple {...props} />
))(({ darkMode }) => ({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: 16,
  fontFamily: 'inherit',
  letterSpacing: "-0.01em",
  backgroundColor: 'transparent',
  color: darkMode ? '#5d5d5d' : '#15293c80',
  '&.Mui-selected': {
    color: darkMode ? 'white' : 'black',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: "-0.01em"
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "16px" }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const StudioShowCase = memo(({ userData, currentJob, t, firebase, onGenFill, onFetchUser, checkCredits, onUpImageResolution, onRemoveBG, progress, handleSelectCollection, darkMode, onMagicFixer, isMobile, mode }) => {
  const [jobsList, setJobsList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [imageSrcCompare, setImageSrcCompare] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [showShopFurniture, setShowShopFurniture] = useState(false);
  const [imageShop, setImageShop] = useState(null);
  const [imageSrcEdit, setImageSrcEdit] = useState();
  const [showEditImage, setShowEditImage] = useState(false);
  const [searching, setSearching] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isFaceNotFound, setIsFaceNotFound] = useState(false);
  const [value, setValue] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showExportPhoto, setShowExportPhoto] = useState(false);
  const [hotPacks, setHotPacks] = useState([]);

  useEffect(() => {
    firebase.getPhotoPacksOnLanding().then(querySnapshot => {
      const sortedPacks = querySnapshot.docs.map(doc => doc.data()).sort((a, b) => {
        return (b.isHot ? 1 : 0) - (a.isHot ? 1 : 0); // Sort by isHot
      });
      setHotPacks(sortedPacks);
    });
  }, []);

  useEffect(() => {
    // Check job status then add to the list
    if (currentJob !== undefined && currentJob !== null) {
      if (currentJob.status === "starting") {
        setIsCreating(true);
        setIsFaceNotFound(false);
        setValue(1);

        // try {
        //   var reader = new FileReader();
        //   reader.onload = function(event) {
        //     var dataURL = event.target.result;
        //     document.getElementById('img_src_loader').src = dataURL;
        //   };
        //   reader.readAsDataURL(currentJob.images.before);
        // } catch (error) {
        //   setIsCreating(false);
        //   console.log(error);
        // }
      }
      else if (currentJob.status === "finished") {
        const mergedJobsList = [...[currentJob], ...jobsList];
        setValue(1);
        setIsCreating(false);
        setJobsList(mergedJobsList);
      }
      else if (currentJob.status === "failed-face") {
        setValue(1);
        setIsFaceNotFound(true);
      }
    }
  }, [currentJob]);

  // useEffect(() => {
  //   setCurrentProgress(progress);
  // }, [progress]);

  function generateRandomFileName(extension) {
    const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
    return `reok-${randomNumber}.${extension}`;
  }

  const onCompareImage = (images) => {
    setImageSrcCompare(images);
    setShowExportPhoto(true);
  }

  const onFindAgency = (image) => {
    setOpenForm(true);
    setImageShop(image);
    setShowShopFurniture(false);
  }

  const onEditImage = (imageUrl) => {

    if (checkCredits()) {
      setShowEditImage(false);
      return;
    }

    setImageSrcEdit(imageUrl);
    setShowEditImage(true);
  }

  const onCloseImageCompare = () => {
    setShowExportPhoto(false);
  }

  const onDownloadImage = (image) => {
    setIsDownloading(true);

    try {
      setTimeout(async () => {
        const response = await fetch(image);
        const blob = await response.blob();

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        const fileName = generateRandomFileName('jpeg');
        link.download = fileName;
        link.click();

        // Clean up the temporary URL
        URL.revokeObjectURL(url);
        setIsDownloading(false);

      }, 2000);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  }

  const onRemoveFromList = (id) => {
    const updatedJobList = jobsList.filter(job => job.id !== id);
    setJobsList(updatedJobList);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function onSelectCollection(e, collection) {
    e.preventDefault();
    handleSelectCollection(collection);
  }

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const openPhotoPipeLine = (e, item) => {
    handleSelectCollection(item);
  }

  const TabLabel = ({ icon, label }) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      {icon}
      <span>{label}</span>
    </div>
  );

  return (
    <div
      className={`results-area ${darkMode ? 'dark-bg' : ''}`}
      style={{ paddingBottom: isMobile ? '54px' : '0' }}
    >
      <Box sx={{ borderBottom: 1, borderColor: darkMode ? '#373737' : '#f1f3f4' }}>
        <StyledTabs
          darkMode={darkMode}
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
          variant="scrollable"
          scrollButtons={false}
        >
          {categories.map((category, index) => (
            <StyledTab
              key={index}
              darkMode={darkMode}
              label={<TabLabel icon={category.icon} label={category.name} />}
            />
          ))}
        </StyledTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className={`${darkMode ? 'text-white' : 'text-black'}`}>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 gap-4">
            {hotPacks.map((item, key) => (
              <div key={key} onClick={(e) => openPhotoPipeLine(e, item)} className={`rounded-lg shadow-md overflow-hidden transition-colors duration-200 w-full ${darkMode ? 'dark-bg-home' : ''} } home-item`}>
                <div className="relative aspect-w-16 aspect-h-9">
                  <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
                  {item.isHot && (
                    <div className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full" style={{ marginTop: "-12px" }}>
                      Hot
                    </div>
                  )}
                </div>
                <div className="p-4">
                  <p className="text-sm font-bold mb-1">{item.title}</p>
                  <p className="font-size-sm text-gray-600 dark:text-gray-300">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div className="rnftg-h-full rnftg-w-full" id="my-result">
          <div className="rnftg-h-full dark:rnftg-bg-gray-900">
            <div className="rnftg-flex rnftg-flex-col pd-2">
              <div className="rnftg-grid rnftg-gap-6 rnftg-grid-flow-row rnftg-grid-cols-1 md:rnftg-grid-cols-2 lg:rnftg-grid-cols-2 xl:rnftg-grid-cols-2">
                {isCreating &&
                  <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                    <div className="rnftg-item__img-wrapper">
                      {!isFaceNotFound ?
                        (
                          <div className="result">
                            <LoaderLineMask status={progress} />
                            <span className="rnftg-no-underline rnftg-text-black dark:rnftg-text-gray-200">
                              {!imageLoaded && (
                                <NewSkeleton />
                              )
                              }
                              {currentJob.images.before &&
                                <img
                                  className={`rnftg-w-full rnftg-h-full rnftg-object-cover rnftg-cursor-pointer rnftg-rounded-t-2xl ${imageLoaded ? 'rendering-mask' : ''}`}
                                  alt="reok"
                                  src={currentJob.images.before}
                                  onLoad={handleImageLoad}
                                />
                              }
                              <div className="img_src_loader"></div>
                              <div className="prompt-label">{t('message.prompt.rendering') + " - " + currentJob.result + " - " + currentJob.prompt}</div>
                            </span>
                          </div>
                        ) : (
                          <div className="result">
                            <div className="line-mask">
                              <div className="circular-progress">
                                <svg width="20px" height="20px" viewBox="0 0 0.5 0.5" xmlns="http://www.w3.org/2000/svg">
                                  <path fill="#d32f2f" d="M0.49 0.397 0.3 0.048C0.288 0.025 0.269 0.013 0.25 0.013c-0.02 0 -0.038 0.013 -0.05 0.036L0.01 0.397c-0.012 0.023 -0.014 0.046 -0.003 0.063 0.01 0.018 0.03 0.028 0.055 0.028h0.376c0.025 0 0.045 -0.01 0.056 -0.028 0.01 -0.018 0.009 -0.04 -0.004 -0.063ZM0.25 0.121c0.01 0 0.018 0.008 0.018 0.018v0.165c0 0.01 -0.008 0.018 -0.018 0.018a0.018 0.018 0 0 1 -0.018 -0.018V0.14c0 -0.01 0.008 -0.018 0.018 -0.018Zm0 0.291c-0.015 0 -0.028 -0.013 -0.028 -0.028 0 -0.016 0.013 -0.029 0.028 -0.029 0.015 0 0.028 0.013 0.028 0.028 0 0.016 -0.013 0.029 -0.028 0.029Z" />
                                </svg>
                              </div>
                            </div>
                            <span className="rnftg-no-underline rnftg-text-black dark:rnftg-text-gray-200">
                              <img
                                className="rnftg-w-full rnftg-h-full rnftg-object-cover rnftg-cursor-pointer rnftg-rounded-t-2xl rendering-mask"
                                id="img_src_loader"
                                loading="lazy"
                                src={currentJob.images.before}
                              />
                              <div className="img_src_loader"></div>
                              <div className="prompt-label">{t('message.prompt.faceNotFound')}</div>
                            </span>
                          </div>
                        )
                      }
                    </div>
                  </article>
                }
                {jobsList.length > 0 &&
                  jobsList.map((job, key) => {
                    job.originalUrl = job.images.before;
                    job.upscaleUrl = job.images.after;

                    return (
                      <ImageResult 
                        t={t} 
                        firebase={firebase} 
                        userData={userData} 
                        key={key} 
                        job={job} 
                        onCompareImage={onCompareImage} 
                        onDownloadImage={onDownloadImage} 
                        onFindAgency={onFindAgency} 
                        onEditImage={onEditImage} 
                        onRemoveBG={onRemoveBG} 
                        isDownloading={isDownloading} 
                        onUpImageResolution={onUpImageResolution} 
                        onRemoveFromList={onRemoveFromList} 
                        hideRenew={true} 
                        darkMode={darkMode} 
                        onMagicFixer={onMagicFixer} 
                        mode={job.mode} 
                      />
                    )
                  }
                  )}
              </div>
            </div>
          </div>
        </div>
      </CustomTabPanel>
      {showExportPhoto && <ExportPhoto onClose={onCloseImageCompare} images={imageSrcCompare} isMobile={isMobile}></ExportPhoto>}
    </div>
  );
});

export default StudioShowCase;
