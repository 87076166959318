import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import MoneyBackGuarantee from '../MoneyBackGuarantee';
import Header from '../CompareImage/Header';
import { Snackbar } from '@mui/material';

const HeaderCoupon = () => {
  const [currentLanguage, setCurrentLanguage] = useState('');
  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(true)
    navigator.clipboard.writeText("REOK10")
  }

  const [isClosedCoupon, setIsClosedCoupon] = useState(false);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const onClose = () => {
    setIsClosedCoupon(true);
  }

  return (
    <>
      {!isClosedCoupon &&
        <div className="flex justify-content-center align-items-center header-coupon">
          <div className="text-white" style={{zIndex: 999, cursor: "pointer"}}>
            <div className='px-4 py-2'>
              <span>
              🎉 New! We've upgraded our photo quality ✨
              </span>
              <span className="coupon-card">
              Enjoy your 50% OFF
              </span>
              <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={1500}
                message="Copied REOK10"
              />
              {/* <span style={{marginLeft: "4px"}}>
                <MoneyBackGuarantee/>
              </span> */}
            </div>
          </div>
          <Header onClose={onClose} enableRotate={false} />
        </div>
      }
    </>
  );
};

export default HeaderCoupon;
