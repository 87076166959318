import React, { useRef, useEffect, useState } from 'react';
import { ArrowDownToLine, LoadingOutlined } from 'lucide-react';
import Header from '../CompareImage/Header';
import CircularProgress from '@mui/material/CircularProgress';
import BeforeAfter from '../BeforeAfter';
import ViewPhoto from '../CompareImage/ViewPhoto';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
  ViberIcon,
  WhatsappIcon,
  XIcon,
  FacebookMessengerShareButton,
} from "react-share";
const { PHOTO_GEN_MODEL, UPSCALE_MODEL, MAGIC_FILL_MODEL, REMIX_MODEL } = require('../../constants/model');

const ExportComponent = ({ images, onClose, isMobile }) => {
  const downloadRef = useRef(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {

    const handleClickOutside = event => {
      if (downloadRef.current && event.target.contains(downloadRef.current)) {
        onClose();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [downloadRef]);

  function generateRandomFileName(extension) {
    const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
    return `reok-${randomNumber}.${extension}`;
  }

  const handleClickDownload = (image) => {
    setIsDownloading(true);

    try {
      setTimeout(async () => {
        const response = await fetch(image);
        const blob = await response.blob();

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        const fileName = generateRandomFileName('jpeg');
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(url);
        setIsDownloading(false);

      }, 2000);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  }

  function convertModelToText(model) {
    if (model === PHOTO_GEN_MODEL) {
      return 'Studio';
    } else if (model === UPSCALE_MODEL) {
      return 'Magic upscaler';
    } else if (model === MAGIC_FILL_MODEL) {
      return 'Magic fixer';
    } else if (model === REMIX_MODEL) {
      return 'Transfer style + pose';
    }
    return '';
  }

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={true}
      onClose={() => onClose()}
    >      
      <ModalDialog
        sx={{
          width: {
            xs: '98%',
            md: '1300px'
          },
          // height: '100%',
          background: '#09090b',
          border: '1px solid #373737',
          overflow: 'auto'
        }}
      >        
        <ModalClose
          onClick={() => onClose()}
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            color: 'white',
            zIndex: 1000,
          }}
        />
        <div className="export-container">
          {!(images.mode === PHOTO_GEN_MODEL || images.before === "") ? (
            <BeforeAfter
              images={images}
            />
          ) : (
            <ViewPhoto
              images={images}
            />
          )}

          <div className="export-content">
            <h3 className="font-semibold mb-2 text-white mt-2">{convertModelToText(images.mode)}</h3>
            <textarea
              ref={textareaRef}
              readOnly
              className="text-white mb-4 p-2 bg-gray rounded-md w-full"
              value={images.prompt}
              style={{ resize: 'none', overflow: 'auto' }}
              rows={isMobile? 3:6}
            />
            <h3 className="font-semibold mb-2 text-white">Share on social</h3>
            <div className="grid rnftg-grid-cols-5 gap-4 mb-5 mt-4">
              <div className="flex flex-col items-center">
                <FacebookShareButton url={images.after} quote={images.prompt}>
                  <FacebookIcon size={45} round={true}/>
                </FacebookShareButton>
                <span className="text-xs text-center text-white mt-1">Facebook</span>
              </div>
              <div className="flex flex-col items-center">
                <FacebookMessengerShareButton url={images.after} quote={images.prompt}>
                  <FacebookMessengerIcon size={45} round={true}/>
                </FacebookMessengerShareButton>
                <span className="text-xs text-center text-white mt-1">Messenger</span>
              </div>
              <div className="flex flex-col items-center">
                <LinkedinShareButton url={images.after} quote={images.prompt}>
                  <LinkedinIcon size={45} round={true}/>
                </LinkedinShareButton>
                <span className="text-xs text-center text-white mt-1">Linkedin</span>
              </div>
              <div className="flex flex-col items-center">
                <RedditShareButton url={images.after} quote={images.prompt}>
                  <RedditIcon size={45} round={true}/>
                </RedditShareButton>
                <span className="text-xs text-center text-white mt-1">Reddit</span>
              </div>
              <div className="flex flex-col items-center">
                <PinterestShareButton url={images.after} quote={images.prompt}>
                  <PinterestIcon size={45} round={true}/>
                </PinterestShareButton>
                <span className="text-xs text-center text-white mt-1">Pinterest</span>
              </div>
              <div className="flex flex-col items-center">
                <TwitterShareButton url={images.after} quote={images.prompt}>
                  <XIcon size={45} round={true}/>
                </TwitterShareButton>
                <span className="text-xs text-center text-white mt-1">X</span>
              </div>
              <div className="flex flex-col items-center">
                <TelegramShareButton url={images.after} quote={images.prompt}>
                  <TelegramIcon size={45} round={true}/>
                </TelegramShareButton>
                <span className="text-xs text-center text-white mt-1">Telegram</span>
              </div>
              <div className="flex flex-col items-center">
                <WhatsappShareButton url={images.after} quote={images.prompt}>
                  <WhatsappIcon size={45} round={true}/>
                </WhatsappShareButton>
                <span className="text-xs text-center text-white mt-1">Whatsapp</span>
              </div>
              <div className="flex flex-col items-center">
                <ViberShareButton url={images.after} quote={images.prompt}>
                  <ViberIcon size={45} round={true}/>
                </ViberShareButton>
                <span className="text-xs text-center text-white mt-1">Viber</span>
              </div>
              <div className="flex flex-col items-center">
                <EmailShareButton url={images.after} quote={images.prompt}>
                  <EmailIcon size={45} round={true}/>
                </EmailShareButton>
                <span className="text-xs text-center text-white mt-1">Email</span>
              </div>
              {/* <button className="flex flex-col items-center" onClick={() => shareOnSocialMedia('youtube')}>
                <img src="images/youtube-icon.svg" alt="Youtube" className="w-10 h-10 mb-1" />
                <span className="text-xs text-center text-white">Youtube</span>
              </button>
              <button className="flex flex-col items-center" onClick={() => shareOnSocialMedia('facebook')}>
                <img src="images/facebook-icon.svg" alt="Facebook" className="w-10 h-10 mb-1" />
                <span className="text-xs text-center text-white">Facebook</span>
              </button>
              <button className="flex flex-col items-center" onClick={() => shareOnSocialMedia('instagram')}>
                <img src="images/instagram-icon.svg" alt="Instagram" className="w-10 h-10 mb-1" />
                <span className="text-xs text-center text-white">Instagram</span>
              </button>
              <button className="flex flex-col items-center" onClick={() => shareOnSocialMedia('linkedin')}>
                <img src="images/linkedin-icon.svg" alt="Linkedin" className="w-12 h-12 mb-1" style={{ maxWidth: '100%', height: 'auto' }} />
                <span className="text-xs text-center text-white">Linkedin</span>
              </button>
              <button className="flex flex-col items-center" 
                onClick={() => shareOnSocialMedia('twitter')}
              >
                <img src="images/x.webp" alt="X" className="w-8 h-8 mb-3 mt-2" style={{ filter: 'invert(1)' }} />
                <span className="text-xs text-center text-white">X</span>
              </button> */}
            </div>
            <button className=" bg-pink-500 p-3 rounded-lg flex justify-between items-center">
              <div className="flex items-center text-sm bold" onClick={() => handleClickDownload(images.after)}>
                {isDownloading ?
                  <CircularProgress size={24} sx={{ color: '#fff' }} />
                  : (
                    <ArrowDownToLine className="h-6 w-6 mr-2" />
                  )}
                <span style={{ marginLeft: '6px' }}>Download</span>
              </div>
            </button>
          </div>
        </div>
        </ModalDialog>
        </Modal>
  );
};

export default ExportComponent;