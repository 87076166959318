import React, { useEffect, useRef, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';

const icons = {
  All: <svg fill="currentColor" width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3,11h7a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1H3A1,1,0,0,0,2,3v7A1,1,0,0,0,3,11ZM4,4H9V9H4ZM22,3a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1ZM20,9H15V4h5ZM2,21a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1H3a1,1,0,0,0-1,1Zm2-6H9v5H4Zm18,6V14a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7A1,1,0,0,0,22,21Zm-2-1H15V15h5Z" /></svg>,
  Trending: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-flame"><path d="M8.5 14.5A2.5 2.5 0 0 0 11 12c0-1.38-.5-2-1-3-1.072-2.143-.224-4.054 2-6 .5 2.5 2 4.9 4 6.5 2 1.6 3 3.5 3 5.5a7 7 0 1 1-14 0c0-1.153.433-2.294 1-3a2.5 2.5 0 0 0 2.5 2.5z"/></svg>,
  ForCreator: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-thumbs-up"><path d="M7 10v12"/><path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2a3.13 3.13 0 0 1 3 3.88Z"/></svg>,
  ForBusiness: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-handshake"><path d="m11 17 2 2a1 1 0 1 0 3-3"/><path d="m14 14 2.5 2.5a1 1 0 1 0 3-3l-3.88-3.88a3 3 0 0 0-4.24 0l-.88.88a1 1 0 1 1-3-3l2.81-2.81a5.79 5.79 0 0 1 7.06-.87l.47.28a2 2 0 0 0 1.42.25L21 4"/><path d="m21 3 1 11h-2"/><path d="M3 3 2 14l6.5 6.5a1 1 0 1 0 3-3"/><path d="M3 4h8"/></svg>,
  CreateWithAI: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-wand-sparkles"><path d="m21.64 3.64-1.28-1.28a1.21 1.21 0 0 0-1.72 0L2.36 18.64a1.21 1.21 0 0 0 0 1.72l1.28 1.28a1.2 1.2 0 0 0 1.72 0L21.64 5.36a1.2 1.2 0 0 0 0-1.72"/><path d="m14 7 3 3"/><path d="M5 6v4"/><path d="M19 14v4"/><path d="M10 2v2"/><path d="M7 8H3"/><path d="M21 16h-4"/><path d="M11 3H9"/></svg>,
};

const categories = [
  { name: 'Trending', icon: icons.Trending },
  { name: 'For creator', icon: icons.ForCreator },
  { name: 'For business', icon: icons.ForBusiness },
];

const badWordsString = process.env.REACT_APP_BAD_WORDS || '';

const StyledTabs = styled(({ darkMode, ...props }) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ darkMode }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    width: "100%",
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    // backgroundColor: "#fb5183",
    backgroundImage: "linear-gradient(115deg, #4fcf70, #fad648, #a767e5, #12bcfe, #44ce7b)"
  },
}));

const TabLabel = ({ icon, label }) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
    {icon}
    <span>{label}</span>
  </div>
);

const StyledTab = styled(({ darkMode, ...props }) => (
  <Tab disableRipple {...props} />
))(({ darkMode }) => ({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: 16,
  fontFamily: 'inherit',
  letterSpacing: "-0.01em",
  backgroundColor: 'transparent',
  color: darkMode ? '#5d5d5d' : '#15293c80',
  '&.Mui-selected': {
    color: darkMode ? 'white' : 'black',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: "-0.01em"
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="overflow-auto mt-2">
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const PhotoPacks = ({ firebase, onShowStyle, stylesList, handleSelectStyle, promptStyle, darkMode, handleSelectCollection }) => {
  const popupRef = useRef(null);
  const [currentStyles, setCurrentStylesList] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isPrompt, setIsPrompt] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [value, setValue] = useState(0);
  const [error, setError] = useState('');
  const [forbiddenWords, setForbiddenWords] = useState([]);
  const [hotPacks, setHotPacks] = useState([]);
  const [trendingPacks, setTrendingPacks] = useState([]);
  const [forCreatorPacks, setForCreatorPacks] = useState([]);
  const [forBusinessPacks, setForBusinessPacks] = useState([]);
  const [createWithAIPacks, setCreateWithAIPacks] = useState([]);

  useEffect(() => {
    firebase.getPhotoPacksOnLanding().then(querySnapshot => {
      const sortedPacks = querySnapshot.docs.map(doc => doc.data()).sort((a, b) => {
        return (b.isHot ? 1 : 0) - (a.isHot ? 1 : 0) || (new Date(a.createdAt) - new Date(b.createdAt)); // Sort by isHot and then by createdAt
      });
      setTrendingPacks(sortedPacks.filter(pack => pack.category.includes('trending')));
      setForCreatorPacks(sortedPacks.filter(pack => pack.category.includes('for_creator')));
      setForBusinessPacks(sortedPacks.filter(pack => pack.category.includes('for_business')));
      setCreateWithAIPacks(sortedPacks.filter(pack => pack.category.includes('create_with_ai')));
    });
  }, []);

  useEffect(() => {
    if (stylesList !== undefined ) {
      // Start with business
      // const filteredStyles = stylesList.filter(style => style.type === "new");
      setCurrentStylesList(stylesList);
    }

    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (popupRef.current && event.target.contains(popupRef.current)) {
        onShowStyle(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onShowStyle]);

  useEffect(() => {
    // Read bad words from environment variable
    const badWordsArray = badWordsString.split(',');
    setForbiddenWords(badWordsArray);
  }, []);

  useEffect(() => {
    if (promptStyle !== '') {
      setPrompt(promptStyle);
    }
  }, [promptStyle]);
  
  function onSelectStyle(e, style) {
    e.preventDefault();
    handleSelectStyle(style);
  }

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    let filteredStyles;

    if (newValue === 0) {
      filteredStyles = stylesList.filter(style => style.type === "new");
    } else if (newValue === 1) { // custom items
      filteredStyles = stylesList.filter(style => style.type === "custom");
    }

    setCurrentStylesList(filteredStyles);
  };

  const openPhotoPipeLine = (e, item) => {
    handleSelectCollection(item);
    onShowStyle(false);
  }

  return (
    <>
      <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={true}
      onClose={() => onShowStyle(false)}
    >
      <ModalDialog
        sx={{
          width: {
            xs: '100%',
            md: '100%'
          },
          height: '100%',
          background: '#09090b',
          border: '1px solid #373737',
          overflow: 'auto'
        }}
      >
        <ModalClose
          onClick={() => onShowStyle(false)}
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            color: 'white',
            zIndex: 1000,
          }}
        />
          <Box sx={{ borderBottom: 1, borderColor: darkMode ? '#373737' : '#f1f3f4' }}>
            <StyledTabs
              darkMode={darkMode}
              value={value}
              onChange={handleChangeTab}
              aria-label="styled tabs example"
              variant="scrollable"
              scrollButtons={false}
            >
              {categories.map((category, index) => (
                <StyledTab 
                  key={index}
                    darkMode={darkMode} 
                    label={<TabLabel icon={category.icon} label={category.name} />}
                />
              ))}
            </StyledTabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div className={`${darkMode ? 'text-white' : 'text-black'}`}>
            <div className="rnftg-grid rnftg-gap-4 rnftg-grid-flow-row rnftg-grid-cols-2 md:rnftg-grid-cols-4 lg:rnftg-grid-cols-5 xl:rnftg-grid-cols-5">
              {trendingPacks.map((item, key) => (
                <div key={key} onClick={(e) => openPhotoPipeLine(e, item)} className={`rounded-lg shadow-md overflow-hidden transition-colors duration-200 w-full ${darkMode ? 'dark-bg-home' : ''} } home-item`}>
                  <div className="relative aspect-w-16 aspect-h-9">
                      <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
                      {item.isHot && (
                        <div className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full" style={{ marginTop: "-12px" }}>
                          Hot
                        </div>
                      )}
                    </div>
                    <div className="p-4">
                      <p className="text-sm font-bold mb-1">{item.title}</p>
                      <p className="font-size-sm text-gray-600 dark:text-gray-300">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div className={`${darkMode ? 'text-white' : 'text-black'}`}>
            <div className="rnftg-grid rnftg-gap-4 rnftg-grid-flow-row rnftg-grid-cols-2 md:rnftg-grid-cols-4 lg:rnftg-grid-cols-5 xl:rnftg-grid-cols-5">
              {forCreatorPacks.map((item, key) => (
                <div key={key} onClick={(e) => openPhotoPipeLine(e, item)} className={`rounded-lg shadow-md overflow-hidden transition-colors duration-200 w-full ${darkMode ? 'dark-bg-home' : ''} } home-item`}>
                  <div className="relative aspect-w-16 aspect-h-9">
                      <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
                      {item.isHot && (
                        <div className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full" style={{ marginTop: "-12px" }}>
                          Hot
                        </div>
                      )}
                    </div>
                    <div className="p-4">
                      <p className="text-sm font-bold mb-1">{item.title}</p>
                      <p className="font-size-sm text-gray-600 dark:text-gray-300">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <div className={`${darkMode ? 'text-white' : 'text-black'}`}>
            <div className="rnftg-grid rnftg-gap-4 rnftg-grid-flow-row rnftg-grid-cols-2 md:rnftg-grid-cols-4 lg:rnftg-grid-cols-5 xl:rnftg-grid-cols-5">
              {forBusinessPacks.map((item, key) => (
                <div key={key} onClick={(e) => openPhotoPipeLine(e, item)} className={`rounded-lg shadow-md overflow-hidden transition-colors duration-200 w-full ${darkMode ? 'dark-bg-home' : ''} } home-item`}>
                  <div className="relative aspect-w-16 aspect-h-9">
                      <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
                      {item.isHot && (
                        <div className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full" style={{ marginTop: "-12px" }}>
                          Hot
                        </div>
                      )}
                    </div>
                    <div className="p-4">
                      <p className="text-sm font-bold mb-1">{item.title}</p>
                      <p className="font-size-sm text-gray-600 dark:text-gray-300">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </CustomTabPanel>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default PhotoPacks;
