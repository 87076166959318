import React, { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import i18n from '../i18n';
import Spinner from '../components/Spinner';

const PrivateRoute = () => {
  const user = useSelector(state => state.user);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Wait for the user state to stabilize
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [user]);

  if (isLoading) {
    return <Spinner />;
  }
  if (!user) {
    const redirectPath = i18n.language === "en" ? "/signin" : `/${i18n.language}/signin`;
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;