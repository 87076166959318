import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Avatar } from '../General/Avatar'
import SignOutContainer from '../../containers/SignOutContainer';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Skeleton } from 'antd';

import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  HomeOutlined,
  CustomerServiceOutlined,
  LoginOutlined
} from '@ant-design/icons';
import QuickLanguageSwitcher from '../QuickLanguageSwitcher';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#aab4be',
        ...theme.applyStyles('dark', {
          backgroundColor: '#2c2c2c',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#2c2c2c',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
    ...theme.applyStyles('dark', {
      backgroundColor: '#2c2c2c',
    }),
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be',
    borderRadius: 20 / 2,
    ...theme.applyStyles('dark', {
      backgroundColor: '#2c2c2c',
    }),
  },
}));

const NewAvatarHeader = ({ userData, t, darkMode, onSelectDarkMode, onShowFeedback }) => {
  const dropdownDesktopRef = useRef(null);
  const [showProfileOptions, setShowProfileOptions] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownDesktopRef.current && !dropdownDesktopRef.current.contains(event.target)) {
        dropdownDesktopRef.current.classList.remove('newshow');
        setShowProfileOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function onOpenTogglerDesktop(e) {
    e.stopPropagation();
    dropdownDesktopRef.current.classList.toggle('newshow');
    setShowProfileOptions(!showProfileOptions);
  }

  function showSupport() {
    window.HubSpotConversations.widget.open();
  }

  const handleFeedbackClick = (e) => {
    e.preventDefault();
    onShowFeedback();
  };

  function hideDropDown() {
    dropdownDesktopRef.current.classList.remove('newshow');
  }

  const renderAvatar = () => {
    if (userData === undefined) {
      return (
        <>
          <Skeleton.Avatar active size={40} />
          <Skeleton.Input style={{ width: 120, marginLeft: 8 }} active size="small" />
        </>
      );
    }

    if (!userData?.emailVerified) {
      return (
        <span className="avatar flex-shrink-0 avatar-base avatar-circle">
          <div className="avatar-initials bg-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              viewBox="0 0 16 16"
              fill="none"
              className="svg-icon rounded-0"
            >
              <g clipPath="url(#4f25a6d6acd8dd3fbe3e)">
                <path
                  d="M8 0C3.58849 0 0 3.58849 0 8C0 12.4115 3.58849 16 8 16C12.4115 16 16 12.4115 16 8C16 3.58849 12.4115 0 8 0ZM8 2.85712C9.57119 2.85712 10.8571 4.14313 10.8571 5.71431C10.8571 7.2855 9.57119 8.57144 8 8.57144C6.42881 8.57144 5.14286 7.2855 5.14286 5.71431C5.14286 4.14313 6.42881 2.85712 8 2.85712V2.85712ZM5.71429 9.71431H10.2857C11.8459 9.71431 13.1199 10.9781 13.1406 12.5335C11.8852 13.9566 10.052 14.8571 8 14.8571C5.94803 14.8571 4.11482 13.9566 2.85937 12.5335C2.88006 10.9781 4.15412 9.71431 5.71429 9.71431V9.71431Z"
                  fill="#495057"
                />
              </g>
              <defs>
                <clipPath id="4f25a6d6acd8dd3fbe3e">
                  <rect width={16} height={16} fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </span>
      );
    }

    return (
      <>
        <Avatar
          color='#222'
          size={40}
          initials={`${userData?.firstName?.charAt(0).toUpperCase() ?? ""} ${userData?.lastName?.charAt(0).toUpperCase() ?? ""}`}
        />
        {/* <span className='avatar-name'>{`${userData?.firstName ?? ""} ${userData?.lastName ?? ""}`}</span>
        <span className='text-white'>
          {showProfileOptions ? (
            <ChevronUp width={22} height={22}/>
          ) : (
            <ChevronDown width={22} height={22}/>
          )}
        </span> */}
      </>
    );
  };

  return (
    <nav className="navbar navbar-main navbar-height navbar-expand-lg theme-transparent">
      <div className="gap-3">
        <div id="nav" className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            {/* {!userData?.emailVerified &&
              <Link
                to="/signin"
                className="btn btn-outline-light btn-sm rounded-pill text-nowrap"
                style={{}}
                type="default"
              >
                {t('button.getStarted')}
              </Link>} */}
            <div className="dropdown">
              <button
                className="navbar-dropdown-account-wrapper"
                id="accountNavbarDropdown"
                onClick={onOpenTogglerDesktop}
              >
                {renderAvatar()}
              </button>
              {userData ? (
                <div
                  className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                  aria-labelledby="accountNavbarDropdown"
                  ref={dropdownDesktopRef}
                >
                  <div className="dropdown-item fw-semi-bold">
                    <a
                      href="https://reok.lemonsqueezy.com/billing"
                      style={{ color: 'inherit' }}
                      target='_blank'
                    >
                      <span style={{ display: "inline-grid", paddingRight: "2px" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24px" height="24px" viewBox="0 0 0.72 0.72">
                          <path d="M0.6 0.12H0.12c-0.033 0 -0.06 0.027 -0.06 0.06L0.06 0.54c0 0.033 0.027 0.06 0.06 0.06h0.48c0.033 0 0.06 -0.027 0.06 -0.06V0.18c0 -0.033 -0.027 -0.06 -0.06 -0.06m0 0.42H0.12v-0.18h0.48zm0 -0.3H0.12V0.18h0.48z" />
                        </svg>
                      </span>
                      <span>{t('header.nav.billing')}</span>
                    </a>
                  </div>
                  <div className="new-dropdown-divider order-5" />
                  <div className="order-5" onClick={hideDropDown}>
                    <a className="dropdown-item" onClick={handleFeedbackClick} style={{ cursor: "pointer" }}>
                      <CustomerServiceOutlined />
                      <span>{t('header.nav.supportContact')}</span>
                    </a>
                  </div>
                  <div className="new-dropdown-divider order-5" />
                  <div className="dropdown-item fw-semi-bold">
                    <SignOutContainer t={t} />
                  </div>
                </div>
              ) : (
                <div
                  className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                  aria-labelledby="accountNavbarDropdown"
                  ref={dropdownDesktopRef}
                >
                  <Link className="dropdown-item fw-semi-bold" to="/signin">
                    <LoginOutlined /><span style={{ marginLeft: "5px" }}>{t('header.nav.signIn')}</span>
                  </Link>
                  <div className="new-dropdown-divider order-5" />
                  <a className="dropdown-item fw-semi-bold" href="/signup">
                    <LoginOutlined /><span style={{ marginLeft: "5px" }}>{t('header.nav.signUp')}</span>
                  </a>
                  <div className="new-dropdown-divider order-5" />
                  <div className="order-5" onClick={hideDropDown}>
                    <a className="dropdown-item" onClick={handleFeedbackClick} style={{ cursor: "pointer" }}>
                      <CustomerServiceOutlined />
                      <span style={{ marginLeft: "5px" }}>{t('header.nav.supportContact')}</span>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <MaterialUISwitch
              checked={darkMode}
              onChange={onSelectDarkMode}
            />
          </div>
          <div className="pl-1">
            <QuickLanguageSwitcher />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default NewAvatarHeader