import React, { useEffect, useState, useRef } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import { Select, TextField, Tooltip, FormControl, Stack, Typography, MenuItem, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { UPSCALE_MODEL, PHOTO_GEN_MODEL, REMOVE_OBJECTS_MODEL, MAGIC_FILL_MODEL, REMIX_MODEL } from '../../constants/model';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash';
import heic2any from 'heic2any';
import axios from 'axios';
import { RowContainer } from '../SmartEditor/components/SidePanel/LabelTitle';
// import { Slider } from '../SmartEditor/components/ui/slider';
import Slider, { SliderThumb } from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import Check from '@mui/icons-material/Check';
import StudioShowCase from '../StudioShowCase';
import PhotoPacks from '../PhotoPacks';
import { useStore } from '../SmartEditor/lib/states';

const APP_URL_V1 = process.env.REACT_APP_APP_URL;

const modes = {
  pro: 'Pro',
  basic: 'Basic',
};

const MAX_WIDTH = 768;

const results = [
  {
    value: 1,
    label: 'one',
  },
  {
    value: 4,
    label: 'four',
  },
];

const ageItems = [];
for (let age = 18; age <= 100; age++) {
  ageItems.push({
    value: age,
    label: age,
  });
}

const badWordsString = process.env.REACT_APP_BAD_WORDS || '';
const badWordsArray = badWordsString.split(',').map(word => word.trim());
const forbiddenWordsSet = new Set(badWordsArray.map(word => word.toLowerCase()));

const containsBadWord = text => {
  const words = text.toLowerCase().split(/\s+/);
  return words.some(word => forbiddenWordsSet.has(word));
};

const MAX_IMAGES = 20;
const MIN_IMAGES = 8;
const TRAIN_MODEL_CREDITS = 200;
const maxSizeBasic = 10 * 1024 * 1024; // 10 MB
const maxSizePro = 15 * 1024 * 1024; // 10 MB

const aspectRatios = [
  { label: '1:1', value: '1:1' },
  { label: '16:9', value: '16:9' },
  { label: '21:9', value: '21:9' },
  { label: '3:2', value: '3:2' },
  { label: '2:3', value: '2:3' },
  { label: '4:5', value: '4:5' },
  { label: '5:4', value: '5:4' },
  { label: '3:4', value: '3:4' },
  { label: '4:3', value: '4:3' },
  { label: '9:16', value: '9:16' },
  { label: '9:21', value: '9:21' },
];

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#fc5083',
        ...theme.applyStyles('dark', {
          backgroundColor: '#fc5083',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)',
    }),
  },
}));

const PrettoSlider = styled(Slider)({
  color: '#fc5083',
  height: 6,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.3)',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const Studio = ({
  firebase,
  setUser,
  darkMode,
  userData,
  setUserData,
  onShowPricing,
  openTrainModel,
  onMagicFixer,
  handleOnUpImageResolution,
  runModel,
  remixFile,
  setRemixFile,
  onRemoveObject,
  selectedCollection,
  setSelectedCollection,
  onSetUpdateMyAssets,
  onHideMenuBar
}) => {
  // const [userData, setUserData] = useState({});
  const [modelsList, setModelsList] = useState([]);
  const [jobsList, setJobsList] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [mode, setMode] = useState(modes.pro); //Set default is PRO
  const fileInputRef = useRef(null);
  const [styleSrc, setStyleSrc] = useState('');
  const [remixPoseSrc, setRemixPoseSrc] = useState('');
  const [remixPoseFile, setRemixPoseFile] = useState('');
  const [styleName, setStyleName] = useState(null);
  const [styleCode, setStyleCode] = useState('');
  const [currentResult, setCurrentResult] = useState(4);
  const [currentJob, setCurrentJob] = useState(null);
  const [isActiveDeskMenu, setIsActiveDeskMenu] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('');
  const [promptStyle, setPromptStyle] = useState('');
  const [credits, setCredits] = useState(20);
  const [progress, setProgress] = useState(0);

  const [promptErrorMessage, setPromptErrorMessage] = useState('');
  const [getPromptErrorMessage, setGetPromptErrorMessage] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);
  const lastImageRef = useRef(null);
  const [shouldScrollToLast, setShouldScrollToLast] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [isShowStyle, setIsShowStyle] = useState(false);
  const [creativity, setCreativity] = useState(3);
  const [activeTab, setActiveModelTab] = useState('1');
  const [activeModeTab, setActiveModeTab] = useState('1');
  const [isLoadingModels, setIsLoadingModels] = useState(true);
  const [currentAspectRatio, setCurrentAspectRatio] = useState('1:1');
  const [similarity, setSimilarity] = useState(2);
  const [publicModels, setPublicModels] = useState([]);
  const [enhancePromptLoading, setEnhancePromptLoading] = useState(false);
  const [getPromptLoading, setGetPromptLoading] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [lockPose, setLockPose] = useState(true);
  const [transferredImageSize, setTransferredImageSize] = useState(false);

  const [updateSettings] = useStore(state => [state.updateSettings]);

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    if (shouldScrollToLast && lastImageRef.current) {
      lastImageRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      setShouldScrollToLast(false);
    }
  }, [selectedImages, shouldScrollToLast]);

  useEffect(() => {
    if (runModel) {
      if (runModel.isPublic) {
        setActiveModelTab('2');
      } else {
        setActiveModelTab('1');
      }
      setSelectedModel(runModel);
    }
  }, [runModel]);

  useEffect(() => {
    if (selectedCollection) {
      handleSelectCollectionFromHome(selectedCollection);
      setSelectedCollection(null);
    }
  }, [selectedCollection]);

  useEffect(() => {
    if (remixFile) {
      const file = remixFile;
      setActiveModeTab('2');

      if (checkFileSize(file) === false) return;

      setRemixPoseFile(file);
      const reader = new FileReader();

      reader.onload = function () {
        const image = new Image();

        image.onload = function () {
          const canvas = document.createElement('canvas');
          const scaleSize = MAX_WIDTH / image.width;
          canvas.width = image.width;
          canvas.height = image.height;

          const ctx = canvas.getContext('2d');

          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          if (file.name === '' || file.name === undefined) {
            file.name = `reok-input-${uuidv4()}.jpg`;
          }

          canvas.toBlob(blob => {
            const reader2 = new FileReader();
            reader2.onloadend = function () {
              const srcEncoded = reader2.result;
              setRemixPoseSrc(srcEncoded);
            };

            canvas.remove(); // Remove the canvas element
            reader2.readAsDataURL(blob);
          }, 'image/jpeg');
        };

        image.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  }, [remixFile]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (window.innerWidth <= 980) {
      setIsMobile(true);
      setIsActiveDeskMenu(false);
    }

    const handleResize = () => {
      if (window.innerWidth <= 980) {
        setIsMobile(true);
        setIsActiveDeskMenu(false);
      } else {
        setIsMobile(false);
        setIsActiveDeskMenu(true);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchJobs = async () => {
      setIsLoadingModels(true);
      try {
        const snapshot = await firebase.getTrainingJobs(userData.uid);
        const modelList = snapshot.docs
          .filter(doc => doc.data().status === 'finished')
          .map(doc => ({
            id: doc.id,
            triggerWord: doc.data().triggerWord,
            modelName: doc.data().modelName,
            type: doc.data().type,
            images: {
              before: doc.data().data[0],
              after: '',
            },
            thumbnail: doc.data().thumbnail,
            status: doc.data().status,
            progress: 1,
            createdAt: doc.data().createdAt ? new Date(doc.data().createdAt).toLocaleString() : '',
            error: doc.data().error,
            isPublic: false,
          }));
        setModelsList(modelList);

        const publicModels = await firebase.getPublicModels();
        const publicModelsList = publicModels.docs.map(doc => ({
          id: doc.id,
          triggerWord: doc.data().triggerWord,
          modelName: doc.data().modelName,
          type: doc.data().type,
          images: {
            before: doc.data().thumbnail,
            after: '',
          },
          thumbnail: doc.data().thumbnail,
          status: doc.data().status,
          isPublic: true,
        }));
        setPublicModels(publicModelsList);
      } catch (error) {
        console.error('Error fetching training jobs:', error);
      } finally {
        setIsLoadingModels(false);
      }
    };

    fetchJobs();
  }, [firebase, userData]);

  function checkCredits() {
    if (mode === modes.pro && ((userData.pro && userData.proCredits < 2) || !userData.pro)) {
      message.warning(t('message.warning.runOutProCredits'));
      setTimeout(() => {
        setIsCreating(false);
        onShowPricing(true);
      }, 1500);
      return true;
    }

    if (mode === modes.basic && ((userData.basic && userData.basicCredits < 2) || !userData.basic)) {
      message.warning(t('message.warning.runOutBasicCredits'));
      setTimeout(() => {
        setIsCreating(false);
        onShowPricing(true);
      }, 1500);
      return true;
    }

    return false;
  }

  const scrollToTop = () => {
    const section = document.querySelector('#scroll-top');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  function checkCreditsProfessional() {
    if (mode === modes.pro && userData.pro && userData.proCredits < currentResult * 5) {
      message.warning(t('message.warning.runOutProCredits'));
      setTimeout(() => {
        setIsCreating(false);
        onShowPricing(true);
      }, 1500);
      return true;
    }
    return false;
  }

  const incrementProgress = () => {
    setProgress(prevProgress => {
      const newProgress = prevProgress + Math.floor(Math.random() * 3);

      if (newProgress < 100) {
        return newProgress;
      } else {
        return prevProgress; // Return the previous state without updating
      }
    });
  };

  async function onGeneratePhoto() {
    if (!!promptErrorMessage) {
      message.error(t('message.error.badWordsStyle'));
      return;
    }

    if (checkCreditsProfessional()) return;

    if (selectedModel === null || selectedModel === '') {
      message.error(t('message.error.selectModel'));
      return;
    }

    if (currentAspectRatio === '' || currentAspectRatio === null) {
      message.error(t('message.error.aspectRatio'));
      return;
    }

    if (!currentResult || currentResult === '') {
      message.error(t('message.error.numberOfResult'));
      return;
    }

    if (creativity < 1 || creativity > 10) {
      message.error(t('message.error.creativity'));
      return;
    }

    //Validate data for each basic and advanced mode
    if (activeModeTab === '2') {
      if (promptStyle === '' || promptStyle === null) {
        message.error(t('message.error.promptStyle'));
        return;
      }
    } else if (activeModeTab === '1') {
      if (styleCode === '' || styleCode === null) {
        message.error(t('message.error.stylePackage'));
        return;
      }
    } else {
      message.error(t('message.error.modeUnSupported'));
      return;
    }

    let imageUrl = selectedModel.thumbnail;
    let prompt = activeModeTab === '1' ? styleName : promptStyle;
    const isRemix = remixPoseFile && similarity !== 0 && activeModeTab === '2';

    setIsCreating(true);

    if (activeModeTab === '2') {
      if (isRemix) {
        imageUrl = await firebase.uploadPoseFile(remixPoseFile);
      }
    }

    try {
      const resultItem = results.find(item => item.value === currentResult);
      const job = {
        images: {
          before: imageUrl,
          after: '',
        },
        status: 'starting',
        progress: 0,
        prompt: prompt,
        result: t(`results.${resultItem.label}`),
      };

      setCurrentJob(job);

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
      };

      let requestDataEng = {
        packageName: activeModeTab === '1' ? styleCode : 'customize',
        quantity: currentResult,
        prompt: prompt,
        style: '',
        creativity: creativity,
        similarity: similarity,
        modelId: selectedModel.id,
        aspectRatio: currentAspectRatio,
        isPublic: selectedModel.isPublic,
        image: isRemix ? imageUrl : '',
      };

      const result = await axios.post(`${APP_URL_V1}/photo-gen`, requestDataEng, { headers });

      const jobIds = result.data.ids;
      const credits = result.data.credits;
      updateSettings({ credits: credits });

      for (let index = 0; index < jobIds.length; index++) {
        const jobId = jobIds[index];

        while (true) {
          const result = await axios.get(`${APP_URL_V1}/photo-gen-job/${jobId}`, {}, { headers });

          if (result.data.status === 'finished') {
            setProgress(100);
            setIsCreating(false);

            let finishedImage = result.data.image;

            const newImages = {
              before: imageUrl,
              after: finishedImage,
            };

            const newJob = {
              images: newImages,
              status: 'finished',
              prompt: prompt,
              uid: userData.uid,
              id: result.data.id,
              isLoved: false,
              mode: isRemix ? REMIX_MODEL : PHOTO_GEN_MODEL,
            };
            setCurrentJob(newJob);

            break;
          } else if (result.data.status === 'nsfw') {
            setIsCreating(false);
            let finishedImage = result.data.image;

            const newImages = {
              before: imageUrl,
              after: finishedImage,
            };

            const newJob = {
              images: newImages,
              prompt: promptStyle,
              uid: userData.uid,
              id: result.data.id,
              isLoved: false,
              status: 'nsfw',
            };
            setCurrentJob(newJob);
            break;
          } else if (result.data.status === 'failed-face') {
            message.error(t('message.error.wrongFace'));
            setIsCreating(false);
            const job = {
              status: 'failed-face',
            };

            setCurrentJob(job);
            return;
          } else if (result.data.status === 'failed') {
            message.error(t('message.error.wrongFace'));
            setIsCreating(false);
            return;
          } else {
            if (result.data.status > progress) {
              setProgress(result.data.status);
            } else {
              incrementProgress();
            }
          }
          await new Promise(resolve => setTimeout(resolve, 3000));
        }
      }

      message.success(t('message.success.rederingDone'));
      setProgress(0);
      onFetchUser();

      //Update to my assets
      onSetUpdateMyAssets();
    } catch (error) {
      if (error.response.data.error === 'not-enough-credits') {
        message.error(t('message.error.outOfCredits'));
        setIsCreating(false);
        return;
      }
      message.error(t('message.error.wrongFace'));
      setIsCreating(false);
    }
  }

  function onGenFill(newJob) {
    setCurrentJob(newJob);
  }

  function onFetchUser() {
    firebase
      .getUser(firebase.auth.currentUser.uid)
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          setUserData(querySnapshot.data());
          setUser(querySnapshot.data());
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const checkFileSize = file => {
    const fileSize = file.size;

    if (mode === modes.pro && fileSize > maxSizePro) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return false;
    }
    if (mode === modes.basic && fileSize > maxSizeBasic) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return false;
    }

    setSimilarity(2);

    if (activeTab === '1') {
      setSimilarity(2);
    }

    setTransferredImageSize(true);

    return true;
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      const uploaderWrapper = document.querySelector('.uploader-wrapper');
      uploaderWrapper.style.border = '1px dashed #fc5185';
      uploaderWrapper.style.color = '#fc5185';
    } else if (e.type === 'dragleave') {
      const uploaderWrapper = document.querySelector('.uploader-wrapper');
      uploaderWrapper.style.border = darkMode ? '1px dashed #373737' : '1px dashed rgb(0 0 0 / 20%)';
      uploaderWrapper.style.color = darkMode ? '#373737' : '#8c8c8c';
    }
  };

  const hideMenuBar = () => {
    setIsActiveDeskMenu(!isActiveDeskMenu);
    onHideMenuBar(!isActiveDeskMenu);
  };

  const handleSelectCollection = collection => {
    message.success(`Using ${collection.title}`);

    setActiveModeTab('1'); // Using basic mode
    setStyleName(collection.title);
    setStyleSrc(collection.image);
    setStyleCode(collection.id);
    setCurrentResult(4);
    setCredits(20);
    setCurrentAspectRatio('2:3');

    scrollToTop();
  };

  const handleSelectCollectionFromHome = collection => {
    setActiveModeTab('1'); // Using basic mode
    setStyleName(collection.title);
    setStyleSrc(collection.image);
    setStyleCode(collection.id);
    setCurrentResult(4);
    setCredits(20);
    setCurrentAspectRatio('2:3');

    scrollToTop();
  };

  const debouncedValidation = debounce(value => {
    const badWord = containsBadWord(value);
    if (badWord) {
      setPromptErrorMessage(t('message.error.badWordsStyle', { word: badWord }));
    } else {
      setPromptErrorMessage('');
    }
  }, 100);

  const handleMixDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];

      if (checkFileSize(file) === false) return;

      setRemixPoseFile(file);
      const reader = new FileReader();

      reader.onload = function () {
        const image = new Image();

        image.onload = function () {
          const canvas = document.createElement('canvas');
          const scaleSize = MAX_WIDTH / image.width;
          // canvas.width = MAX_WIDTH;
          // canvas.height = image.height * scaleSize;

          canvas.width = image.width;
          canvas.height = image.height;

          const ctx = canvas.getContext('2d');

          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          if (file.name === '' || file.name === undefined) {
            file.name = `reok-input-${uuidv4()}.jpg`;
          }

          canvas.toBlob(blob => {
            const reader2 = new FileReader();
            reader2.onloadend = function () {
              const srcEncoded = reader2.result;
              setRemixPoseSrc(srcEncoded);
            };

            canvas.remove(); // Remove the canvas element
            reader2.readAsDataURL(blob);
          }, 'image/jpeg');
        };

        image.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const processFile = async file => {
    // Check file format
    const validFormats = ['image/webp', 'image/jpeg', 'image/png'];
    if (!validFormats.includes(file.type) && !file.name.toLowerCase().endsWith('.heic')) {
      return null;
    }

    let processedFile = file;
    if (!validFormats.includes(file.type)) {
      try {
        const pngBlob = await convertToPNG(file);
        processedFile = new File([pngBlob], file.name.replace(/\.[^/.]+$/, '.png'), { type: 'image/png' });
      } catch (error) {
        console.log('Error converting file:', error);
        return null;
      }
    }

    return processedFile;

    // // Check resolution
    // const dimensions = await getImageDimensions(processedFile);
    // const isLowResolution = dimensions.width < 1024 || dimensions.height < 1024;

    // return {
    //   file: processedFile,
    //   url: URL.createObjectURL(processedFile),
    //   isLowResolution: isLowResolution,
    //   dimensions: dimensions
    // };
  };

  const convertToPNG = file => {
    return new Promise((resolve, reject) => {
      if (file.type === 'image/heic' || file.name.toLowerCase().endsWith('.heic')) {
        heic2any({
          blob: file,
          toType: 'image/png',
        })
          .then(conversionResult => {
            resolve(conversionResult);
          })
          .catch(error => {
            console.error('Error converting HEIC to PNG:', error);
            reject(error);
          });
      } else {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(resolve, 'image/png');
        };
        img.onerror = reject;
        img.src = URL.createObjectURL(file);
      }
    });
  };

  const getImageDimensions = file => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });
  };

  const handleModelSelect = subject => {
    setSelectedModel(subject);
  };

  const removeUploadedStyle = function (e) {
    setStyleSrc('');
    setStyleName('');
    setStyleCode('');

    setTransferredImageSize(false);
    // setPromptStyle('');
  };

  const handleStyleUpload = () => {
    setIsShowStyle(true);
  };

  function handleChangeResult(event) {
    setCurrentResult(event.target.value);
    if (event.target.value === 1) {
      setCredits(5);
    }
    if (event.target.value === 4) {
      setCredits(20);
    }
  }
  const handleTabChange = key => {
    setActiveModelTab(key);
  };

  const handleModeTabChange = key => {
    setActiveModeTab(key);
  };

  const renderStrength = () => {
    return (
      <div className="flex flex-col gap-1">
        <RowContainer>
          <PrettoSlider
            className="w-full"
            defaultValue={8}
            min={1}
            max={10}
            step={0.1}
            value={creativity}
            onChange={(_, value) => setCreativity(value)}
          />
        </RowContainer>
      </div>
    );
  };

  const renderSimilarity = () => {
    return (
      <div className="flex flex-col gap-1">
        <RowContainer>
          <PrettoSlider
            className="w-full"
            defaultValue={2}
            min={0}
            max={9}
            step={0.1}
            value={similarity}
            disabled={!lockPose} 
            onChange={(_, value) => setSimilarity(value)}
          />
        </RowContainer>
      </div>
    );
  };

  const handleInputChange = event => {
    const newValue = event.target.value;
    debouncedValidation(newValue);
    setPromptStyle(newValue);
  };

  const enhancePrompt = async () => {
    if (promptStyle === '') {
      setPromptErrorMessage('Hey please describe your prompt, I will enhance it for you in seconds');
      return;
    }

    setEnhancePromptLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
    };

    let requestDataEng = {
      prompt: promptStyle,
    };

    const result = await axios.post(`${APP_URL_V1}/enhance-prompt`, requestDataEng, { headers });

    if (result.data.error) {
      message.error('An error occurred while enhancing the prompt, Try again later');
      return;
    }

    const prompt = result.data.prompt;
    const credits = result.data.credits;
    updateSettings({ credits: credits });
    setPromptStyle(prompt);
    setEnhancePromptLoading(false);
  };

  const onGetPrompt = async () => {
    if (!remixPoseFile) {
      setGetPromptErrorMessage('Please upload a Reference image to get prompt');
      return;
    }

    setGetPromptLoading(true);
    setIsScanning(true);

    const imageUrl = await firebase.uploadPoseFile(remixPoseFile);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
    };

    let requestDataEng = {
      image: remixPoseFile ? imageUrl : '',
    };

    const result = await axios.post(`${APP_URL_V1}/get-prompt`, requestDataEng, { headers });

    if (result.data.error) {
      message.error('An error occurred while getting the prompt, Try again later');
      return;
    }

    const prompt = result.data.prompt;
    const credits = result.data.credits;
    updateSettings({ credits: credits });
    setPromptStyle(prompt);
    setGetPromptLoading(false);
    setIsScanning(false);
  };

  const handleChangeAspectRatio = event => {
    setCurrentAspectRatio(event.target.value);
  };

  const onShowStyle = showStyle => {
    setIsShowStyle(showStyle);
  };

  const removeUploadedImage = function (e) {
    e.preventDefault();
    setRemixPoseFile(null);
    setRemixPoseSrc('');
    setRemixFile(null);
    setIsScanning(false);
    setGetPromptLoading(false);
    setTransferredImageSize(false);
  };

  const handleOpenFileUpload = () => {
    fileInputRef.current.click();
    setGetPromptErrorMessage(null);
  };

  const handleFileUpload = event => {
    const file = event.target.files[0];

    if (checkFileSize(file) === false) return;

    setRemixPoseFile(file);
    const reader = new FileReader();

    reader.onload = function () {
      const image = new Image();

      image.onload = function () {
        const canvas = document.createElement('canvas');
        const scaleSize = MAX_WIDTH / image.width;
        canvas.width = image.width;
        canvas.height = image.height;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        if (file.name === '' || file.name === undefined) {
          file.name = `reok-input-${uuidv4()}.jpg`;
        }

        canvas.toBlob(blob => {
          const reader2 = new FileReader();
          reader2.onloadend = function () {
            const srcEncoded = reader2.result;
            setRemixPoseSrc(srcEncoded);
          };

          canvas.remove(); // Remove the canvas element
          reader2.readAsDataURL(blob);
        }, 'image/jpeg');
      };

      image.src = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const removePromptStyle = () => {
    setPromptStyle('');
  };

  const handleLockPoseChange = (event) => {
    const isChecked = event.target.checked;
    setLockPose(isChecked);
    setSimilarity(isChecked ? 2 : 0);

    if (isChecked) {
      setTransferredImageSize(true);
    } else {
      setTransferredImageSize(false);
      setCurrentAspectRatio('2:3');
    }
  };

  return (
    <div
      className={`right-area editor-area ${darkMode ? 'dark-bg' : 'light-bg'}`}
        style={{
          transition: 'all 0.35s ease',
          left: !isActiveDeskMenu ? '0' : '0',
          width: !isActiveDeskMenu ? '100%' : 'calc(100% - 250px)',
        }}
        >
      <div className={`form-area ${darkMode ? 'dark-bg dark-border-2' : 'light-bg light-border-2'}`}>
        <div>
          <div className="generate-form">
            {!isMobile ? (
              <>
                <div className="flex align-items-center">
                  <span
                    style={{ color: '#888888' }}
                    className={`${darkMode ? 'cursor-pointer-dark' : 'cursor-pointer-light'}`}
                    onClick={hideMenuBar}
                  >
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="bi bi-layout-sidebar-inset"
                    >
                      <path d="M21 3a1.5 1.5 0 0 1 1.5 1.5v15a1.5 1.5 0 0 1 -1.5 1.5H3a1.5 1.5 0 0 1 -1.5 -1.5V4.5a1.5 1.5 0 0 1 1.5 -1.5zM3 1.5a3 3 0 0 0 -3 3v15a3 3 0 0 0 3 3h18a3 3 0 0 0 3 -3V4.5a3 3 0 0 0 -3 -3z" />
                      <path d="M4.5 6a1.5 1.5 0 0 1 1.5 -1.5h3a1.5 1.5 0 0 1 1.5 1.5v12a1.5 1.5 0 0 1 -1.5 1.5H6a1.5 1.5 0 0 1 -1.5 -1.5z" />
                    </svg>
                  </span>
                  <h3 className={`mb-0 pl-4 ${darkMode ? 'text-white' : 'text-black'}`}>{t('app.menu.studioMode')}</h3>
                </div>
                <div style={{ color: '#8c8c8c', fontSize: '14px', marginTop: '0' }}>{t('app.studioMode.title')}</div>
              </>
            ) : (
              <>
                <h3 className={`mb-0 ${darkMode ? 'text-white' : 'text-black'}`}>{t('app.menu.studioMode')}</h3>
                <div style={{ color: '#8c8c8c', fontSize: '14px', marginTop: '0' }}>{t('app.studioMode.title')}</div>
              </>
            )}
            {/* <hr className="section-line"/> */}
            <div className={`mb-0 ${darkMode ? 'text-white' : 'text-black'} model-alert mt-4 py-2 px-4`}>
              Start Photography by choosing an AI model or creating your own custom model with your photos
              <span className='coupon-card bold' style={{ color: darkMode ? '#fff' : '#fc5183', cursor: 'pointer' }} onClick={openTrainModel}> Create Model</span>
            </div>

            <div className="page-header mb-0 page-header-reset mb-4" />
            <label className={`${darkMode ? 'text-white' : 'text-black'}`}>1. {t('app.studioMode.selectPhotographer')}</label>
            <Tooltip title={t('app.studioMode.selectPhotographerSub')}>
              <span className="text-gray-500 cursor-help" style={{ marginLeft: '2px', fontSize: '14px' }}>
                ?
              </span>
            </Tooltip>
            <div className="page-header mb-0 page-header-reset mb-2" />
            <div className={`w-full ${darkMode ? 'dark-border-full' : 'light-border-full'} rounded-lg`}>
              <div className={`flex`}>
                <button
                  className={`w-full py-3 px-4 text-center transition-colors duration-200 ease-in-out
                    ${activeTab === '1'
                      ? darkMode
                        ? 'dark-bg-tab text-white border-b-2 border-pink-500'
                        : 'gray-bg-tab text-black border-b-2 border-pink-500'
                      : darkMode
                        ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                        : 'text-gray-600 hover:bg-gray-200'
                    }
                  `}
                  style={{ borderRadius: '4px', fontSize: '14px', margin: '4px' }}
                  onClick={() => handleTabChange('1')}
                >
                  Your models
                </button>
                <button
                  className={`w-full py-2 px-4 text-center transition-colors duration-200 ease-in-out
                    ${activeTab === '2'
                      ? darkMode
                        ? 'dark-bg-tab text-white border-b-2'
                        : 'gray-bg-tab text-black border-b-2 border-pink-500'
                      : darkMode
                        ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                        : 'text-gray-600 hover:bg-gray-200'
                    }
                  `}
                  style={{ borderRadius: '4px', fontSize: '14px', margin: '4px' }}
                  onClick={() => handleTabChange('2')}
                >
                  AI models
                </button>
              </div>
            </div>
            <div className="page-header mb-0 page-header-reset mb-2" />
            <div className={`w-full ${darkMode ? 'dark-border-full' : 'light-border-full'} rounded-lg`}>
              <div className="model-tab-wrapper">
                {activeTab === '1' && (
                  <div className="h-30 model-tab-content">
                    <div className="grid grid-cols-3 sm:grid-cols-4 gap-4 mb-4">
                      <button
                        style={{
                          border: darkMode ? '1px solid #373737' : '1px solid rgb(0 0 0 / 20%)',
                        }}
                        className={`h-30 text-sm flex flex-col items-center justify-center p-4 rounded-lg transition-colors
                          ${darkMode ? 'dark-bg-item text-white' : 'light-bg-item text-black'}
                        `}
                        onClick={e => {
                          openTrainModel(e);
                        }}
                      >
                        <span className="mb-1">+</span>
                        <span>create model</span>
                      </button>
                      {isLoadingModels ? (
                        <div className="col-span-3 sm:col-span-4 flex justify-center items-center">
                          <CircularProgress size={24} sx={{ color: '#FF69B4' }} />
                        </div>
                      ) : (
                        <>
                          {modelsList.map((subject, key) => (
                            <button
                              key={key}
                              style={{
                                border:
                                  selectedModel?.id === subject.id
                                    ? '1px solid #fc5185'
                                    : darkMode
                                      ? '1px solid #373737'
                                      : '1px solid rgb(0 0 0 / 20%)',
                                borderRadius: '10px',
                                overflow: 'hidden',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                height: '120px',
                                width: '100%',
                                padding: 0,
                                position: 'relative',
                              }}
                              className={`rounded-lg transition-colors`}
                              onClick={() => handleModelSelect(subject)}
                            >
                              <div
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  position: 'relative',
                                  overflow: 'hidden',
                                }}
                              >
                                <img
                                  src={subject.thumbnail}
                                  alt={subject.modelName}
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    boxSizing: 'border-box',
                                  }}
                                />
                                <span
                                  className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-xs py-1 px-2"
                                  style={{ fontSize: '10px' }}
                                >
                                  {subject.modelName}
                                </span>
                              </div>
                              {selectedModel?.id && subject?.id && selectedModel.id === subject.id && (
                                // <div
                                //   className="absolute top-2 bg-pink-500 rounded-full p-1"
                                //   style={{ width: '20px', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', left: 0 }}
                                // >
                                //   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="12px" height="12px">
                                //     <path d="M0 0h24v24H0z" fill="none" />
                                //     <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                                //   </svg>
                                // </div>
                                <div
                                  className="absolute p-1 m-1"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    left: 0,
                                  }}
                                >
                                  <Check
                                    sx={{
                                      background: '#fc5185',
                                      color: '#fff',
                                      borderRadius: '50%',
                                    }}
                                  />
                                </div>
                              )}
                            </button>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                )}

                {activeTab === '2' && (
                  <div className="h-30 model-tab-content">
                    <div className="grid grid-cols-3 sm:grid-cols-4 gap-4">
                      {isLoadingModels ? (
                        <div className="col-span-3 sm:col-span-4 flex justify-center items-center">
                          <CircularProgress size={24} sx={{ color: '#FF69B4' }} />
                        </div>
                      ) : (
                        publicModels.map((subject, key) => (
                          <button
                            key={key}
                            style={{
                              border:
                                selectedModel?.id === subject.id
                                  ? '1px solid #fc5185'
                                  : darkMode
                                    ? '1px solid #373737'
                                    : '1px solid rgb(0 0 0 / 20%)',
                              borderRadius: '10px',
                              overflow: 'hidden',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              height: '120px',
                              width: '100%',
                              padding: 0,
                              position: 'relative',
                            }}
                            className={`rounded-lg transition-colors`}
                            onClick={() => handleModelSelect(subject)}
                          >
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                overflow: 'hidden',
                              }}
                            >
                              <img
                                src={subject.thumbnail}
                                alt={subject.modelName}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                  boxSizing: 'border-box',
                                }}
                              />
                              <span
                                className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-xs py-1 px-2"
                                style={{ fontSize: '10px' }}
                              >
                                {subject.modelName}
                              </span>
                            </div>
                            {selectedModel?.id && subject?.id && selectedModel.id === subject.id && (
                              <div
                                className="absolute p-1 m-1"
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  left: 0,
                                }}
                              >
                                <Check
                                  sx={{
                                    background: '#fc5185',
                                    color: '#fff',
                                    borderRadius: '50%',
                                  }}
                                />
                              </div>
                            )}
                          </button>
                        ))
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="page-header mb-0 page-header-reset mb-5" />
            <label className={`${darkMode ? 'text-white' : 'text-black'}`}>2. Customize</label>
            <Tooltip title={t('app.studioMode.customizeSub')}>
              <span className="text-gray-500 cursor-help" style={{ marginLeft: '2px', fontSize: '14px' }}>
                ?
              </span>
            </Tooltip>
            <div className="page-header mb-0 page-header-reset mb-2" />
            <div className={`w-full ${darkMode ? 'dark-border-full' : 'light-border-full'} rounded-lg`}>
              <div className={`flex`}>
                <button
                  className={`w-full py-3 px-4 text-center transition-colors duration-200 ease-in-out
                    ${activeModeTab === '1'
                      ? darkMode
                        ? 'dark-bg-tab text-white border-b-2 border-pink-500'
                        : 'gray-bg-tab text-black border-b-2 border-pink-500'
                      : darkMode
                        ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                        : 'text-gray-600 hover:bg-gray-200'
                    }
                  `}
                  style={{ borderRadius: '4px', fontSize: '14px', margin: '4px' }}
                  onClick={() => handleModeTabChange('1')}
                >
                  Basic
                </button>
                <button
                  className={`w-full py-2 px-4 text-center transition-colors duration-200 ease-in-out
                    ${activeModeTab === '2'
                      ? darkMode
                        ? 'dark-bg-tab text-white border-b-2'
                        : 'gray-bg-tab text-black border-b-2 border-pink-500'
                      : darkMode
                        ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                        : 'text-gray-600 hover:bg-gray-200'
                    }
                  `}
                  style={{ borderRadius: '4px', fontSize: '14px', margin: '4px' }}
                  onClick={() => handleModeTabChange('2')}
                >
                  Advanced
                </button>
              </div>
            </div>
            <div className="page-header mb-0 page-header-reset mb-2" />
            <div className={`w-full ${darkMode ? 'dark-border-full' : 'light-border-full'} rounded-lg`}>
              <div className="p-4">
                {activeModeTab === '1' && (
                  <div className="h-full mb-2 text-sm">
                    <p className={`${darkMode ? 'text-white' : 'text-black'}`}>{t('app.studioMode.styleTitle')}</p>
                    <div className="d-flex flex-column">
                      <div className="upload-title">
                        {styleSrc && (
                          <div onClick={removeUploadedStyle} style={{ marginTop: '-30px', cursor: 'pointer' }} className="red-color">
                            <svg
                              style={{ pointerEvents: 'none' }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash text-[#9ca3af] hover:text-white transition"
                            >
                              <path d="M3 6L5 6 21 6"></path>
                              <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                            </svg>
                          </div>
                        )}
                      </div>
                      <div className="page-header mb-0 page-header-reset mb-2" />
                      {!styleSrc && (
                        <div
                          className={`pose-uploader-wrapper ${darkMode ? 'dark-border dark-bg-input' : 'light-border'}`}
                          onClick={handleStyleUpload}
                        >
                          <div className="uploader-title">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M7 7L5.5 5.5M15 7L16.5 5.5M5.5 16.5L7 15M11 5L11 3M5 11L3 11M17.1603 16.9887L21.0519 15.4659C21.4758 15.3001 21.4756 14.7003 21.0517 14.5346L11.6992 10.8799C11.2933 10.7213 10.8929 11.1217 11.0515 11.5276L14.7062 20.8801C14.8719 21.304 15.4717 21.3042 15.6375 20.8803L17.1603 16.9887Z"
                                stroke="#8c8c8c"
                                strokeWidth="1.6"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <span style={{ marginLeft: '4px' }}>{t('home.stylePlaceHolder')}</span>
                          </div>
                        </div>
                      )}
                      {styleSrc && (
                        <div
                          className={`photographer-wrapper ${darkMode ? 'dark-border dark-bg-input' : 'light-border'}`}
                          onClick={handleStyleUpload}
                        >
                          <div className="flex align-items-center">
                            <img src={styleSrc} alt="Style Image" className="photographer-image" />
                            <span className={`photographer-name ${darkMode ? 'text-white' : 'text-black'}`}>
                              {currentLanguage !== 'en' ? t(`styles.${styleCode}`) : styleName}
                            </span>
                          </div>
                          <span className="detail-btn">
                            <svg fill="#757575" width="20px" height="20px" viewBox="-2.8 0 12.8 12.8" xmlns="http://www.w3.org/2000/svg">
                              <path d="m1.6 8.8 2.4 -2.4 -2.4 -2.4 0.8 -0.8 3.2 3.2L2.4 9.6l-0.8 -0.8Z" />
                            </svg>
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="page-header mb-0 page-header-reset mb-4" />
                    <p className={`${darkMode ? 'text-white' : 'text-black'}`}>{t('app.studioMode.aspectRatio')}</p>
                    <div className="page-header mb-0 page-header-reset mb-2" />
                    <FormControl
                      sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#fc5185',
                          },
                        },
                      }}
                    >
                      <Select
                        sx={{
                          border: darkMode ? '1px solid #373737' : '',
                          borderRadius: '8px',
                          background: darkMode ? '#323232' : '',
                          color: darkMode ? 'white' : 'black',
                          '& .MuiSvgIcon-root': {
                            color: darkMode ? 'white' : 'black',
                          },
                          fontSize: '14px',
                        }}
                        value={currentAspectRatio}
                        onChange={handleChangeAspectRatio}
                        autoWidth
                      >
                        {aspectRatios.map((c, i) => {
                          return (
                            <MenuItem key={i} value={c.value}>
                              {c.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <div className="page-header mb-0 page-header-reset mb-4" />
                    <p className={`${darkMode ? 'text-white' : 'text-black'}`}>{t('app.studioMode.quantityTitle')}</p>
                    <div className="page-header mb-0 page-header-reset mb-2" />
                    <FormControl
                      sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#fc5185',
                          },
                        },
                      }}
                    >
                      {' '}
                      <Select
                        sx={{
                          border: darkMode ? '1px solid #373737' : '',
                          borderRadius: '8px',
                          background: darkMode ? '#323232' : '',
                          color: darkMode ? 'white' : 'black',
                          '& .MuiSvgIcon-root': {
                            color: darkMode ? 'white' : 'black',
                          },
                          fontSize: '14px',
                        }}
                        value={currentResult}
                        onChange={handleChangeResult}
                        autoWidth
                      >
                        {results.map((c, i) => {
                          return (
                            <MenuItem key={i} value={c.value}>
                              {t(`results.${c.label}`)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                )}

                {activeModeTab === '2' && (
                  <div className="h-full mb-2 text-sm ">
                    <p className={`${darkMode ? 'text-white' : 'text-black'}`}>
                      {currentLanguage === 'en' ? t('app.studioMode.customize') : t('app.studioMode.enOnlyCustomize')}
                    </p>
                    <div className="d-flex flex-column">
                      <div className="upload-title">
                        {promptStyle && (
                          <div onClick={removePromptStyle} style={{ marginTop: '-30px', cursor: 'pointer' }} className="red-color">
                            <svg
                              style={{ pointerEvents: 'none' }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash text-[#9ca3af] hover:text-white transition"
                            >
                              <path d="M3 6L5 6 21 6"></path>
                              <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                            </svg>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="page-header mb-0 page-header-reset mb-2" />
                    <TextField
                      disabled={currentLanguage !== 'en'}
                      value={promptStyle}
                      sx={{
                        width: '100%',
                        border: darkMode ? '1px solid #373737' : '',
                        borderRadius: '6px',
                        background: darkMode ? '#323232' : '',
                        color: darkMode ? 'white' : 'black',
                        fontSize: isMobile ? '16px' : '14px',
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#fc5185',
                          },
                        },
                      }}
                      maxRows={15}
                      minRows={4}
                      multiline
                      onChange={handleInputChange}
                      placeholder="model wearing a business suit..."
                      inputProps={{
                        style: {
                          fontSize: isMobile ? '16px' : '14px',
                          color: darkMode ? 'white' : 'black',
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: isMobile ? '16px' : '14px',
                          color: darkMode ? 'white' : 'black',
                        },
                      }}
                      error={!!promptErrorMessage}
                      helperText={promptErrorMessage}
                      autoFocus={false}
                    />
                    <div className="flex justify-center">
                      <button
                        onClick={enhancePrompt}
                        className="relative flex items-center mt-2 bg-pink-500 text-white text-xs font-bold px-3 py-1 rounded-full"
                        style={{
                          width: 'fit-content',
                          cursor: 'pointer',
                        }}
                        disabled={enhancePromptLoading || getPromptLoading}
                      >
                        <span style={{ marginLeft: '2px' }}>{enhancePromptLoading ? 'AI enhancing...' : 'Enhance prompt'}</span>
                        {!enhancePromptLoading ? (
                          <>
                            <svg
                              style={{ marginTop: '-2px' }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 32 32"
                              fill="#ffc337"
                            >
                              <path d="M17.333 4v9.333h8L14.666 28v-9.333H6.667z" />
                            </svg>
                            1
                          </>
                        ) : (
                          <CircularProgress size={14} sx={{ color: '#fff', marginLeft: '6px', marginTop: '4px', marginBottom: '4px' }} />
                        )}
                      </button>
                    </div>
                    <div className="page-header mb-0 page-header-reset mb-4" />
                    <p className={`${darkMode ? 'text-white' : 'text-black'}`}>Transfer style + pose</p>
                    <div className="d-flex flex-column">
                      <div className="page-header mb-0 page-header-reset mb-2" />
                      {/* Upload image */}
                      {!remixPoseSrc && (
                        <div
                          className={`uploader-wrapper ${darkMode ? 'dark-border dark-bg-input' : 'light-border'} ${getPromptErrorMessage ? 'dashed-border-red' : ''
                            }`}
                          onClick={handleOpenFileUpload}
                          onDragOver={handleDrag}
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDrop={handleMixDrop}
                        >
                          <div className="uploader-title">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="icon"
                              role="presentation"
                            >
                              <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4" />
                              <polyline points="17 8 12 3 7 8" />
                              <line x1="12" y1="3" x2="12" y2="15" />
                            </svg>
                            <span style={{ marginLeft: '4px' }}>Click or drag your reference image</span>
                          </div>
                          <input type="file" accept="image/*" onChange={handleFileUpload} style={{ display: 'none' }} ref={fileInputRef} />
                        </div>
                      )}

                      {/* Error message */}
                      {!remixPoseSrc && getPromptErrorMessage && <div className="text-red text-xs mt-2 mb-2">{getPromptErrorMessage}</div>}

                      {/* Remove button */}
                      <div className="upload-title">
                        {remixPoseSrc && (
                          <div onClick={removeUploadedImage} style={{ marginTop: '-30px', cursor: 'pointer' }} className="red-color">
                            <svg
                              style={{ pointerEvents: 'none' }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash text-[#9ca3af] hover:text-white transition"
                            >
                              <path d="M3 6L5 6 21 6"></path>
                              <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                            </svg>
                          </div>
                        )}
                      </div>
                      <div className="page-header mb-0 page-header-reset mb-2" />

                      {/* Uploaded image */}
                      {remixPoseSrc && (
                        <div className={`mb-2 new-photographer-wrapper ${darkMode ? 'dark-border dark-bg-input' : 'light-border'}`}>
                          <div className="flex align-items-center">
                            <div className="relative w-full h-full">
                              <img src={remixPoseSrc} alt="Style Image" className={`photographer-image w-full h-full object-cover`} />
                              {isScanning && <div className="absolute inset-0 scanning-photo" />}
                            </div>
                            <div className="flex flex-col w-full ml-3">
                              <div>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  sx={{
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                  }}
                                >
                                  <p className={`${darkMode ? 'text-white' : 'text-black'}`}>Lock pose</p>
                                  <AntSwitch
                                    defaultChecked={lockPose}
                                    onChange={handleLockPoseChange}
                                    inputProps={{ 'aria-label': 'ant design' }}
                                  />
                                </Stack>
                                <p className={`text-xs mt-1`}>
                                  {lockPose ? 'Keep reference pose' : 'Using Prompt only'}
                                </p>                              
                              </div>
                              <hr className="mt-3"/>
                              <div className="mt-3">
                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  width: '100%'
                                }}
                              >
                                <p className={`${darkMode ? 'text-white' : 'text-black'}`}>Similarity</p>
                                <label
                                  className={`${darkMode ? 'text-white' : 'text-black'}`}
                                >
                                  {similarity * 10}%
                                </label>
                              </Stack>
                              {renderSimilarity()}
                              <p className={`text-xs`}>
                                20-40% works best
                              </p>   
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="flex justify-center">
                        <button
                          onClick={onGetPrompt}
                          className="relative flex items-center bg-pink-500 text-white text-xs font-bold px-3 py-1 rounded-full"
                          style={{ width: 'fit-content', cursor: 'pointer' }}
                          disabled={enhancePromptLoading || getPromptLoading}
                        >
                          <span style={{ marginLeft: '2px' }}>{getPromptLoading ? 'AI Scanning...' : 'Get prompt'}</span>
                          {!getPromptLoading ? (
                            <>
                              <svg
                                style={{ marginTop: '-2px' }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 32 32"
                                fill="#ffc337"
                              >
                                <path d="M17.333 4v9.333h8L14.666 28v-9.333H6.667z" />
                              </svg>
                              1
                            </>
                          ) : (
                            <CircularProgress size={14} sx={{ color: '#fff', marginLeft: '6px', marginTop: '4px', marginBottom: '4px' }} />
                          )}
                        </button>
                      </div>
                    </div>
                    {/* <div className="page-header mb-0 page-header-reset mb-4" />
                    <p className={`${darkMode ? 'text-white' : 'text-black'}`}>
                      Transfer similarity
                    </p>
                    {renderSimilarity()} */}
                    <div className="page-header mb-0 page-header-reset mb-4" />
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                    >
                      <p className={`${darkMode ? 'text-white' : 'text-black'}`}>{t('app.studioMode.creativity')}</p>
                      <label className={`${darkMode ? 'text-white' : 'text-black'}`} style={{ marginLeft: '10px' }}>
                        {creativity * 10}%
                      </label>
                    </Stack>
                    {renderStrength()}
                    <div className="page-header mb-0 page-header-reset mb-4" />
                    <p className={`${darkMode ? 'text-white' : 'text-black'}`}>{t('app.studioMode.aspectRatio')}</p>
                    <div className="page-header mb-0 page-header-reset mb-2" />
                    <FormControl
                      sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#fc5185',
                          },
                        },
                      }}
                    >
                      {!transferredImageSize ? (
                        <Select
                          sx={{
                            border: darkMode ? '1px solid #373737' : '',
                            borderRadius: '8px',
                            background: darkMode ? '#323232' : '',
                            color: darkMode ? 'white' : 'black',
                            '& .MuiSvgIcon-root': {
                              color: darkMode ? 'white' : 'black',
                            },
                            fontSize: '14px',
                          }}
                          value={currentAspectRatio}
                          onChange={handleChangeAspectRatio}
                          autoWidth
                        >
                          {aspectRatios.map((c, i) => {
                            return (
                              <MenuItem key={i} value={c.value}>
                                {c.label}
                              </MenuItem>
                            );
                          })}
                        </Select>)
                        : (
                          <Select
                            sx={{
                              border: darkMode ? '1px solid #373737' : '',
                              borderRadius: '8px',
                              background: darkMode ? '#323232' : '',
                              color: darkMode ? 'white' : 'black',
                              '& .MuiSvgIcon-root': {
                                color: darkMode ? 'white' : 'black',
                              },
                              fontSize: '14px',
                            }}
                            value="transfered_image_size"
                            autoWidth
                          >
                            <MenuItem value="transfered_image_size">Transfered image size</MenuItem>
                          </Select>
                        )}
                    </FormControl>
                    <div className="page-header mb-0 page-header-reset mb-4" />
                    <p className={`${darkMode ? 'text-white' : 'text-black'}`}>{t('app.studioMode.quantityTitle')}</p>
                    <div className="page-header mb-0 page-header-reset mb-2" />
                    <FormControl
                      sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#fc5185',
                          },
                        },
                      }}
                    >
                      <Select
                        sx={{
                          border: darkMode ? '1px solid #373737' : '',
                          borderRadius: '8px',
                          background: darkMode ? '#323232' : '',
                          color: darkMode ? 'white' : 'black',
                          '& .MuiSvgIcon-root': {
                            color: darkMode ? 'white' : 'black',
                          },
                          fontSize: '14px',
                        }}
                        value={currentResult}
                        onChange={handleChangeResult}
                        autoWidth
                      >
                        {results.map((c, i) => {
                          return (
                            <MenuItem key={i} value={c.value}>
                              {t(`results.${c.label}`)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={`down-action-area ${darkMode ? 'dark-bg' : 'light-bg'}`}>
            <div className="create-imagine-button generate-btn">
              <Button onClick={onGeneratePhoto} type="submit" className="generate-button w-button" loading={isCreating}>
                <span style={{ marginLeft: '4px' }}>
                  {t('button.professional')}
                  <span>
                    {' '}
                    {/* {'['}- */}
                    <svg
                      style={{ marginTop: '-2px' }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                      fill="#ffc337"
                    >
                      <path d="M17.333 4v9.333h8L14.666 28v-9.333H6.667z" />
                    </svg>
                    {credits}
                    {/* {']'} */}
                  </span>
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <StudioShowCase
        userData={userData}
        checkCredits={checkCredits}
        firebase={firebase}
        currentJob={currentJob}
        t={t}
        jobsList={jobsList}
        isCreating={isCreating}
        progress={progress}
        onGenFill={onGenFill}
        onFetchUser={onFetchUser}
        onUpImageResolution={handleOnUpImageResolution}
        onRemoveBG={onRemoveObject}
        handleSelectCollection={handleSelectCollection}
        onMagicFixer={onMagicFixer}
        darkMode={darkMode}
        isMobile={isMobile}
      // mode={mode}
      />
      {isShowStyle && (
        <PhotoPacks
          userData={userData}
          firebase={firebase}
          darkMode={true}
          onShowStyle={onShowStyle}
          t={t}
          mode={mode}
          onShowPricing={onShowPricing}
          currentLanguage={currentLanguage}
          promptStyle={promptStyle}
          handleSelectCollection={handleSelectCollection}
        />
      )}
    </div>
  );
};

export default Studio;
