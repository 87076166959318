import React, { useEffect, useRef, useState } from 'react';
import {
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';

const ViewPhoto = ({ images }) => {

  const imageBeforeRef = useRef(null);
  const imageAfterRef = useRef(null);
  const sliderRef = useRef(null);
  const beforeRef = useRef(null);
  const afterRef = useRef(null);
  const resizerRef = useRef(null);

  return (
    <div className="swiper">
      <div className="before-after-wrapper">
        <div id="before-after-slider" ref={sliderRef}>
          <div id="after-image" ref={afterRef}>
            <img src={images.after} alt="after" className="after-image" ref={imageAfterRef} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPhoto;
