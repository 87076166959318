import React, { useEffect, useState, useRef } from 'react';
import { Button, message } from 'antd';
import MUIButton from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import NewSkeleton from '../NewSkeleton';
import { Eraser, Sparkles } from 'lucide-react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import WarningIcon from '@mui/icons-material/Warning';
import AutoModeIcon from '@mui/icons-material/AutoMode';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const ImageResult = ({
  t,
  job,
  firebase,
  userData,
  onCompareImage,
  onDownloadImage,
  onRemoveBG,
  isDownloading,
  onUpImageResolution,
  onMagicFixer,
  darkMode,
  mode
}) => {

  const [loveColor, setLoveColor] = useState('#fff');
  const [isLoved, setIsLoved] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [openRenew, setOpenRenew] = useState(false);
  const [isInReview, setIsInReview] = useState(false);
  const [text, setText] = useState('');
  const [textError, setTextError] = useState(false);
  const [isDestroyed, setIsDestroyed] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (job.status === "nsfw") {
      const nsfwText = "NSFW Detected, we removed it - ";
      if (!job.prompt.includes(nsfwText)) {
        // If the substring is not already present, add the prefix
        job.prompt = nsfwText + job.prompt;
      }
    }
    if (job.isLoved !== undefined && job.isLoved) {
      setIsLoved(true);
      setLoveColor('#e8594b');
    }

    if (job.isInReview !== undefined && job.isInReview) {
      setIsInReview(true);
    }

    // setTimeout(() => {
    //   try {
    //     const image = new Image();
    //     image.onload = () => {
    //       imageRef.current.src = job.upscaleUrl;
    //       setImageLoaded(true);
    //     };
    //     image.onerror = () => {
    //       setImageLoaded(false);
    //       // Handle error, show message, etc.
    //       console.error("Error loading image");
    //     };
    //     image.src = job.upscaleUrl;
    //   } catch (error) {
    //     setImageLoaded(false);
    //     // Handle other errors
    //     console.error(error);
    //   }
    // }, 2500);

  }, [job]);
  
  function onLove(job) {

    if (isLoved) {
      if (loveColor === "#fff") {
        setLoveColor('#e8594b');
      } else {
        setLoveColor("#fff");
      }
      return;
    }

    firebase.updateJob(job.id, {isLoved: true})
    .then(result => {
      setLoveColor('#e8594b');
      setIsLoved(true);
      job.isLoved = true;
    })
    .catch(err => {
      message.error(err.message);
      console.log(err);
    });
  }

  function onConfirmRemove(e, job) {
    e.preventDefault();

    firebase.updateJob(job.id, {isDeleted: true})
    .then(result => {
      setIsDestroyed(true);
      message.success(t('message.success.removed'));
    })
    .catch(err => {
      message.error(err.message);
      console.log(err);
    });

    setOpenRemove(false);
  }

  function onConfirmRenew(e) {
    e.preventDefault();

    if (text === "") {
      setTextError(true);
      return;
    }

    if (isInReview) {
      message.success(t('message.success.remindReviewing'));
      setOpenRenew(false);
      return;
    } else {
      const data = {
        email: userData.email,
        uid: job.uid,
        jobId: job.id,
        reason: text,
        originalUrl: job.originalUrl,
        upscaleUrl: job.upscaleUrl,
        createdAt: new Date().toISOString(),
        isDeleted: false,
        isApproved: false
      }

      firebase
      .addRenewPhoto(data)
      .then(result => {
        setIsInReview(true);
        message.success(t('message.success.reviewing'));
      })
      .catch(err => {
        message.error(err.message);
        console.log(err);
      });

      firebase.updateJob(job.id, {isInReview: true})
      .then(result => {
        job.isInReview = true;
      })
      .catch(err => {
        message.error(err.message);
        console.log(err);
      });
    }

    setOpenRenew(false);
  }

  const onRemove = () => {
    if (isInReview) {
      message.error(t('message.error.reviewing'));
      return;
    }
    setOpenRemove(true);
  };

  const onRenew = () => {
    setTextError(false);
    setOpenRenew(true);
  }

  const onCloseRemove = () => {
    setOpenRemove(false);
  };

  const onCloseRenew = () => {
    setOpenRenew(false);
  };

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
    {!isDestroyed ?
    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
      <div className="rnftg-item__img-wrapper">
        <div className={`result`}>
          {!imageLoaded && (
            <NewSkeleton/>
            )
          }
          <img
            className={`${darkMode? 'bg-gray' : 'bg-gray'} rnftg-w-full rnftg-object-contain rnftg-cursor-pointer result-wrapper ${imageLoaded ? 'loaded-image' : ''}`}
            alt="reok"
            width={400}
            height={600}
            src={job.upscaleUrl}
            onLoad={handleImageLoad}
          ></img>
          {
            isInReview === true ? (
              <div className="prompt-label">{t('message.prompt.inReview')}</div>
            )
            :
            (<div className="prompt-label">{job.prompt}</div>)
          }
          
          <div className="action-1">
            <Button
              className="image_action download_image"
              onClick={() => onLove(job)}
              type="submit"
            >
              <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#e8594b">
                <path
                  d="M20.808 11.079C19.829 16.132 12 20.5 12 20.5s-7.829-4.368-8.808-9.421C2.227 6.1 5.066 3.5 8 3.5a4.444 4.444 0 0 1 4 2 4.444 4.444 0 0 1 4-2c2.934 0 5.773 2.6 4.808 7.579Z"
                  fill={loveColor}
                />
              </svg>
            </Button>
          </div>
          <div className="action-1-right">
            <Button
              className="image_action download_image"
              onClick={() => onRemove(job)}
              type="submit"
            >
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h4a1 1 0 1 1 0 2h-1.069l-.867 12.142A2 2 0 0 1 17.069 22H6.93a2 2 0 0 1-1.995-1.858L4.07 8H3a1 1 0 0 1 0-2h4V4zm2 2h6V4H9v2zM6.074 8l.857 12H17.07l.857-12H6.074zM10 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1z"
                  fill="#fff"
                />
              </svg>
            </Button>
          </div>
          <div className="action-2" onClick={() => onCompareImage({ before: job.originalUrl, after: job.upscaleUrl, mode: mode, prompt: job.prompt })}>
            <Button
              className="image_action download_image"
              type="submit"
            >
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 240 240" fillRule="nonzero">
                <g
                  fill="#ffffff"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth={1}
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  strokeMiterlimit={10}
                  strokeDashoffset={0}
                  fontFamily="none"
                  fontWeight="none"
                  fontSize="none"
                  textAnchor="none"
                  style={{ mixBlendMode: 'normal' }}
                >
                  <g transform="scale(5.12,5.12)">
                    <path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z" />
                  </g>
                </g>
              </svg>
            </Button>
            <span>{t('imageComponent.compare')}</span>
          </div>
          <div className="action-3" onClick={() => onDownloadImage(job.upscaleUrl)}>
            <Button
              className="image_action download_image"
              type="submit"
              loading={isDownloading}
            >
              <svg fill="#000000" viewBox="-2 -2 18 20" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin">
                <path d="M8 6.641l1.121-1.12a1 1 0 0 1 1.415 1.413L7.707 9.763a.997.997 0 0 1-1.414 0L3.464 6.934A1 1 0 1 1 4.88 5.52L6 6.641V1a1 1 0 1 1 2 0v5.641zM1 12h12a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2z" />
              </svg>
            </Button>
            <span>{t('imageComponent.download')}</span>
          </div>
          <div className="action-4" onClick={e => onRemoveBG(e, job.upscaleUrl)}>
            <Button className="image_action open_shop" type="button">
              <Eraser style={{stroke: "#fff", fill: "#00000000"}}/>
            </Button>
            <span>{t('imageComponent.removeObjects')}</span>
          </div>
          <div className="action-5" onClick={e => onUpImageResolution(e, job.upscaleUrl)}>
            <Button className="image_action open_shop" type="button">
              <svg
                width="16px"
                height="16px"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="bi bi-badge-4k"
              >
                <path d="M4.807 5.001C4.021 6.298 3.203 7.6 2.5 8.917v.971h2.905V11h1.112V9.888h.733V8.93h-.733V5.001h-1.71zm-1.23 3.93v-.032a46.781 46.781 0 0 1 1.766-3.001h.062V8.93H3.577zm9.831-3.93h-1.306L9.835 7.687h-.057V5H8.59v6h1.187V9.075l.615-.699L12.072 11H13.5l-2.232-3.415 2.14-2.584z" />
                <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
              </svg>
            </Button>
            <span>{t('imageComponent.upscale')}</span>
          </div>
          <div className="action-6" onClick={(e) => onMagicFixer(e, job.upscaleUrl)} >
            <Button className="image_action open_shop" type="button">
              <AutoModeIcon/>
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m19 11-8-8-8.6 8.6a2 2 0 0 0 0 2.8l5.2 5.2c.8.8 2 .8 2.8 0L19 11Z"/><path d="m5 2 5 5"/><path d="M2 13h15"/><path d="M22 20a2 2 0 1 1-4 0c0-1.6 1.7-2.4 2-4 .3 1.6 2 2.4 2 4Z"/></svg> */} 
            </Button>
            <span>{t('imageComponent.virtualHome')}</span>
          </div>
          <div className="blur-image" />
        </div>
      </div>
      {/* {openRemove &&
        <div>
          <Dialog
            open={openRemove}
            TransitionComponent={Transition}
            keepMounted
            onClose={onCloseRemove}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{t('removeTitle')}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {t('removeSub')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MUIButton onClick={onCloseRemove}>{t('button.cancel')}</MUIButton>
              <MUIButton onClick={(e) => onConfirmRemove(e, job)} color="error">{t('button.ok')}</MUIButton>
            </DialogActions>
          </Dialog>
        </div>
      } */}
      {openRemove &&
        <div>
          <Modal 
            open={openRemove}
          >
            <ModalDialog
              sx={{
                background: '#09090b',
                border: '1px solid #373737',
                overflow: 'auto',
              }}
            >
              <ModalClose
                onClick={onCloseRemove}
                sx={{
                  position: 'absolute',
                  top: '4px',
                  right: '4px',
                  color: 'white',
                  zIndex: 1000,
                }}
              />
              <h2 className="flex items-center gap-2 text-xl font-bold text-white mb-4">
                <WarningIcon sx={{ color: '#d32f2f' }}/>
                {t('removeTitle')}
              </h2>
              <p className="text-sm text-white ">
                {t('removeSub')}
              </p>
              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}>
                <button
                  onClick={onCloseRemove}
                  style={{
                    padding: '8px 16px',
                    background: 'transparent',
                    border: '1px solid #373737',
                    color: 'white',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  {t('button.cancel')}
                </button>
                <button
                  onClick={(e) => onConfirmRemove(e, job)}
                  style={{
                    padding: '8px 16px',
                    background: '#d32f2f',
                    border: 'none',
                    color: 'white',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  {t('button.ok')}
                </button>
              </div>
            </ModalDialog>
          </Modal>
        </div>
      }
      {openRenew &&
        <div>
          <Dialog
            open={openRenew}
            TransitionComponent={Transition}
            keepMounted
            onClose={onCloseRenew}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{t('renewTitle')}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {t('renewSub')}
              </DialogContentText>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': {mt: 4,width: '100%' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                    <TextField
                      id="outlined-textarea"
                      label={t('reasonTitle')}
                      placeholder={t('reasonEx')}
                      maxRows={3}
                      multiline
                      onChange={handleInputChange}
                      error={textError}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
              <MUIButton onClick={onCloseRenew}>{t('button.cancel')}</MUIButton>
              <MUIButton onClick={(e) => onConfirmRenew(e)} color="error">{t('button.ok')}</MUIButton>
            </DialogActions>
          </Dialog>
        </div>
      }
    </article>
    : null
    }
    </>
  );
};

export default ImageResult;
